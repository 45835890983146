import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BaseTable from "../../base/BaseTable/BaseTable";
import React from "react";
import TableService from "../../../services/tableService";
import { TRANSFERS_TABLE_FIELDS_CONFIG } from "./TableConfig";


const IcoTransfersForm = React.memo(({ transfers}) => {



  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
          <BaseTable
            showContextMenu={false}
            showNoRecordsPaper={false}
            headers={TableService.getHeaderLables(
              TRANSFERS_TABLE_FIELDS_CONFIG
            )}
            rows={TableService.getPreparedDataForBaseTable(
              TRANSFERS_TABLE_FIELDS_CONFIG,
              transfers,
              [],
              []
            )}
          />
        </div>
      </Grid>
    </Grid>
  );
});

IcoTransfersForm.propTypes = {
  transfers: PropTypes.array,
};

IcoTransfersForm.defaultProps = {
  transfers: [],
};

export default IcoTransfersForm;