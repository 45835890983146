import i18n from "../../i18n";

import {
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";

import DateService from "../../services/dateService";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

export const EQUIPMENT_BMS_TABLE_NAME = "equipment_bms";

export const EQUIPMENT_BMS_TABLE_CONFIG = [
  {
    name: "equipbms_nr",
    getValueCallback: (rowData) => rowData.equipbms_nr,
    label: i18n.t("table.counter_repository.equipbms_nr"),
    filterType: TEXT_FIELD_TYPE,
    editable: false,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "equipbms_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
        rowData.equipbms_date
      ),
    label: i18n.t("table.counter_repository.equipbms_date"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "equipbms_value",
    getValueCallback: (rowData) => rowData.equipbms_value,
    label: i18n.t("table.counter_repository.equipbms_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "add_counter",
    label: i18n.t("dialog.equipment.add_counter"),
    getValueCallback: (rowData, onClickAddCounter) => {
      return (
        <AddCircleOutlineOutlinedIcon
          onClick={() => onClickAddCounter(rowData.equipbms_nr)}
        />
      );
    },
    filterType: null,
    excludedFromExport: true,
  },
];
