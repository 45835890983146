import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import TablePagination from "@mui/material/TablePagination";
import BasicPagination from "../BasicPagination/basicPagination";

import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";
import { getFieldValueFromSearchParams } from "../../../helpers/methods";

function BasicPaginationWithRowsPerPage(props) {
  const { getFilterFieldKey, searchParams } = usePaginationWithSearchParams(
    props.filterPrefix
  );

  const { t } = useTranslation();
  return (
    <TablePagination
      component="div"
      count={props.countRecords}
      page={getFieldValueFromSearchParams(
        searchParams,
        getFilterFieldKey(props.pageKey)
      )}
      onPageChange={props.onPageChange}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      rowsPerPageOptions={props.rowsPerPageOptions}
      labelRowsPerPage={t("rows_per_page")}
      ActionsComponent={BasicPagination
      }
      labelDisplayedRows={() => undefined}
      disabled={props.isLoading}
    />
  );
}

BasicPaginationWithRowsPerPage.propTypes = {
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageKey: PropTypes.string,
  pageSizeKey: PropTypes.string,
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  filterPrefix: PropTypes.string,
  isLoading :PropTypes.bool
};

BasicPaginationWithRowsPerPage.defaultProps = {
  pageSizeKey: "page_size",
  pageKey: "page",
  isLoading :false
};

export default BasicPaginationWithRowsPerPage;
