import { useState, useEffect, useMemo } from "react";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import BasicDialog from "../../base/BasicDialog";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useAsync } from "../../../hooks/useAsync";

import EquipmentTable from "../../table/EquipmentTable/EquipmentTable";

import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";

import useEquipmentService from "../../../services/equipmentService";

import TableService from "../../../services/tableService";

import {
  EQUIPMENT_BMS_TABLE_CONFIG,
  EQUIPMENT_BMS_TABLE_NAME,
} from "./TableConfig";

import useFilterSearchParams from "../../../hooks/useFilterSearchParams";
import { exportEquipmentBmsToFileUrl } from "../../../helpers/apiUrls";

import useFileService from "../../../services/fileService";

import useDialogWithId from "../../../hooks/useDialogWithId";
import EquipmentBmsDetailsDialog from "../../../components/dialog/EquipmentBmsDetailsDialog";

const ReadingForEquipmentDialog = (props) => {
  const { t } = useTranslation();

  const [
    openEquipmentBmsDetailsDialog,
    equipmentBmsId,
    onOpenEquipmentBmsDetailsDialog,
    onCloseEquipmentBmsDetailsDialog,
  ] = useDialogWithId();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
  } = usePaginationWithSearchParams(EQUIPMENT_BMS_TABLE_NAME);

  const { handlePreviewEnclosure } = useFileService();

  const { getEquipmentBmsListData, getEquipmentBmsFilteringData } =
    useEquipmentService();

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
  } = useFilterSearchParams(searchParams);

  const [readingSearchParams, setReadingSearchParams] = useState(() =>
    getSearchParamsByFilterPrefix(EQUIPMENT_BMS_TABLE_NAME)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      EQUIPMENT_BMS_TABLE_NAME,
      readingSearchParams,
      setReadingSearchParams
    );
  }, [searchParams, EQUIPMENT_BMS_TABLE_NAME]);

  const readingSearchParamsWithEquipmentId = useMemo(() => {
    let tempSearchParams = {
      ...readingSearchParams,
    };
    tempSearchParams.equipment = props.equipmentId;
    return tempSearchParams;
  }, [readingSearchParams]);

  const readingData = useAsync(() => {
    return getEquipmentBmsListData(readingSearchParamsWithEquipmentId);
  }, [readingSearchParamsWithEquipmentId]);

  const readingFilteringData = useAsync(getEquipmentBmsFilteringData);

  const [dataLocaly, setDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const refreshTable = () => {
    setDataLocaly(undefined);
    readingData.refetch();
  };

  const exportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      EQUIPMENT_BMS_TABLE_CONFIG,
      [],
      []
    );
  }, [EQUIPMENT_BMS_TABLE_CONFIG]);

  useEffect(() => {
    if (readingData.loading) {
      return;
    }
    setDataLocaly(readingData.value.results);
    setCountRecords(readingData.value.count);
  }, [readingData.loading]);

  const isLoading = dataLocaly === undefined || readingFilteringData.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid container spacing={1} justifyContent={"flex-end"}>
        <Grid item xs={12}>
          <EquipmentTable
            filterPrefix={EQUIPMENT_BMS_TABLE_NAME}
            data={dataLocaly}
            showCheckbox={false}
            showCleanFilterIcon={true}
            countRecords={countRecords}
            page={page}
            onPageChange={handleChangePageWithSearchParams}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            resetPageNumber={resetPageNumber}
            filteringData={readingFilteringData}
            style={{ maxHeight: "75vh" }}
            tableConfig={EQUIPMENT_BMS_TABLE_CONFIG}
            showExportToFileButton={true}
            exportToFileUrl={exportEquipmentBmsToFileUrl}
            exportToFileSearchParams={readingSearchParamsWithEquipmentId}
            exportToFileHeaders={exportHeaders}
            exportToFileFileName={`${t("headers.equipment_reading")}.xlsx`}
            onClickMultipleLocationAlert={handlePreviewEnclosure}
            onClickAssignData={onOpenEquipmentBmsDetailsDialog}
          />
        </Grid>
        {openEquipmentBmsDetailsDialog && (
          <EquipmentBmsDetailsDialog
            open={openEquipmentBmsDetailsDialog}
            onClose={onCloseEquipmentBmsDetailsDialog}
            equipmentBmsId={equipmentBmsId}
            onSubmitCallback={refreshTable}
          />
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => props.onClose()}
      titleAlign="center"
      maxWidth={"lg"}
      title={t("form.meter_passport.reading_history")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

ReadingForEquipmentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  equipmentId: PropTypes.string,
};

ReadingForEquipmentDialog.defaultProps = {};

export default ReadingForEquipmentDialog;
