import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import LocalizationForm from "../../drawer/UniversalDetailsDrawer/UniversalDetailsDrawerForms/LocalizationForm";
import TicketOrderUpperForm from "./TicketOrderUpperForm";
import TicketOrderLeftForm from "./TicketOrderLeftForm";
import { Grid } from "@mui/material";
import { useForm } from "../../../hooks/useForm";
import ActionIconsForm from "../../drawer/UniversalDetailsDrawer/UniversalDetailsDrawerForms/ActionIconsForm";
import useDialog from "../../../hooks/useDialog";
import EnclosuresByItemDialog from "../../dialog/EnclosuresByItemDialog/EnclosuresByItemDialog";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useActionButtonsService from "../../../services/actionButtonsService";
import { getErrorMsg } from "../../../helpers/methods";

const TicketOrderDetailsForm = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const snackbarAlert = useSnackbarAlert();
  const { getActionButtonsForUserAndItemType } = useActionButtonsService();

  const {
    formValue,
    setFormValue,
    onChangeWithCallback,
    onChangeAutocompleteFieldWithObjectOptionsAndCallback,
    onChangeDateWithCallback,
  } = useForm(props.itemData);

  const [
    openEnclosuresDialog,
    onOpenEnclosuresDialog,
    onCloseEnclosuresDialog,
  ] = useDialog();

  const handleChangeFieldValue = (name, value) => {
    setIsLoading(true);
    const oldValue = formValue[name];
    props
      .onUpdateDataPromise(formValue.id, { [name]: value })
      .then(() => {
        props.onRefetchData();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(getErrorMsg(error.data));
        setFormValue((prev) => ({ ...prev, [name]: oldValue }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeLocations = (locationData, onCloseDialog) => {
    const oldLocationData = formValue.locations;
    setIsLoading(true);
    props
      .onUpdateDataPromise(formValue.id, { objects: locationData })
      .then((data) => {
        setFormValue((prev) => ({ ...prev, locations: data.locations }));
        onCloseDialog();
        props.onRefetchData();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(getErrorMsg(error.data));
        setFormValue((prev) => ({ ...prev, locations: oldLocationData }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeStatus = (statusData, onCloseDialog) => {
    const oldStatus = formValue.ticket_status;
    const oldSubStatus = formValue.ticket_substatus;
    setIsLoading(true);
    props
      .onUpdateDataPromise(formValue.id, { status_data: statusData })
      .then((data) => {
        setFormValue((prev) => ({
          ...prev,
          ticket_status: data.ticket_status,
          ticket_substatus: data.ticket_substatus,
        }));
        onCloseDialog();
        props.onRefetchData();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(getErrorMsg(error.data));
        setFormValue((prev) => ({
          ...prev,
          ticket_status: oldStatus,
          ticket_substatus: oldSubStatus,
        }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onOpenEnclosuresDialogLocal = () => {
    onOpenEnclosuresDialog();
  };

  const onCloseEnclosuresDialogLocal = (existsChanges) => {
    if (existsChanges) {
      props.onRefetchData();
    }
    onCloseEnclosuresDialog();
  };

  const handleCloseCreateTicketDialog = (existsChanges, resData) => {
    if (existsChanges) {
      navigate(`/ticket_details/${resData.id}`);
    }
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      <Grid item xs={12}>
        <TicketOrderUpperForm
          readOnly={props.readOnly}
          isTicketClaim={props.isTicketClaim}
          itemType={props.itemType}
          formValue={formValue}
          filteringData={props.filteringData}
          onChange={onChangeWithCallback(handleChangeFieldValue)}
          onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <TicketOrderLeftForm
          readOnly={props.readOnly}
          isTicketClaim={props.isTicketClaim}
          itemType={props.itemType}
          formValue={formValue}
          filteringData={props.filteringData}
          onChange={onChangeWithCallback(handleChangeFieldValue)}
          onChangeDate={onChangeDateWithCallback(handleChangeFieldValue)}
          onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          isOM={props.isOM}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <LocalizationForm
          inBox={true}
          itemForChangeLocalizationId={formValue.id}
          isAllowedToChangeLocalization={
            !props.readOnly && !props.isTicketClaim
          }
          localizationData={formValue.locations}
          isMultipleLocalization={formValue.is_multiple_locations}
          titleVariant={"h6"}
          onChangeLocalization={handleChangeLocations}
          boxHeight={
            props.itemType === "ticket"
              ? props.isOM
                ? "320px"
                : "330px"
              : "425px"
          }
        />
        <ActionIconsForm
          isItemClosed={props.readOnly}
          isItemFinallyClosed={props.isItemFinallyClosed}
          isTicketClaim={props.isTicketClaim}
          isItemCancelled={formValue.is_cancel}
          itemId={formValue.id}
          itemNumber={
            props.itemType === "ticket"
              ? formValue.ticket_nr
              : formValue.order_nr
          }
          onChangeStatus={handleChangeStatus}
          isLoading={isLoading}
          myOfferCount={formValue.my_offer_count}
          offerCount={formValue.offer_count}
          enclosuresCount={formValue.enclosures_count}
          ticketRelationsCount={formValue.ticket_relation_count}
          onClickEnclosures={onOpenEnclosuresDialogLocal}
          onCloseCopyTicketDialog={handleCloseCreateTicketDialog}
          availableActionButtons={getActionButtonsForUserAndItemType(
            props.itemType
          )}
          itemType={props.itemType}
          onRefetchData={props.onRefetchData}
          isAllowedToCopyTicket={formValue.is_allowed_to_copy_ticket}
          itemData={formValue}
        />
      </Grid>
      {openEnclosuresDialog && (
        <EnclosuresByItemDialog
          open={openEnclosuresDialog}
          onClose={onCloseEnclosuresDialogLocal}
          itemId={formValue.id}
          itemType={props.itemType}
        />
      )}
    </Grid>
  );
};

TicketOrderDetailsForm.propTypes = {
  itemType: PropTypes.oneOf(["ticket", "order"]),
  filteringData: PropTypes.object,
  itemData: PropTypes.object,
  onUpdateDataPromise: PropTypes.func,
  onRefetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  isItemFinallyClosed: PropTypes.bool,
  isTicketClaim: PropTypes.bool,
  isOM: PropTypes.bool,
};

TicketOrderDetailsForm.defaultProps = {
  readOnly: false,
};

export default TicketOrderDetailsForm;
