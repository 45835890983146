import { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import {
  OWNER_TABLE_FIELDS_CONFIG,
  OWNER_TABLE_NAME,
} from "./PageTablesConfig";
import OwnerTable from "../../components/table/OwnerTable";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import useUserService from "../../services/userService";
import { useEmptyArrayMemo } from "../../hooks/useEmptyArrayMemo";
import useDialog from "../../hooks/useDialog";
import OwnerDetailsDialog from "../../components/dialog/OwnerDetailsDialog";
import OwnerManagmentToolBar from "../../components/bar/OwnerManagmentToolBar/OwnerManagmentToolBar";
import TableService from "../../services/tableService";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import UniversalFilterForm from "../../components/form/UniversalFilterForm";
import FilterDialog from "../../components/base/FilterDialog";
import DetailsDrawer from "../../components/drawer/UniversalDetailsDrawer/UniversalDetailsDrawer";
import useBasicDrawer from "../../hooks/useBasicDrawer";
import UserFilterDialog from "../../components/dialog/UserFilterDialog";
import { NAVIGATION_DRAWER_TYPE_LEGAL } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import { EDIT_OWNERS_PERMISSION } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";

export default function OwnerManagementPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [ownersLocal, setOwnersLocal] = useState();
  const [ownerDataItem, setOwnersDataItem] = useState();
  const [countRecords, setCountRecords] = useState();
  const selectedOwnerIdRef = useRef();
  const [hasEditPermission] = useCheckPermission(EDIT_OWNERS_PERMISSION)

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);

  const [
    openOwnerDetailsDialog,
    onOpenOwnerDetailsDialog,
    onCloseOwnerDetailsDialog,
  ] = useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const {
    page: ownerDataPage,
    pageSize: ownerDataPageSize,
    handleChangePageWithSearchParams: handleChangeOwnersDataPage,
    handleChangePageSizeWithSearchParams: handleChangeOwnersDataPageSize,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const handleChangeRowsPerPage = (e) => {
    handleChangeOwnersDataPageSize(e, parseInt(e.target.value));
  };


  const {
    getOwners,
    getOwnerDetails,
    getOwnersFilteringData,
    updateOwnerDetails
  } = useUserService();

  const ownerDetailsFn = useAsyncFn(getOwnerDetails);
  const updateOwnerDetailsFn = useAsyncFn(updateOwnerDetails);

  const ownersData = useAsync(() => getOwners(searchParams), [searchParams]);
  const ownerFilteringData = useAsync(() => getOwnersFilteringData(), []);

 

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const onCleanFlitersInFilterDialog = () => {
    setOwnersLocal(undefined);
    setCountRecords(undefined);
  };

  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [OWNER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [OWNER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  const { emptyArrayMemo } = useEmptyArrayMemo();

  useEffect(() => {
    if (ownersData.loading) {
      return;
    }
    setOwnersLocal(ownersData.value.results);
    setCountRecords(ownersData.value.count);
  }, [ownersData.loading]);


  const handleOpenOwnerDetails = useCallback(
    (e, ownerId) => {
      e.stopPropagation();
      setOpenDetailsDrawer(false);
      setOwnersDataItem(undefined);
      selectedOwnerIdRef.current = ownerId;
      onOpenOwnerDetailsDialog();
    },
    [selectedOwnerIdRef.current]
  );

  const onCloseOwnerDialog = useCallback(() => {
    selectedOwnerIdRef.current = undefined;
    ownersData.refetch();
    onCloseOwnerDetailsDialog();
    setOpenDetailsDrawer(false)
  }, [selectedOwnerIdRef.current]);

  const fullRefreshTable = () => {
    setOwnersLocal(undefined);
    ownersData.refetch();
  };

  const onRefetchDrawerData = () => {
    ownerDetailsFn.execute(selectedOwnerIdRef.current).then((data) => {
      setOwnersDataItem(data);
    });
  };

  const handleClickRow = (ownerId) => {
    if (!ownerId || ownerId === selectedOwnerIdRef.current) {
      selectedOwnerIdRef.current = null;
      setOpenDetailsDrawer(false);
      setOwnersDataItem(undefined);
    } else {
      setOpenDetailsDrawer(true);
      selectedOwnerIdRef.current = ownerId;
      onRefetchDrawerData();
    }
  };

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        fullRefreshTable();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const onUpdateOwnerDataPromise = useCallback(
    (data) => {
      return updateOwnerDetailsFn
        .execute(selectedOwnerIdRef.current, data)
        .then((res) => {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.owner_updated")
          );
          onRefetchDrawerData();
          fullRefreshTable();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_owner_updating")
          );
          return Promise.reject(error);
        });
    },
    [selectedOwnerIdRef.current]
  );

  const isLoading = ownersLocal === undefined || countRecords === undefined;

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={setOpenDetailsDrawer}
      itemData={ownerDataItem}
      filteringData={ownerFilteringData}
      itemType={"owner"}
      isLoading={ownerDetailsFn.loading}
      onUpdateDataPromise={onUpdateOwnerDataPromise}
      readOnly={!hasEditPermission}
      onRefetchData={onRefetchDrawerData}
      onRefetchTableData ={fullRefreshTable}
    >
      <NavigationDrawer
        drawerType={NAVIGATION_DRAWER_TYPE_LEGAL}
        pageName={pageName}
      >
        <DefaultPageWrapper titleKey={"owners"}>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <OwnerManagmentToolBar
                style={{ marginBlock: "5px" }}
                onClickAdjustTable={onOpenTableColumnVisibilityDialog}
                onClickSearch={onOpenFilterDialog}
                onClickMyFilters={onOpenUserFiltersDialog}
                onClickCreateOwner={onOpenOwnerDetailsDialog}
                openFilterKey={"owner_active"}
                params={searchParams}
                hasPermission={hasEditPermission}
                ownerId={selectedOwnerIdRef.current}
              />
            </Grid>
            <Grid item xs={12}>
              <LoaderWrapper showLoader={isLoading}>
                <OwnerTable
                  data={ownersLocal}
                  showCheckbox={false}
                  showCleanFilterIcon={true}
                  countRecords={countRecords}
                  page={ownerDataPage}
                  onClickRow={handleClickRow}
                  onPageChange={handleChangeOwnersDataPage}
                  rowsPerPage={ownerDataPageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  selectedOwnerId={selectedOwnerIdRef.current}
                  filteringData={ownerFilteringData}
                  hiddenColumns={
                    hiddenColumnsForTables
                      ? hiddenColumnsForTables[OWNER_TABLE_NAME]
                      : emptyArrayMemo
                  }
                  columnsOrders={
                    columnsOrdersForTables
                      ? columnsOrdersForTables[OWNER_TABLE_NAME]
                      : undefined
                  }
                  style={{ maxHeight: "vh" }}
                  tableConfig={OWNER_TABLE_FIELDS_CONFIG}
                  showContextMenu={false}
                  contextMenuActions={emptyArrayMemo}
                  showDetailsIcon={false}
                  onClickEdit={handleOpenOwnerDetails}
                  hasPermission={hasEditPermission}
                  isLoading = {ownersData.loading || isLoading}
                />
              </LoaderWrapper>
            </Grid>
            {openTableColumnVisibilityDialog && (
              <TableColumnVisibilityDialog
                open={openTableColumnVisibilityDialog}
                onClose={onCloseTableColumnVisibilityDialog}
                onSubmit={handleUpdateUserPreferences}
                tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
                  [
                    {
                      name: OWNER_TABLE_NAME,
                      config: OWNER_TABLE_FIELDS_CONFIG,
                    },
                  ],
                  columnsOrdersForTables
                )}
                tablesHiddenColumns={hiddenColumnsForTables}
                isLoading={userPreferencesForPage.loading}
              />
            )}
            {openFilterDialog && (
              <FilterDialog
                open={openFilterDialog}
                onCleanFilters={onCleanFlitersInFilterDialog}
                onClose={onCloseFilterDialog}
                resetPageNumber={resetPageNumber}
                filterForm={
                  <UniversalFilterForm
                    filteringData={ownerFilteringData}
                    filtersConfig={OWNER_TABLE_FIELDS_CONFIG}
                    includeOpenCloseFilter={false}
                  />
                }
              />
            )}

            {openOwnerDetailsDialog && (
              <OwnerDetailsDialog
                open={openOwnerDetailsDialog}
                onClose={onCloseOwnerDialog}
                ownerId={selectedOwnerIdRef.current}
                readOnly={!hasEditPermission}
              />
            )}

            {openUserFiltersDialog && (
              <UserFilterDialog
                open={openUserFiltersDialog}
                onClose={onCloseUserFiltersDialog}
                pageName={pageName}
                filterForm={
                  <UniversalFilterForm
                    filteringData={ownerFilteringData}
                    filtersConfig={OWNER_TABLE_FIELDS_CONFIG}
                    includeOpenCloseFilter={false}
                  />
                }
              />
            )}
          </Grid>
        </DefaultPageWrapper>
      </NavigationDrawer>
    </DetailsDrawer>
  );
}
