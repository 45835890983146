import { useTranslation } from "react-i18next";
import "./pricelist.css";

export default function NewPriceTable(props) {

    const { t } = useTranslation();

    return (

                <table className="pricetable">
                    <thead>
                        <tr>
                            <th className="priceth">{t("page.price_list_page.service")}</th>
                            <th className="priceth">{t("page.price_list_page.net_price")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.printing_on_an_access_card")}</td>
                            <td className="pricetd">{`20,00 zł/${t("page.price_list_page.piece")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.loss_or_damage_of_rfid_transponder_[lf]_or_access_card")}</td>
                            <td className="pricetd">{`50,00 zł/${t("page.price_list_page.piece")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.programming_an_additional_prox_card")}</td>
                            <td className="pricetd">{`10,00 zł/${t("page.price_list_page.piece")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.programming_an_additional_seos_or_access_mobile_card")}</td>
                            <td className="pricetd">{`20,00 zł/${t("page.price_list_page.piece")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.reprogramming_a_prox_card")}</td>
                            <td className="pricetd">{`10,00 zł/${t("page.price_list_page.piece")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.reprogramming_a_seos_or_access_mobile_card")}</td>
                            <td className="pricetd">{`20,00 zł/${t("page.price_list_page.piece")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.generating_a_report_from_the_building_access_control_system")}</td>
                            <td className="pricetd">{`150,00 zł/${t("page.price_list_page.piece")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.generating_a_report_from_the_parking_system")}</td>
                            <td className="pricetd">{`150,00 zł/${t("page.price_list_page.piece")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.generating_a_utility_consumption_report")}</td>
                            <td className="pricetd">{`150,00 zł/${t("page.price_list_page.piece")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.additional_fee_for_each")}</td>
                            <td className="pricetd">{`8 EUR/${t("page.price_list_page.user_per_month")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.verification_of_cctv_recordings")}</td>
                            <td className="pricetd">{`50,00 zł/ 1h ${t("page.price_list_page.per_video_verification")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.deactivating_access_control_on_internal_or_entrance_doors")}</td>
                            <td className="pricetd">{`150,00 zł/${t("page.price_list_page.per_service")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.security_personnel_assistance_during_work")}</td>
                            <td className="pricetd">{`420,00 zł/ 10h`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.technical_group_personnel_assistance_during_work")}</td>
                            <td className="pricetd">65,00 zł/ 1 h </td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.additional_fee_for_parking_a_bicycle_for_over_72_hours")}</td>
                            <td className="pricetd">20,00 zł/ 72 h  </td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.fee_for_lost_key_(cylinder_replacement)")}</td>
                            <td className="pricetd">{`50,00 zł/${t("page.price_list_page.per_service")}`}</td>
                        </tr>

                        <tr>
                            <td className="pricetd">{t("page.price_list_page.1_hour_parking_voucher")}</td>
                            <td className="pricetd">{`90,00 zł/ 10 ${t("page.price_list_page.pieces")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.2_hour_parking_voucher")}</td>
                            <td className="pricetd">{`150,00 zł/ 10 ${t("page.price_list_page.pieces")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.4_hour_parking_voucher")}</td>
                            <td className="pricetd">{`270,00 zł/ 10 ${t("page.price_list_page.pieces")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.24_hour_parking_voucher")}</td>
                            <td className="pricetd">{`620,00 zł/ 10 ${t("page.price_list_page.pieces")}`}</td>
                        </tr>

                        <tr>
                            <td className="pricetd">{t("page.price_list_page.1_hour_parking_voucher")}</td>
                            <td className="pricetd">{`130,00 zł/ 25 ${t("page.price_list_page.pieces")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.2_hour_parking_voucher")}</td>
                            <td className="pricetd">{`280,00 zł/ 25 ${t("page.price_list_page.pieces")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.4_hour_parking_voucher")}</td>
                            <td className="pricetd">{`570,00 zł/ 25 ${t("page.price_list_page.pieces")}`}</td>
                        </tr>
                        <tr>
                            <td className="pricetd">{t("page.price_list_page.24_hour_parking_voucher")}</td>
                            <td className="pricetd">{`1450,00 zł/ 25 ${t("page.price_list_page.pieces")}`}</td>
                        </tr>


                    </tbody>
                </table>
    );
}
