import * as React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

import { overflowButtonStyle } from "../../../helpers/styles";

export default function SplitButton(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (key, callback) => {
    if (props.withChangeSelectedItem) {
      props.setSelectedItemKey && props.setSelectedItemKey(key);
    }
    callback();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const getStartIcon = () => {
    if (props.withChangeSelectedItem)
      return props.options.find(
        (option) => option.key === props.selectedItemKey
      )?.startIcon;

    return props.startIcon;
  };

  const getEndIcon = () => {
    if (props.withChangeSelectedItem)
      return props.options.find(
        (option) => option.key === props.selectedItemKey
      )?.endIcon;

    return props.endIcon;
  };

  const getLabel = () => {
    if (props.withChangeSelectedItem)
      return props.options.find(
        (option) => option.key === props.selectedItemKey
      )?.label;

    return props.label;
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        size="small"
        sx={{ width: "100%" }}
        color={props.color}
        disabled={props.disabled}
      >
        <Button
          fullWidth
          startIcon={getStartIcon()}
          endIcon={getEndIcon()}
          onClick={props.onClick ? props.onClick : handleToggle}
        >
          <span style={overflowButtonStyle}>{getLabel()}</span>
        </Button>
        <Button size="small" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 10,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {props.options.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={() =>
                        handleMenuItemClick(option.key, option.callback)
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

SplitButton.propTypes = {
  label: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      callback: PropTypes.func,
    })
  ),
  withChangeSelectedItem: PropTypes.bool,
  selectedItemKey: PropTypes.string,
  setSelectedItemKey: PropTypes.func,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

SplitButton.defaultProps = {
  withChangeSelectedItem: false,
  color: "primary",
  disabled: false,
};
