import React from "react";

import BoxWithLabel from "../../box/BoxWithLabel";

import { useTranslation } from "react-i18next";

import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import CellTowerIcon from "@mui/icons-material/CellTower";

import useDialog from "../../../hooks/useDialog";
import ReadingForEquipmentDialog from "../../dialog/ReadingForEquipmentDialog/ReadingForEquipmentDialog";

const MeterReadingHistoryForm = (props) => {
  const { t } = useTranslation();

  const [
    openEquipmentReadingDialog,
    onOpenEquipmentReadingDialog,
    onCloseEquipmentReadingDialog,
  ] = useDialog();

  return (
    <BoxWithLabel
      label={t("form.meter_passport.reading_history")}
      boxStyle={{ height: "100%" }}
      style={{ height: "100%", display: "flex", justifyContent: "center" }}
    >
      <IconButtonWithTooltip
        title={t("form.meter_passport.reading_history")}
        fontSize={"500%"}
        onClick={onOpenEquipmentReadingDialog}
      >
        <CellTowerIcon fontSize="inherit" color="primary" />
      </IconButtonWithTooltip>
      {openEquipmentReadingDialog && (
        <ReadingForEquipmentDialog
          equipmentId={props.equipmentId}
          onClose={onCloseEquipmentReadingDialog}
          open={openEquipmentReadingDialog}
        />
      )}
    </BoxWithLabel>
  );
};

export default MeterReadingHistoryForm;
