import {
  electricityTariffUrl,
  electricityTariffDetailsUrl,
  electricityTariffFilteringDataUrl,
  electricityTariffGroupUrl,
  electricityTariffGroupDetailsUrl,
  electricityTariffGroupFilteringDataUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useTariffService() {
  const axiosPrivate = useAxiosPrivate();

  const getElectricityTariffData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(electricityTariffUrl, searchParams)
    );
  };

  const getElectricityTariffFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(electricityTariffFilteringDataUrl, searchParams)
    );
  };

  const getElectricityTariffDetailsData = (id) => {
    return makeRequest(axiosPrivate, electricityTariffDetailsUrl(id));
  };

  const createElectricityTariff = (dataToSend) => {
    return makeRequest(axiosPrivate, electricityTariffUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateElectricityTariff = (dataToSend, id) => {
    return makeRequest(axiosPrivate, electricityTariffDetailsUrl(id), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getElectricityTariffGroupData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(electricityTariffGroupUrl, searchParams)
    );
  };

  const getElectricityTariffGroupFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        electricityTariffGroupFilteringDataUrl,
        searchParams
      )
    );
  };

  const getElectricityTariffGroupDetailsData = (id) => {
    return makeRequest(axiosPrivate, electricityTariffGroupDetailsUrl(id));
  };

  const createElectricityTariffGroup = (dataToSend) => {
    return makeRequest(axiosPrivate, electricityTariffGroupUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateElectricityTariffGroup = (dataToSend, id) => {
    return makeRequest(axiosPrivate, electricityTariffGroupDetailsUrl(id), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  return {
    getElectricityTariffData,
    getElectricityTariffDetailsData,
    getElectricityTariffFilteringData,
    createElectricityTariff,
    updateElectricityTariff,
    getElectricityTariffGroupData,
    getElectricityTariffGroupDetailsData,
    getElectricityTariffGroupFilteringData,
    createElectricityTariffGroup,
    updateElectricityTariffGroup,
  };
}
