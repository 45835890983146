import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import ValidationForm from "../ValidationForm";
import EditableTable from "../../table/EditableTable/EditableTable";
import { useEmptyArrayMemo } from "../../../hooks/useEmptyArrayMemo";
import BoxWithTypography from "../../box/BoxWithTypography/BoxWithTypography";
import TableService from "../../../services/tableService";
import { BOOLEAN_FIELD_TYPE, DATE_FIELD_TYPE, NUMERIC_FIELD_TYPE, TEXT_FIELD_TYPE } from "../../../helpers/constants";
import _default from "@emotion/styled";
import AddButton from "../../button/AddButton";
import CloseIcon from '@mui/icons-material/Close';
import i18n from "../../../i18n";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import FloatField from "../../base/FloatField";


const EditableBoxForm = React.memo((props) => {

  const { t } = useTranslation();

  const TRANCHE_TABLE_FIELDS_CONFIG = React.useMemo(() => {
    return [
      {
        name: "icoschedule_amount_to_pay",
        getValueCallback: (rowData) => rowData?.icoschedule_amount_to_pay,
        label: t("form.schedule_form.amount"),
        dataType: NUMERIC_FIELD_TYPE,
        editable: true
      },
      {
        name: "icoschedule_due_date",
        getValueCallback: (rowData) => rowData?.icoschedule_due_date,
        label: t("form.schedule_form.ico_due_date"),
        dataType: DATE_FIELD_TYPE,
        editable: true
      },
      {
        name: "icoschedule_is_repaid_on_time",
        getValueCallback: (rowData) => i18n.t(`${rowData?.icoschedule_is_repaid_on_time}`),
        label: t("form.schedule_form.is_run_on_time"),
        dataType: TEXT_FIELD_TYPE,
        editable: false
      }
    ]
  }, [])

  const INSTALLMENT_TABLE_FIELDS_CONFIG = React.useMemo(() => {
    return [
      {
        name: "icoschedule_amount_to_pay",
        getValueCallback: (rowData) => rowData?.icoschedule_amount_to_pay,
        label: t("form.schedule_form.amount"),
        dataType: NUMERIC_FIELD_TYPE,
        editable: true
      },
      {
        name: "icoschedule_due_date",
        getValueCallback: (rowData) => rowData?.icoschedule_due_date,
        label: t("form.schedule_form.ico_due_date"),
        dataType: DATE_FIELD_TYPE,
        editable: true
      },
      {
        name: "icoschedule_is_repaid_on_time",
        getValueCallback: (rowData) => i18n.t(`${rowData?.icoschedule_is_repaid_on_time}`),
        label: t("form.schedule_form.is_repaid_on_time"),
        dataType: TEXT_FIELD_TYPE,
        editable: false
      }
    ]
  }, [])

  const INTEREST_TABLE_FIELDS_CONFIG = React.useMemo(() => {
    return [
      {
        name: "icoschedule_amount_to_pay",
        getValueCallback: (rowData) => rowData?.icoschedule_amount_to_pay,
        label: t("form.schedule_form.amount"),
        dataType: NUMERIC_FIELD_TYPE,
        editable: false
      },
      {
        name: "icoschedule_due_date",
        getValueCallback: (rowData) => rowData?.icoschedule_due_date,
        label: t("form.schedule_form.ico_due_date"),
        dataType: DATE_FIELD_TYPE,
        editable: true,
      }
    ]
  }, [])


  const { emptyArrayMemo } = useEmptyArrayMemo()

  const style = useMemo(() => {
    return { maxHeight: "vh" }
  }, [])

  const getScheduleConfig = useCallback(() => {
    switch (props.kind) {
      case "tranche":
        return TRANCHE_TABLE_FIELDS_CONFIG
      case "installment":
        return INSTALLMENT_TABLE_FIELDS_CONFIG
      case "interest":
        return INTEREST_TABLE_FIELDS_CONFIG
    }

  }, [props.kind])

  const tableData = React.useMemo(() => {
    return TableService.getFieldsConfigForEditableTable(
      getScheduleConfig(),
      props.data,
      { minDate: props.minIntallmentEarlierRepaymentDate },
      props.onClickRemove,
      props.kind == "installment" && props.dialogMode === 'changes_wo_appendix')
  }, [props.data, props.minIntallmentEarlierRepaymentDate, INTEREST_TABLE_FIELDS_CONFIG, getScheduleConfig, props.kind])


  const onEditRowByKind = useCallback((name, value, index) => {
    props.onEditRow(name, value, index, props.kind)
    switch (props.kind) {
      case "tranche":
        props.setTranchesChangedByUser(true)
        props.setScheduleUpdated(false)
        break;
      case "installment":
        props.setInstallmentsChangedByUser(true)
        props.setScheduleUpdated(false)
        break;
      case "interest":
        props.setInterestsChangedByUser(true)
        props.setScheduleUpdated(false)
        break;
    }

  }, [props.kind])

  const getAddRowButton = useCallback(() => {
    if (props.kind == "installment" && props.dialogMode === 'changes_wo_appendix') {
      return (<AddButton onClick={props.onHandleAddNewInstallmentTranche} />)
    }
  }, [props.kind, props.onHandleAddNewInstallmentTranche, props.dialogMode])

  return (
    <BoxWithTypography
      warning={props.showWarning}
      error={!props.areDateValid || !props.isSumValid || !props.isFirstDateAfterActivationDateValid || !props.isPartialInstallmentSumIsValid}
      style={{ padding: "10px" }}
      label={t(`form.schedule_form.${props.kind}`)}
      component={"div"}
    >
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
        <div style={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
          {/* {getAddRowButton()} */}
          <EditableTable
            data={tableData}
            readOnly={props.readOnly}
            editable={true}
            showCheckbox={false}
            kind={props.kind}
            onDataChange={onEditRowByKind}
            filteringData={emptyArrayMemo}
            style={style}
            tableConfig={getScheduleConfig()}
            showContextMenu={false}
          />
        </div>
        </Grid>
        {props.kind === "interest" &&
        <Grid item xs={6}>
          <FloatField
            label={t("form.schedule_form.interest_sum")}
            value={props.totalInterestSum}
            showNumberSeparator={true}
            suffix={props.selectedCurrency}
            disabled={true}
          />
        </Grid>}
        <Grid item xs={12}>
        <ValidationForm
          isSumEqualConditionValue={props.isSumValid}
          areIcoScheduleDatesAsc={props.areDateValid}
          isFirstDateAfterActivationDateValid={props.isFirstDateAfterActivationDateValid}
          isPartialInstallmentSumIsValid={props.isPartialInstallmentSumIsValid}
          isEarlierRepaymentDateIsAfterLastDateTranferToPayment={props.isEarlierRepaymentDateIsAfterLastDateTranferToPayment}
        />
        </Grid>
      </Grid>
    </BoxWithTypography>
  )

});

const IcoScheduleForm = React.memo((props) => {

  const { t } = useTranslation();

  const scheduleKinds = useMemo(() => {
    return ["tranche", "installment", "interest"]
  }, [])


  const getShowWaring = useCallback((kind) => {
    switch (kind) {
      case "tranche":
        return props.areTranchesChangedByUser
      case "installment":
        return props.areInstallmentsChangedByUser
      case "interest":
        return props.areInterestsChangedByUser
      default:
        return false
    }
  }, [props.areTranchesChangedByUser, props.areInstallmentsChangedByUser, props.areInterestsChangedByUser, props.kind])

  const getDatesValidation = useCallback((kind) => {
    switch (kind) {
      case "tranche":
        return props.isTanchesDatesTableValid
      case "installment":
        return props.isInstallmentDatesTableValid
      case "interest":
        return props.isInterestDatesTableValid
      default:
        return false
    }
  }, [props.isTanchesDatesTableValid, props.isInstallmentDatesTableValid, props.isInterestDatesTableValid, props.kind])

  const getSumValidation = useCallback((kind) => {
    switch (kind) {
      case "tranche":
        return props.isTanchesSumTableValid
      case "installment":
        return props.isInstallmentSumTableValid
      default:
        return true
    }
  }, [props.isTanchesSumTableValid, props.isInstallmentSumTableValid, props.kind])


  const getFirstDateAfterActivationDateValidation = useCallback((kind) => {
    switch (kind) {
      case "installment":
        return props.isFirstInstalmmentDateAfterActivationDateValid
      case "interest":
        return props.isFirstInterestDateAfterActivationDateValid
      default:
        return true
    }
  }, [props.isFirstInstalmmentDateAfterActivationDateValid, props.isFirstInterestDateAfterActivationDateValid, props.kind])

  const getPartialInstallmentSumIsValid = useCallback((kind) => {
    switch (kind) {
      case "installment":
        return props.checkPartialInstallmentSumIsValid
      default:
        return true
    }
  }, [props.checkPartialInstallmentSumIsValid, props.kind])

  const getEarlierRepaymentDateIsAfterLastDateTranferToPayment = useCallback((kind) => {
    switch (kind) {
      case "installment":
        return props.isEarlierRepaymentDateIsAfterLastDateTranferToPayment
      default:
        return true
    }
  }, [props.isEarlierRepaymentDateIsAfterLastDateTranferToPayment, props.kind])


  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={1}
    >
      {!props.isScheduleUpdated &&
        <Grid item xs={1.5}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={false}
            disabled={!props.isScheduleFormValid}
            onClick={props.onRecalculateSchedule}
          >
            {t(`form.schedule_form.recalculate_schedule`)}
          </LoadingButton>
        </Grid>}

      {scheduleKinds?.map((kind) => {
        return (
          <>
            <Grid item xs={props.isScheduleUpdated ? 4 : 3.5}>
              <EditableBoxForm
                kind={kind}
                totalInterestSum = {props.totalInterestSum}
                readOnly={props.readOnly}
                data={props.icoSchedules[kind]}
                onEditRow={props.onDataChange}
                selectedCurrency = {props.selectedCurrency}
                setTranchesChangedByUser={props.setTranchesChangedByUser}
                setInstallmentsChangedByUser={props.setInstallmentsChangedByUser}
                setInterestsChangedByUser={props.setInterestsChangedByUser}
                setScheduleUpdated={props.setScheduleUpdated}
                showWarning={getShowWaring(kind)}
                areDateValid={getDatesValidation(kind)}
                isSumValid={getSumValidation(kind)}
                isFirstDateAfterActivationDateValid={getFirstDateAfterActivationDateValidation(kind)}
                isPartialInstallmentSumIsValid={getPartialInstallmentSumIsValid(kind)}
                isEarlierRepaymentDateIsAfterLastDateTranferToPayment={getEarlierRepaymentDateIsAfterLastDateTranferToPayment(kind)}
              />
            </Grid>

          </>
        )
      })
      }

      <Grid item xs={12}>
        <ValidationForm
          icoScheduleShouldBeUpdated={!props.isScheduleUpdated}
          isActivationDateAfterMinInterestDate={props.isActivationDateAfterMinInterestDate}
        />
      </Grid>
    </Grid>
  );
});

IcoScheduleForm.propTypes = {
  readOnly: PropTypes.bool,
  icoId: PropTypes.string,
  onChangeSchedule: PropTypes.func,
  icoSchedules: PropTypes.array,
  isScheduleUpdated: PropTypes.bool,
  isScheduleFormValid: PropTypes.bool
};

IcoScheduleForm.defaultProps = {
  readOnly: false,
  icoSchedules: [],
  isScheduleUpdated: true,
  isScheduleFormValid: false
};

export default IcoScheduleForm;