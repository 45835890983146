import i18n from "../../../i18n";

import {
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../../helpers/constants";

import SelectFieldService from "../../../services/selectFieldService";

import DateService from "../../../services/dateService";

import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { IconButton } from "@mui/material";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../../helpers/methods";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

export const EQUIPMENT_BMS_TABLE_NAME = "equipment_bms";

export const EQUIPMENT_BMS_TABLE_CONFIG = [
  {
    name: "equipment",
    getValueCallback: (rowData) => rowData.equipment_nr,
    label: i18n.t("table.counter_repository.equipment"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.equipment,
      isObjectOption: true,
      optionLabelKey: "equipment_nr",
      addNewValue: false,
      multiple: true,
    }),
    other_export_field_name: "equipment_nr",
    sortable: true,
  },
  {
    name: "equipbms_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
        rowData.equipbms_date
      ),
    label: i18n.t("table.counter_repository.equipbms_date"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "equipbms_value",
    getValueCallback: (rowData) => rowData.equipbms_value,
    label: i18n.t("table.counter_repository.equipbms_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "equipbms_unit",
    getValueCallback: (rowData) => rowData.equipbms_unit,
    label: i18n.t("table.counter_repository.equipbms_unit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "equipbms_manual",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.equipbms_manual),
    label: i18n.t("table.counter_repository.equipbms_manual"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "equipbms_status",
    getValueCallback: (rowData) =>
      i18n.t(`backend_choices_list.${rowData.equipbms_status}`),
    label: i18n.t("table.counter_repository.equipbms_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: convertFlatListFromBackendToTranslatedOptionObject(
        filteringData.value.equipbms_status
      ),
      isObjectOption: true,
      multiple: true,
    }),
  },
  {
    name: "equipbms_note",
    getValueCallback: (rowData) => rowData.equipbms_note,
    label: i18n.t("table.counter_repository.equipment_note_bms"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "preview",
    getValueCallback: (rowData, onPreview) =>
      rowData.enclosure?.content_type === "application/pdf" ||
      (rowData.enclosure?.content_type &&
        rowData.enclosure?.content_type.includes("image")) ? (
        <IconButton
          color="primary"
          onClick={() => {
            onPreview(rowData.enclosure.id);
          }}
          sx={{ padding: "5px" }}
        >
          <PreviewOutlinedIcon fontSize="medium" />
        </IconButton>
      ) : null,
    label: i18n.t("table.enclosure.preview"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { maxWidth: "100px" },
    }),
  },
  {
    name: "edit",
    label: i18n.t("edit"),
    getValueCallback: (rowData, onPreview, onClickEdit) => {
      const content = (
        <ModeEditOutlineOutlined onClick={() => onClickEdit(rowData.id)} />
      );
      return rowData.equipbms_manual ? content : "";
    },
    filterType: null,
  },
];
