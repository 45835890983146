import React from "react";
import PropTypes from "prop-types";
import TableService from "../../../services/tableService";
import FilterTable from "../FilterTable";
import usePaginationWithSearchParams from "../../../hooks/usePaginationWithSearchParams";

export default function AgreementTable(props) {

  const {
    page: dataPage,
    pageSize: dataPageSize,
    handleChangePageWithSearchParams: handleChangeDataPage,
    handleChangePageSizeWithSearchParams: handleChangeDataPageSize,
  } = usePaginationWithSearchParams();

  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        props.tableConfig,
        props.data,
        props.hiddenColumns,
        props.columnsOrders,
        props.onClickEdit,
        props.hasPermission,
        props.onClickAccept,
        props.onClickGenerate,
        props.onClickSignDoc,
        props.onClickArchive,
        props.onClickUppload
      ),
      
    [props.tableConfig,
    props.data,
    props.hiddenColumns,
    props.columnsOrders,
    props.onClickEdit,
    props.hasPermission,
    props.onClickAccept]
  );

  return (
    <FilterTable
      showChangesCircle={props.showChangesCircle}
      showDetailsIcon={props.showDetailsIcon}
      checkedAll={props.checkedAll}
      onClickRow={props.onClickRow}
      showCheckbox={props.showCheckbox}
      onClickCheck={props.onClickCheck}
      onClickCheckAll={props.onClickCheckAll}
      data={data}
      filteringData={props.filteringData}
      countRecords={props.countRecords}
      page={dataPage}
      onPageChange={handleChangeDataPage}
      rowsPerPage={dataPageSize}
      onRowsPerPageChange={handleChangeDataPageSize}
      resetPageNumber={props.resetPageNumber}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        props.tableConfig,
        props.filteringData,
        props.hiddenColumns,
        props.columnsOrders
      )}
      selectedItemId={props.selectedAgreementId}
      style={props.style}
      filterPrefix={props.filterPrefix}
      showCleanFilterIcon={props.showCleanFilterIcon}
      showContextMenu={props.showContextMenu}
      contextMenuActions={props.contextMenuActions}
      showExportToFileButton={props.showExportToFileButton}
      exportToFileUrl={props.exportToFileUrl}
      exportToFileSearchParams={props.exportToFileSearchParams}
      exportToFileHeaders={props.exportToFileHeaders}
      exportToFileFileName={props.exportToFileFileName}
      onClickDetailsIcon={props.onClickDetailsIcon}
      detailsTitle={props.detailsTitle}
      isLoading={props.isLoading}
    />
  );
}

AgreementTable.propTypes = {
  data: PropTypes.object,
  showCheckbox: PropTypes.bool,
  onClickCheck: PropTypes.func,
  onClickCheckAll: PropTypes.func,
  style: PropTypes.object,
  hiddenColumns: PropTypes.array,
  columnsOrders: PropTypes.array,
  tableConfig: PropTypes.object,
  filterPrefix: PropTypes.string,
  showCleanFilterIcon: PropTypes.bool,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.arrayOf(PropTypes.object),
  showDetailsIcon: PropTypes.bool,
  showChangesCircle: PropTypes.func,
  showExportToFileButton: PropTypes.bool,
  exportToFileUrl: PropTypes.string,
  exportToFileSearchParams: PropTypes.object,
  exportToFileHeaders: PropTypes.array,
  exportToFileFileName: PropTypes.string,
};

AgreementTable.defaultProps = {
  hiddenColumns: [],
  data: [],
  showCheckbox: false,
  showCleanFilterIcon: false,
  showContextMenu: false,
  showDetailsIcon: false,
  contextMenuActions: [],
  showChangesCircle: false,
};
