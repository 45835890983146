import React, { useCallback, useEffect, useMemo } from "react";
import useLocationService from "../../../../services/locationService";
import PropTypes from "prop-types";
import { useAsync } from "../../../../hooks/useAsync";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import AutocompleteField from "../../../field/AutocompleteField";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { isEmptyArray } from "../../../../helpers/methods";

const LocationLevel = React.memo((props) => {
  const { t } = useTranslation();

  const { getObjectSelectList } = useLocationService();

  const getObjectsDependecyList = () => {
    // prevent to fetch objects options when new ticketOm is changed
    if (props.readOnly) return [];
    return [props.locationParent, props.ticketOm, props.options];
  };

  const objects = useAsync(() => {
    var searchParams = {};
    if (props.locationParent === null) {
      searchParams.parent__isnull = true;
    } else {
      searchParams.parent = props.locationParent;
    }
    if (props.ticketOm) {
      searchParams.ticket_om = props.ticketOm.id;
    }
    if (isEmptyArray(props.options) && displayLevel){
      return getObjectSelectList(searchParams);
    }
    return Promise.resolve(props.options)
  }, getObjectsDependecyList());

  const displayLevel = useMemo(() => {
    if (
      (props.readOnly && !props.value) ||
      props.maxAllowedLevelNumber < props.level
    )
      return false;

    if (
      props.level === 1 ||
      (objects.value && (props.showEmptyLevel || objects.value.length > 0))
    ) {
      return true;
    }
    if (objects.loading) {
      return true;
    }
    return false;
  }, [
    objects.value,
    props.level,
    props.value,
    props.readOnly,
    props.options,
    objects.loading,
  ]);

  // useEffect(() => {
  //   if (
  //     objects &&
  //     objects.value?.length === 1 &&
  //     !props.readOnly &&
  //     props.index != 0
  //   ) {
  //     props.onChange(objects.value[0], props.level);
  //   }
  // }, [objects.loading]);

  return (
    <>
      {displayLevel && (
        <Grid item sm={3} xs={12}>
          {objects.loading ? (
            <LoaderWrapper showLoader={true} />
          ) : (
            <AutocompleteField
              fullWidth
              readOnly={props.readOnly}
              options={objects.value}
              isObjectOption={true}
              optionLabelKey={
                props.level === 3 || props.level === 4
                  ? "object_name_with_code"
                  : "object_name"
              }
              variant={"outlined"}
              label={`${t("other.location_select.level")} ${props.level}`}
              value={props.value}
              required={props.required}
              onChange={(value_object, value_key) =>
                props.onChange(value_key, props.level)
              }
              error={props.errorField}
              helperText={props.helperTextField}
              multiple={false}
              addNewValue={false}
              disableClearable={props.level === 1}
            />
          )}
        </Grid>
      )}
    </>
  );
});

LocationLevel.propTypes = {
  level: PropTypes.number,
  locationParent: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  errorField: PropTypes.bool,
  readOnly: PropTypes.bool,
  createTicketLocation: PropTypes.bool,
  maxAllowedLevelNumber: PropTypes.number,
  showEmptyLevel: PropTypes.bool,
  options : PropTypes.array
};

LocationLevel.defaultProps = {
  locationParent: null,
  readOnly: false,
  createTicketLocation: false,
  maxAllowedLevelNumber: 5,
  showEmptyLevel: false,
  options : []
};

export default LocationLevel;
