import React, { useState } from "react";
import PropTypes from "prop-types";
import { sxTextFieldTable } from "./../TextFieldTable/TextFieldTable";

import DivField from "../DivField/DivField";
import DateField from "../DateField/DateField";

import useFieldTable from "../../../hooks/useFieldTable";
import DateService from "../../../services/dateService";

const DateFieldTable = React.memo((props) => {
  const [dateOpen, setDateOpen] = useState(false);
  const { isSelected, setIsSelected } = useFieldTable();

  return (
    <>
      {isSelected ? (
        <DateField
        {...props}
          label=""
          style={props.style}
          onChange={props.onChange}
          value={props.value}
          name={props.name}
          disabled={props.disabled}
          required={props.required}
          readOnly={props.readOnly}
          open={dateOpen}
          onClose={() => {
            setIsSelected(false);
            setDateOpen(false);
            props.onClose();
          }}
          textFieldSx={{
            ...sxTextFieldTable,
            "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
              display: "none",
            },
          }}
        />
      ) : (
        <DivField
          {...props}
          value={DateService.convertDateToFormatYYYYMMDD(props.value)}
          onSelectField={() => {
            setIsSelected(true);
            setDateOpen(true);
          }}
          type={undefined}
        />
      )}
    </>
  );
});

DateFieldTable.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onClose: PropTypes.func,
};

DateFieldTable.defaultProps = {
  disabled: false,
  readOnly: false,
};

export default DateFieldTable;
