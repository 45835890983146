import React from "react";

import BoxWithLabel from "../../box/BoxWithLabel";

import { useTranslation } from "react-i18next";

import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";
import GroupsIcon from "@mui/icons-material/Groups";

import TicketsForEquipmentDialog from "../../dialog/TicketsForEquipmentDialog/TicketsForEquipmentDialog";
import useDialog from "../../../hooks/useDialog";

const MeterTicketForm = (props) => {
  const { t } = useTranslation();

  const [
    openEquipmentTicketDialog,
    onOpenEquipmentTicketDialog,
    onCloseEquipmentTicketDialog,
  ] = useDialog();

  return (
    <BoxWithLabel
      label={t("form.meter_passport.tickets")}
      boxStyle={{ height: "100%" }}
      style={{ height: "100%", display: "flex", justifyContent: "center" }}
    >
      <IconButtonWithTooltip
        title={t("form.meter_passport.tickets")}
        fontSize={"500%"}
        onClick={onOpenEquipmentTicketDialog}
      >
        <GroupsIcon fontSize="inherit" color="primary" />
      </IconButtonWithTooltip>
      {openEquipmentTicketDialog && (
        <TicketsForEquipmentDialog
          equipmentId={props.equipmentId}
          onClose={onCloseEquipmentTicketDialog}
          open={openEquipmentTicketDialog}
        />
      )}
    </BoxWithLabel>
  );
};

export default MeterTicketForm;
