import i18n from "../../i18n";

import {
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
} from "../../helpers/constants";

import SelectFieldService from "../../services/selectFieldService";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";

import WarningAlertIcon from "../../components/other/WarningAlertIcon";
import PrefixSuffixFieldTableWrapper from "../../components/wrapper/PrefixSuffixFieldTableWrapper";

import WindowService from "../../services/windowService";
import DateService from "../../services/dateService";

export const counter_repository_TABLE_NAME = "counter_repository";

export const EQUIPMENT_STATE_TABLE_NAME = "equipment_state";
export const EQUIPMENT_INSTALATION_TABLE_NAME = "equipment_instalation";
export const EQUIPMENT_INSTALATION_TENANT_SET_TABLE_NAME = "tenant_set";
export const EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_NAME = "object_set";

export const EQUIPMENT_STATE_TABLE_PREFIX = "equipment_state";
export const EQUIPMENT_INSTALATION_TABLE_PREFIX = "equipment_instalation";
export const EQUIPMENT_INSTALATION_TENANT_SET_TABLE_PREFIX = "tenant_set";
export const EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_PREFIX = "object_set";

export const EQUIPMENT_STATE_TABLE_CONFIG = [
  {
    name: "passport",
    getValueCallback: (rowData) => (
      <FeedOutlinedIcon
        onClick={(e) =>
          WindowService.openInNewTab(
            `/counter_passport/${rowData.equipment_id}`
          )
        }
      />
    ),
    label: i18n.t("passport"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
  {
    name: "equipment",
    getValueCallback: (rowData) => rowData.equipment_nr,
    label: i18n.t("table.counter_repository.equipment"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.equipment,
      isObjectOption: true,
      optionLabelKey: "equipment_nr",
      addNewValue: false,
      multiple: true,
    }),
    other_export_field_name: "equipment_nr",
    sortable: true,
  },
  {
    name: "equipment_responsible_user",
    getValueCallback: (rowData) => rowData.equipment_responsible_user,
    label: i18n.t("table.counter_repository.equipment_responsible_user"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.equipment_responsible_user,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_category",
    getValueCallback: (rowData) => rowData.equipment_category,
    label: i18n.t("table.counter_repository.equipment_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_category,
      isObjectOption: true,
      optionLabelKey: "equcat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_media_type",
    getValueCallback: (rowData) => rowData.equipment_media_type,
    label: i18n.t("table.counter_repository.equipment_media_type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_media_type,
      isObjectOption: true,
      optionLabelKey: "equmedtyp_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equstate_is_in_bms",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.equstate_is_in_bms),
    label: i18n.t("table.counter_repository.equstate_is_in_bms"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_in_scheme",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_in_scheme
      ),
    label: i18n.t("table.counter_repository.equstate_is_in_scheme"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },

  {
    name: "equstate_is_temporary",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_temporary
      ),
    label: i18n.t("table.counter_repository.equstate_is_temporary"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_server_room",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_server_room
      ),
    label: i18n.t("table.counter_repository.equstate_is_server_room"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_building",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_building
      ),
    label: i18n.t("table.counter_repository.equstate_is_building"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_floor",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.equstate_is_floor),
    label: i18n.t("table.counter_repository.equstate_is_floor"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_damaged",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_damaged
      ),
    label: i18n.t("table.counter_repository.equstate_is_damaged"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_in_settlement",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_in_settlement
      ),
    label: i18n.t("table.counter_repository.equstate_is_in_settlement"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_subcounter",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_subcounter
      ),
    label: i18n.t("table.counter_repository.equstate_is_subcounter"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equstate_is_empty_location",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.equstate_is_empty_location
      ),
    label: i18n.t("table.counter_repository.equstate_is_empty_location"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipment_status",
    getValueCallback: (rowData) => rowData.equipment_status,
    label: i18n.t("table.counter_repository.equipment_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_status,
      isObjectOption: true,
      optionLabelKey: "equstat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equstate_start",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.equstate_start
      ),
    label: i18n.t("table.counter_repository.equstate_start"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "equstate_stop",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.equstate_stop
      ),
    label: i18n.t("table.counter_repository.equstate_stop"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
];

export const EQUIPMENT_INSTALATION_TABLE_CONFIG = [
  {
    name: "passport",
    getValueCallback: (rowData) => (
      <FeedOutlinedIcon
        onClick={(e) =>
          WindowService.openInNewTab(
            `/counter_passport/${rowData.equipment_id}`
          )
        }
      />
    ),
    label: i18n.t("passport"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
  {
    name: "equipment",
    getValueCallback: (rowData) => rowData.equipment_nr,
    label: i18n.t("table.counter_repository.equipment"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.equipment,
      isObjectOption: true,
      optionLabelKey: "equipment_nr",
      addNewValue: false,
      multiple: true,
    }),
    other_export_field_name: "equipment_nr",
  },
  {
    name: "objects_level_1_name",
    getValueCallback: (rowData) => rowData.location?.level_1?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_1_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2_name",
    getValueCallback: (rowData) => rowData.location?.level_2?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_2_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3_name",
    getValueCallback: (rowData) => rowData.location?.level_3?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_3_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_4_name",
    getValueCallback: (rowData) => rowData.location?.level_4?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_4_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_5_name",
    getValueCallback: (rowData) => rowData.location?.level_5?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_5_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_category",
    getValueCallback: (rowData) => rowData.equipment_category,
    label: i18n.t("table.counter_repository.equipment_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_category,
      isObjectOption: true,
      optionLabelKey: "equcat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_media_type",
    getValueCallback: (rowData) => rowData.equipment_media_type,
    label: i18n.t("table.counter_repository.equipment_media_type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_media_type,
      isObjectOption: true,
      optionLabelKey: "equmedtyp_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_status",
    getValueCallback: (rowData) => rowData.equipment_status,
    label: i18n.t("table.counter_repository.equipment_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_status,
      isObjectOption: true,
      optionLabelKey: "equstat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipinstal_date_instalation",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.equipinstal_date_instalation
      ),
    label: i18n.t("table.counter_repository.equipinstal_date_instalation"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "equipinstal_date_deinstalation",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.equipinstal_date_deinstalation
      ),
    label: i18n.t("table.counter_repository.equipinstal_date_deinstalation"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
];

export const EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_CONFIG = [
  {
    name: "passport",
    getValueCallback: (rowData) => (
      <FeedOutlinedIcon
        onClick={(e) =>
          WindowService.openInNewTab(
            `/counter_passport/${rowData.equipment_id}`
          )
        }
      />
    ),
    label: i18n.t("passport"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
  {
    name: "equipment",
    getValueCallback: (rowData) => rowData.equipment_nr,
    label: i18n.t("table.counter_repository.equipment"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.equipment,
      isObjectOption: true,
      optionLabelKey: "equipment_nr",
      addNewValue: false,
      multiple: true,
    }),
    other_export_field_name: "equipment_nr",
  },
  {
    name: "objects_level_1_name",
    getValueCallback: (rowData, onClickMultipleLocationAlert) => {
      return rowData.is_multiple_location ? (
        <PrefixSuffixFieldTableWrapper
          prefix={
            <WarningAlertIcon
              style={{ cursor: "pointer" }}
              onClick={(e) => onClickMultipleLocationAlert(e, rowData.id)}
            />
          }
        >
          {rowData.location[0]?.level_1?.object_name}
        </PrefixSuffixFieldTableWrapper>
      ) : (
        rowData.location[0]?.level_1?.object_name
      );
    },
    label: i18n.t("table.counter_repository.measuring_place_level_1_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2_name",
    getValueCallback: (rowData) => rowData.location[0]?.level_2?.object_name,
    label: i18n.t("table.counter_repository.measuring_place_level_2_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3_name",
    getValueCallback: (rowData) => rowData.location[0]?.level_3?.object_name,
    label: i18n.t("table.counter_repository.measuring_place_level_3_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_4_name",
    getValueCallback: (rowData) => rowData.location[0]?.level_4?.object_name,
    label: i18n.t("table.counter_repository.measuring_place_level_4_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_5_name",
    getValueCallback: (rowData) => rowData.location[0]?.level_5?.object_name,
    label: i18n.t("table.counter_repository.measuring_place_level_5_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_category",
    getValueCallback: (rowData) => rowData.equipment_category,
    label: i18n.t("table.counter_repository.equipment_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_category,
      isObjectOption: true,
      optionLabelKey: "equcat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_media_type",
    getValueCallback: (rowData) => rowData.equipment_media_type,
    label: i18n.t("table.counter_repository.equipment_media_type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_media_type,
      isObjectOption: true,
      optionLabelKey: "equmedtyp_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_status",
    getValueCallback: (rowData) => rowData.equipment_status,
    label: i18n.t("table.counter_repository.equipment_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_status,
      isObjectOption: true,
      optionLabelKey: "equstat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "eios_start_settlement",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.eios_start_settlement
      ),
    label: i18n.t("table.counter_repository.eios_start_settlement"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "eios_end_settlement",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.eios_end_settlement
      ),
    label: i18n.t("table.counter_repository.eios_end_settlement"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
];

export const EQUIPMENT_INSTALATION_TENANT_SET_TABLE_CONFIG = [
  {
    name: "passport",
    getValueCallback: (rowData) => (
      <FeedOutlinedIcon
        onClick={(e) =>
          WindowService.openInNewTab(
            `/counter_passport/${rowData.equipment_id}`
          )
        }
      />
    ),
    label: i18n.t("passport"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
  {
    name: "equipment",
    getValueCallback: (rowData) => rowData.equipment_nr,
    label: i18n.t("table.counter_repository.equipment"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.equipment,
      isObjectOption: true,
      optionLabelKey: "equipment_nr",
      addNewValue: false,
      multiple: true,
    }),
    other_export_field_name: "equipment_nr",
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.counter_repository.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ppe",
    getValueCallback: (rowData) => rowData.ppe,
    label: i18n.t("table.ppe_table.ppe_number"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.ppe,
      isObjectOption: true,
      optionLabelKey: "ppe_number",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "electricity_tariff_group",
    getValueCallback: (rowData) => rowData.electricity_tariff_group,
    label: i18n.t("table.electricity_tariff_table.electricity_tariff_group"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.electricity_tariff_group,
      isObjectOption: true,
      optionLabelKey: "etg_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "eits_power",
    getValueCallback: (rowData) => rowData.eits_power,
    label: i18n.t("form.meter_passport.eits_power"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipment_category",
    getValueCallback: (rowData) => rowData.equipment_category,
    label: i18n.t("table.counter_repository.equipment_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_category,
      isObjectOption: true,
      optionLabelKey: "equcat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_media_type",
    getValueCallback: (rowData) => rowData.equipment_media_type,
    label: i18n.t("table.counter_repository.equipment_media_type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_media_type,
      isObjectOption: true,
      optionLabelKey: "equmedtyp_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_status",
    getValueCallback: (rowData) => rowData.equipment_status,
    label: i18n.t("table.counter_repository.equipment_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_status,
      isObjectOption: true,
      optionLabelKey: "equstat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "eits_start_settlement",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.eits_start_settlement
      ),
    label: i18n.t("table.counter_repository.eits_start_settlement"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "eits_end_settlement",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(
        rowData.eits_end_settlement
      ),
    label: i18n.t("table.counter_repository.eits_end_settlement"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
];

export const useMeterSearchConfig = () => {
  const getTablePropsByTableName = (selectedTable) => {
    let tableName, tablePrefix, tableConfig;

    if (selectedTable === EQUIPMENT_STATE_TABLE_NAME) {
      tableName = EQUIPMENT_STATE_TABLE_NAME;
      tablePrefix = EQUIPMENT_STATE_TABLE_PREFIX;
      tableConfig = EQUIPMENT_STATE_TABLE_CONFIG;
    } else if (selectedTable === EQUIPMENT_INSTALATION_TABLE_NAME) {
      tableName = EQUIPMENT_INSTALATION_TABLE_NAME;
      tablePrefix = EQUIPMENT_INSTALATION_TABLE_PREFIX;
      tableConfig = EQUIPMENT_INSTALATION_TABLE_CONFIG;
    } else if (selectedTable === EQUIPMENT_INSTALATION_TENANT_SET_TABLE_NAME) {
      tableName = EQUIPMENT_INSTALATION_TENANT_SET_TABLE_NAME;
      tablePrefix = EQUIPMENT_INSTALATION_TENANT_SET_TABLE_PREFIX;
      tableConfig = EQUIPMENT_INSTALATION_TENANT_SET_TABLE_CONFIG;
    } else if (selectedTable === EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_NAME) {
      tableName = EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_NAME;
      tablePrefix = EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_PREFIX;
      tableConfig = EQUIPMENT_INSTALATION_OBJECT_SET_TABLE_CONFIG;
    }

    return {
      tableName: tableName,
      tableConfig: tableConfig,
      tableFilterPrefix: tablePrefix,
      configForUserPreferenceDialog: [
        {
          name: tableName,
          config: tableConfig,
        },
      ],
    };
  };

  return {
    fetchingFromBackend: false,
    getTableProps: getTablePropsByTableName,
  };
};
