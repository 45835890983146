import React from "react";
import PropTypes from "prop-types";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import BoxWithLabel from "../../box/BoxWithLabel";

import { useTranslation } from "react-i18next";

import { BLACK_TEXT_FIELD_INPUT_STYLE } from "../../../helpers/styles";

import { Tooltip, IconButton, Grid } from "@mui/material";

import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

import EquipmentInstalationTenantSetHistoryDialog from "../../dialog/EquipmentInstalationTenantSetHistoryDialog/EquipmentInstalationTenantSetHistoryDialog";
import useDialog from "../../../hooks/useDialog";

const MeterTenantForm = (props) => {
  const { t } = useTranslation();
  const [
    openEquipmentInstalationTenantSetHistoryDialog,
    onOpenEquipmentInstalationTenantSetHistoryDialog,
    onCloseEquipmentInstalationTenantSetHistoryDialog,
  ] = useDialog();

  return (
    <BoxWithLabel
      label={t("form.meter_passport.tenant")}
      boxStyle={{ height: "100%" }}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container direction="row" justifyContent="flex-end" spacing={1}>
        {props.tenant.map((equipmentTenant) => (
          <>
            <Grid item xs={10}>
              <TextFieldFormControl
                name="tenant"
                label={t("other.equipment_instalation_tenant_record.tenant")}
                value={equipmentTenant.tenant}
                readOnly={true}
                inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
                style={BLACK_TEXT_FIELD_INPUT_STYLE}
              />
            </Grid>
            <Grid item xs={2}>
              <TextFieldFormControl
                name="eit_factor"
                label={t(
                  "other.equipment_instalation_tenant_record.eit_factor"
                )}
                value={equipmentTenant.eit_factor}
                readOnly={true}
                inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
                style={BLACK_TEXT_FIELD_INPUT_STYLE}
              />
            </Grid>
          </>
        ))}
        <Grid item xs={4}>
          <TextFieldFormControl
            name={"ppe"}
            label={t("form.meter_passport.ppe")}
            value={props.ppe}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormControl
            name={"electricity_tariff_group"}
            label={t("form.meter_passport.electricity_tariff_group")}
            value={props.tariffGroup}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormControl
            name={"eits_power"}
            label={t("form.meter_passport.eits_power")}
            value={props.power}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        marginTop={1}
        container
        justifyContent={"flex-end"}
        alignItems={"flex-start"}
        columnSpacing={1}
      >
        <Grid item xs={4.8}>
          <TextFieldFormControl
            name={"eits_start_settlement"}
            label={t("form.meter_passport.eits_start_settlement")}
            value={props.startSettlement}
            readOnly={true}
            inputStyle={BLACK_TEXT_FIELD_INPUT_STYLE}
            style={BLACK_TEXT_FIELD_INPUT_STYLE}
          />
        </Grid>
        {props.showEquipmentInstalationTenantSetHistoryButton && (
          <Grid item xs={1}>
            <Tooltip
              title={t(
                "dialog.equipment.equipment_instalation_tenant_set_history"
              )}
            >
              <IconButton
                onClick={onOpenEquipmentInstalationTenantSetHistoryDialog}
              >
                <ManageHistoryIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>

      {openEquipmentInstalationTenantSetHistoryDialog && (
        <EquipmentInstalationTenantSetHistoryDialog
          open={openEquipmentInstalationTenantSetHistoryDialog}
          onClose={onCloseEquipmentInstalationTenantSetHistoryDialog}
          equipmentId={props.equipmentId}
          onSubmit={props.onSubmit}
        />
      )}
    </BoxWithLabel>
  );
};

MeterTenantForm.propTypes = {
  showEquipmentInstalationTenantSetHistoryButton: PropTypes.bool,
};

MeterTenantForm.defaultProps = {
  showEquipmentInstalationTenantSetHistoryButton: true,
};

export default MeterTenantForm;
