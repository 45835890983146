import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

export const ELECTRICITY_TARIFF_TABLE_NAME = "electricity_tariff_table";
export const ELECTRICITY_TARIFF_FILTER_PREFIX = "t";
export const ELECTRICITY_TABLE_FIELDS_CONFIG = [
  {
    name: "electricity_tariff_group",
    getValueCallback: (rowData) => rowData.electricity_tariff_group?.etg_name,
    label: i18n.t("table.electricity_tariff_table.electricity_tariff_group"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.electricity_tariff_group,
      isObjectOption: true,
      optionLabelKey: "etg_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_start",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatDDMMYYYY(rowData.et_start),
    label: i18n.t("table.electricity_tariff_table.et_start"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    headerSx: { whiteSpace: "break-spaces" },
    sortable: true,
  },
  {
    name: "et_end",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatDDMMYYYY(rowData.et_end),
    label: i18n.t("table.electricity_tariff_table.et_end"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    headerSx: { whiteSpace: "break-spaces" },
    sortable: true,
  },
  {
    name: "user",
    getValueCallback: (rowData) => rowData.user?.full_name,
    label: i18n.t("table.electricity_tariff_table.user"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.user,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_subscription_rate_fixed",
    getValueCallback: (rowData) => rowData.et_subscription_rate_fixed,
    label: i18n.t("table.electricity_tariff_table.et_subscription_rate_fixed"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_network_rate_fixed",
    getValueCallback: (rowData) => rowData.et_network_rate_fixed,
    label: i18n.t("table.electricity_tariff_table.et_network_rate_fixed"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_quality_rate_changeable",
    getValueCallback: (rowData) => rowData.et_quality_rate_changeable,
    label: i18n.t("table.electricity_tariff_table.et_quality_rate_changeable"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_transition_fee_rate_fixed",
    getValueCallback: (rowData) => rowData.et_transition_fee_rate_fixed,
    label: i18n.t(
      "table.electricity_tariff_table.et_transition_fee_rate_fixed"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_oze_rate_changeable",
    getValueCallback: (rowData) => rowData.et_oze_rate_changeable,
    label: i18n.t("table.electricity_tariff_table.et_oze_rate_changeable"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_cogeneration_rate_changeable",
    getValueCallback: (rowData) => rowData.et_cogeneration_rate_changeable,
    label: i18n.t(
      "table.electricity_tariff_table.et_cogeneration_rate_changeable"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_cogeneration_rate_changeable",
    getValueCallback: (rowData) => rowData.et_cogeneration_rate_changeable,
    label: i18n.t(
      "table.electricity_tariff_table.et_cogeneration_rate_changeable"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_network_rate_changeable",
    getValueCallback: (rowData) => rowData.et_network_rate_changeable,
    label: i18n.t("table.electricity_tariff_table.et_network_rate_changeable"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_power_rate_changeable",
    getValueCallback: (rowData) => rowData.et_power_rate_changeable,
    label: i18n.t("table.electricity_tariff_table.et_power_rate_changeable"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_power_rate_lump_below_500",
    getValueCallback: (rowData) => rowData.et_power_rate_lump_below_500,
    label: i18n.t(
      "table.electricity_tariff_table.et_power_rate_lump_below_500"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_power_rate_lump_between_500_1200",
    getValueCallback: (rowData) => rowData.et_power_rate_lump_between_500_1200,
    label: i18n.t(
      "table.electricity_tariff_table.et_power_rate_lump_between_500_1200"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_power_rate_lump_between_1200_2800",
    getValueCallback: (rowData) => rowData.et_power_rate_lump_between_1200_2800,
    label: i18n.t(
      "table.electricity_tariff_table.et_power_rate_lump_between_1200_2800"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "et_power_rate_lump_above_2800",
    getValueCallback: (rowData) => rowData.et_power_rate_lump_above_2800,
    label: i18n.t(
      "table.electricity_tariff_table.et_power_rate_lump_above_2800"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];
