export const PREVIEW_MODE_FIELDS = []

export const EDIT_MODE_FIELDS = [
"ico_product",
"ico_agreement",
"ico_agreement_parent",
"ico_sign_type",
"ico_sign_form",
"ico_name",
"ico_note_finance",
"ico_note_legal",
"ico_location",
"participiants_informed_doer",
"docs",
"draft",
"ico_pre_bo_is_done"
]

export const CHANGE_COND_WO_APPENDIX = [
    "ico_note_finance",
    "schedules",
    "condition_settlement",
    "settlement_amount"
]

export const CHANGE_AGREEMENT_SIDES = [
    "lender",
    "borrower",
    "ico_note_finance"
]