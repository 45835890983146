import { useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import AgreementTable from "../../components/table/AgreementTable";
import { NAVIGATION_DRAWER_TYPE_ICO } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import useIcoService from "../../services/icoService";
import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";
import { ICO_ACCEPT_TABLE_FIELDS_CONFIG, ICO_ACCEPT_TABLE_NAME } from "./PageTablesConfig";
import useDialogWithId from "../../hooks/useDialogWithId";
import IcoAcceptDialog from "../../components/dialog/IcoAcceptDialog";
import { getFieldValueFromSearchParams } from "../../helpers/methods";
import IcoConfiguratorDialog from "../../components/dialog/IcoConfiguratorDialog";

const TABLE_CONFIGS = [
  { name: ICO_ACCEPT_TABLE_NAME, config: ICO_ACCEPT_TABLE_FIELDS_CONFIG },
];

export default function IcoAcceptPage(props) {
  const { pageName } = props;

  const [icosDataLocal, setIcosDataLocal] = useState([]);
  const [countIcoRecord, setCountIcoRecord] = useState();
  const [selectedIcoId, setSelectedIcoId] = useState();
  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState(undefined);
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState(undefined);

  const OPEN_DALOG_ID = 'open_dialog_with_id'

  const {
    resetPageNumber,
    searchParams,
    setSearchParams
  } = usePaginationWithSearchParams();

  const [
    openAcceptDialog,
    chosenIcoId,
    onOpenAcceptDialog,
    onCloseAcceptDialog,
  ] = useDialogWithId();

  const { getIcoAcceptList, getIcoFilteringData } = useIcoService();

  const icosData = useAsync(
    () => getIcoAcceptList(searchParams),
    [searchParams]
  );

  const icoFilteringData = useAsync(getIcoFilteringData);

  useEffect(() => {
    if (icosData.loading) {
      return;
    }
    setIcosDataLocal(icosData.value.results);
    setCountIcoRecord(icosData.value.count);
  }, [icosData.loading]);


  const handleClickIcoRow = useCallback((id) => {
    onOpenAcceptDialog(id)
    setSelectedIcoId(id)
  }, [])


  const isAgreementTableLoading =
    icoFilteringData.loading ||
    icosDataLocal === undefined



  useEffect(() => {
    const id = getFieldValueFromSearchParams(searchParams, OPEN_DALOG_ID)
    if (id && id !== selectedIcoId) {
      handleClickIcoRow(id)
    }
  }, [searchParams, openAcceptDialog, OPEN_DALOG_ID]);

  const handleCloseAcceptDialog = useCallback(() => {
    if (Array.from(searchParams.keys()).includes(OPEN_DALOG_ID)) {
      searchParams.delete(OPEN_DALOG_ID);
      setSearchParams(searchParams);
    }
    onCloseAcceptDialog()
  }, [OPEN_DALOG_ID])


  return (
    <NavigationDrawer
      drawerType={NAVIGATION_DRAWER_TYPE_ICO}
      pageName={pageName}
    >
      <DefaultPageWrapper titleKey={pageName}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12} marginY={1}>
            <UniversalToolBarWithDialogs
              pageName={pageName}
              tableConfig={TABLE_CONFIGS}
              filteringData={icoFilteringData ? icoFilteringData : []}
              refreshTable={icosData.refetch}
              resetPageNumber={resetPageNumber}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showMassActionButton={false}
              showCreateButton={false}
              showOpenCloseFilterButton={false}
              showCleanfiltersButton={true}
              showMyFiltersButton={true}
              showSearchButton={true}
              extraButtonList={[]}
              extraButtonListMdGrid={[]}
              itemType={"ico"}
              style={{ marginBlock: "5px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isAgreementTableLoading}>
              <AgreementTable
                data={icosDataLocal}
                onClickRow={handleClickIcoRow}
                countRecords={countIcoRecord}
                resetPageNumber={resetPageNumber}
                filteringData={icoFilteringData ? icoFilteringData : []}
                hiddenColumns={hiddenColumnsForTables ? hiddenColumnsForTables[TABLE_CONFIGS.name] : []}
                columnsOrders={columnsOrdersForTables ? columnsOrdersForTables[TABLE_CONFIGS.name] : []}
                selectedAgreementId={selectedIcoId}
                style={{ maxHeight: "70vh" }}
                tableConfig={TABLE_CONFIGS[0].config}
                showCleanFilterIcon={true}
                showContextMenu={false}
                showExportToFileButton={false}
                showDetailsIcon={false}
                showCheckbox={false}

              />
            </LoaderWrapper>
          </Grid>

        </Grid>
        {openAcceptDialog &&
          <IcoAcceptDialog
            open={openAcceptDialog}
            onClose={handleCloseAcceptDialog}
            onRefetchData={icosData.refetch}
            icoFilteringData={icoFilteringData}
            icoId={chosenIcoId}
          />
        }

      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
