import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import DateField from "../../field/DateField/DateField";
import DateService from "../../../services/dateService";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField/FloatField";

const CreateTransferForm = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >

      {/* <Grid item xs={2}>
        <DateField
          name="today_date"
          label={t("form.create_transfer_form.transfer_date")}
          value={DateService.convertDateToFormatYYYYMMDD(
            new Date()
          )}
          readOnly
          showClearIcon={false}
        />
      </Grid> */}
      <Grid item xs={2}>
        <DateField
          value={props.transferData?.transfer_date_paid}
          name="transfer_date_paid"
          label={t("form.create_transfer_form.transfer_date_paid")}
          onChange={props.onChangeDate}
          minDate={props.minTransferDate}
          maxDate={props.maxRealTransferDate}
          required
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextFieldFormControl
          value={props.transferData?.transfer_payer_name}
          label={t("form.create_transfer_form.transfer_payer")}
          readOnly
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextFieldFormControl
          value={props.transferData?.transfer_receiver_name}
          label={t("form.create_transfer_form.transfer_receiver")}
          readOnly
        />
      </Grid>
      <Grid item xs={2.5}>
          <AutocompleteField
            name={"transfer_payer_iban"}
            label={t("form.create_transfer_form.payer_iban")}
            value={props.transferData?.transfer_payer_iban}
            options={props.payerIbans}
            isObjectOption={true}
            optionLabelKey={"iban_nr"}
            onChange={props.onChangeAutocomplete}
            addNewValue={false}
            multiple={false}
            required

          /> 
      </Grid>
      <Grid item xs={2.5}>
          <AutocompleteField
            name={"transfer_receiver_iban"}
            label={t("form.create_transfer_form.receiver_iban")}
            value={props.transferData?.transfer_receiver_iban}
            options={props.receiverIbans}
            isObjectOption={true}
            optionLabelKey={"iban_nr"}
            onChange={props.onChangeAutocomplete}
            addNewValue={false}
            multiple={false}
            required

          /> 
      </Grid>
      <Grid item xs={2}>
        <TextFieldFormControl
          value={props.transferData?.currency_name}
          label={t("form.create_transfer_form.currency")}
          readOnly
        />
      </Grid>
      <Grid item xs={2}>
        <FloatField
          value={props.transferData?.transfer_amount}
          label={t("form.create_transfer_form.transfer_amount")}
          readOnly
          showNumberSeparator
          required

        />
      </Grid>
      <Grid item xs={8}>
        <TextFieldFormControl
          name = {"transfer_title"}
          value={props.transferData?.transfer_title}
          label={t("form.create_transfer_form.transfer_title")}
          onChange={props.onChange}
          multiline
          rows = {1}
          resize = "vertical"
          required
          error={!props.isInvalid}
          isInvalid={props.isInvalid}
          helperText={
            props.errorText ||
            `${t("form.message_input_form.max_amount_chars")} ${140}`
          }
          helperTextStyle={props.helperTextStyle}
        />
      </Grid>



 

    </Grid>
  );
};

CreateTransferForm.propTypes = {
  transferData: PropTypes.object,
  isTransferTitleValid : PropTypes.bool,
  payerIbans: PropTypes.array,
  receiverIbans: PropTypes.array,
  onChange: PropTypes.func,
  onChangeAutocomplete: PropTypes.func
};

CreateTransferForm.defaultProps = {
  receiverIbans: [],
  payerIbans: [],
  isTransferTitleValid : true
};

export default CreateTransferForm;
