import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../helpers/methods";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

export const PPE_TABLE_NAME = "ppe_table";
export const PPE_FILTER_PREFIX = "ppe";
export const PPE_TABLE_FIELDS_CONFIG = [
  {
    name: "ppe_number",
    getValueCallback: (rowData) => rowData.ppe_number,
    label: i18n.t("table.ppe_table.ppe_number"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "ppe_status",
    getValueCallback: (rowData) =>
      i18n.t(`backend_choices_list.${rowData.ppe_status}`),
    label: i18n.t("table.ppe_table.ppe_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: convertFlatListFromBackendToTranslatedOptionObject(
        filteringData.value?.ppe_status
      ),
      isObjectOption: true,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.ppe_table.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment",
    getValueCallback: (rowData) => rowData.equipment,
    label: i18n.t("table.ppe_table.equipment_nr"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment,
      isObjectOption: true,
      optionLabelKey: "equipment_nr",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];
