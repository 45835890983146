import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAsyncFn } from "../../../hooks/useAsync";

import useEquipmentService from "../../../services/equipmentService";

import DateField from "../../field/DateField/DateField";
import BasicDialog from "../../base/BasicDialog";

import { useForm } from "../../../hooks/useForm";
import { getErrorMsg } from "../../../helpers/methods";

import { useSnackbarAlert } from "../../../context/snackbarAlert";
import DateService from "../../../services/dateService";

const ElectricityDistributionRelalculateDialog = (props) => {
  const { t } = useTranslation();

  const { formValue, onChangeDate } = useForm({});

  const snackbarAlert = useSnackbarAlert();

  const { recalculateElectricityDistribution } = useEquipmentService();

  const recalculateElectricityDistributionFn = useAsyncFn(
    recalculateElectricityDistribution
  );

  const prepareDataToSend = () => {
    return {
      start_year: DateService.getYearFromDateStr(formValue.date_start),
      start_month: DateService.getMonthFromDateStr(formValue.date_start),
      end_year: DateService.getYearFromDateStr(formValue.date_end),
      end_month: DateService.getMonthFromDateStr(formValue.date_end),
    };
  };

  const onSubmit = (dataToSend) => {
    recalculateElectricityDistributionFn
      .execute(
        props.equipmentId,
        dataToSend.start_year,
        dataToSend.start_month,
        dataToSend.end_year,
        dataToSend.end_month
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.electricity_distribution_updated")
        );
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const getDialogContent = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <DateField
            name={"date_start"}
            label={t("from")}
            value={formValue.date_start}
            onChange={onChangeDate}
            showClearIcon={false}
            required
            minDate={DateService.MIN_BMS_READING_DATE}
            maxDate={formValue.date_end || DateService.getPreviousMonth()}
            view={"month"}
          />
        </Grid>
        <Grid item xs={12}>
          <DateField
            name={"date_end"}
            label={t("to")}
            value={formValue.date_end}
            onChange={onChangeDate}
            showClearIcon={false}
            required
            minDate={formValue.date_start || DateService.MIN_BMS_READING_DATE}
            maxDate={DateService.getPreviousMonth()}
            view={"month"}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            variant="contained"
            color="primary"
            loading={recalculateElectricityDistributionFn.loading}
            onClick={() => onSubmit(prepareDataToSend())}
            disabled={!(formValue.date_start && formValue.date_end)}
          >
            {t("recalculate")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      title={t("dialog.equipment.electricity_distribution_recalculation")}
      maxWidth={"xs"}
      showTopFullScreenButton={false}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

ElectricityDistributionRelalculateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  equipmentId: PropTypes.string,
};

ElectricityDistributionRelalculateDialog.defaultProps = {};

export default ElectricityDistributionRelalculateDialog;
