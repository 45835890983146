import i18n from "../../../i18n";

import {
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../../helpers/constants";

import SelectFieldService from "../../../services/selectFieldService";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

export const ELECTRICITY_TARIFF_GROUP_TABLE_NAME = "electricity_tariff_group";
export const ELECTRICITY_TARIFF_GROUP_TABLE_PREFIX = "etg";

export const ELECTRICITY_TARIFF_GROUP_TABLE_CONFIG = [
  {
    name: "etg_name",
    getValueCallback: (rowData) => rowData.etg_name,
    label: i18n.t("table.electricity_tariff_table.etg_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "etg_name",
    getValueCallback: (rowData) => rowData.etg_note,
    label: i18n.t("table.electricity_tariff_table.etg_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "etg_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.etg_active),
    label: i18n.t("table.electricity_tariff_table.etg_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },

  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];
