import { useMemo, useState, useEffect, useRef, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import { useAsync } from "../../hooks/useAsync";
import useTariffService from "../../services/tariffsService";

import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

import useDialog from "../../hooks/useDialog";
import useDialogWithId from "../../hooks/useDialogWithId";

import { isSearchParamsForFilteringEmpty } from "../../helpers/methods";
import { NAVIGATION_DRAWER_TYPE_METERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import TariffTable from "../../components/table/TariffTable";

import TableService from "../../services/tableService";
import { exportElectricityTariffUrl } from "../../helpers/apiUrls";

import ElectricityTariffManagementDialog from "../../components/dialog/ElectricityTariffGroupManagementDialog";
import ElectricityTarrifDialog from "../../components/dialog/ElectricityTariffDialog/ElectricityTariffDialog";

import { useSnackbarAlert } from "../../context/snackbarAlert";
import DialpadIcon from "@mui/icons-material/Dialpad";
import { centerVericalAlignIconStyle } from "../../helpers/styles";
import { overflowButtonStyle } from "../../helpers/styles";

import {
  ELECTRICITY_TARIFF_TABLE_NAME,
  ELECTRICITY_TARIFF_FILTER_PREFIX,
  ELECTRICITY_TABLE_FIELDS_CONFIG,
} from "./TableConfig";

import { DIALOG_CREATE_MODE, DIALOG_EDIT_MODE } from "../../helpers/constants";

const CONFIG_FOR_USER_PREFERENCEDIALOG = {
  name: ELECTRICITY_TARIFF_TABLE_NAME,
  config: ELECTRICITY_TABLE_FIELDS_CONFIG,
};

export default function ElectricityTariffManagementPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [dataLocaly, setDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefixes,
  } = useFilterSearchParams(searchParams);

  const [filterSearchParams, setFilterSearchParams] = useState(
    getSearchParamsByFilterPrefix(ELECTRICITY_TARIFF_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      ELECTRICITY_TARIFF_FILTER_PREFIX,
      filterSearchParams,
      setFilterSearchParams
    );
  }, [searchParams]);

  const { getElectricityTariffData, getElectricityTariffFilteringData } =
    useTariffService();

  const filteringData = useAsync(getElectricityTariffFilteringData);

  const backendData = useAsync(
    () => getElectricityTariffData(filterSearchParams),
    [filterSearchParams]
  );

  useEffect(() => {
    if (backendData.loading) {
      return;
    }
    setDataLocaly(backendData.value.results);
    setCountRecords(backendData.value.count);
  }, [backendData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setDataLocaly(undefined);
    backendData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const [openTariffDialog, tariffId, onOpenTariffDialog, onCloseTariffDialog] =
    useDialogWithId();

  const [
    openTariffGroupDialog,
    onOpenTariffGroupDialog,
    onCloseTariffGroupDialog,
  ] = useDialog();

  const tariffDialogModeRef = useRef();
  const handleOpenTariffDialogInCreateMode = () => {
    tariffDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenTariffDialog(undefined);
  };

  const handleOpenTarrifDialogInEditMode = (id) => {
    tariffDialogModeRef.current = DIALOG_EDIT_MODE;
    onOpenTariffDialog(id);
  };

  const getTariffDialogSubmitCallback = () => {
    if (!isSearchParamsForFilteringEmpty(searchParams)) {
      setSearchParams({});
    } else {
      backendData.refetch();
    }

    onCloseTariffDialog();
  };

  const exportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      ELECTRICITY_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[ELECTRICITY_TARIFF_TABLE_NAME],
      columnsOrdersForTables?.[ELECTRICITY_TARIFF_TABLE_NAME]
    );
  }, [
    ELECTRICITY_TABLE_FIELDS_CONFIG,
    hiddenColumnsForTables?.[ELECTRICITY_TARIFF_TABLE_NAME],
    columnsOrdersForTables?.[ELECTRICITY_TARIFF_TABLE_NAME],
  ]);

  const getExtraButtonListForToolBar = () => {
    let extraButtonList = [
      <Button
        color="success"
        onClick={onOpenTariffGroupDialog}
        variant="contained"
        size="small"
        startIcon={<DialpadIcon sx={centerVericalAlignIconStyle} />}
        sx={overflowButtonStyle}
        fullWidth
      >
        {t("page.electricity_tariff_management.tariff_group_management")}
      </Button>,
    ];

    return extraButtonList;
  };

  const isLoading = dataLocaly === undefined || filteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_METERS}
    >
      <DefaultPageWrapper titleKey={"electricity_tariff_management"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <UniversalToolBarWithDialogs
              pageName={props.pageName}
              tableConfig={CONFIG_FOR_USER_PREFERENCEDIALOG}
              filteringData={filteringData}
              refreshTable={refreshTable}
              onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
              resetPageNumber={resetPageNumber}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showOpenCloseFilterButton={false}
              showCreateButton={true}
              extraButtonList={getExtraButtonListForToolBar()}
              onClickCreateItem={handleOpenTariffDialogInCreateMode}
              createButtonLabel={t(
                "page.electricity_tariff_management.add_tariff"
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isLoading}>
              {hiddenColumnsForTables && columnsOrdersForTables && (
                <TariffTable
                  showCheckbox={false}
                  data={dataLocaly}
                  showCleanFilterIcon={true}
                  countRecords={countRecords}
                  page={page}
                  onPageChange={handleChangePageWithSearchParams}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={filteringData}
                  filterPrefix={ELECTRICITY_TARIFF_FILTER_PREFIX}
                  style={{ maxHeight: "75vh" }}
                  tableConfig={ELECTRICITY_TABLE_FIELDS_CONFIG}
                  hiddenColumns={
                    hiddenColumnsForTables[ELECTRICITY_TARIFF_TABLE_NAME]
                  }
                  columnsOrders={
                    columnsOrdersForTables[ELECTRICITY_TARIFF_TABLE_NAME]
                  }
                  onClickEdit={handleOpenTarrifDialogInEditMode}
                  showExportToFileButton={true}
                  exportToFileUrl={exportElectricityTariffUrl}
                  exportToFileSearchParams={filterSearchParams}
                  exportToFileHeaders={exportHeaders}
                  exportToFileFileName={`${t(
                    "headers.electricity_tariff_management"
                  )}.xlsx`}
                />
              )}
            </LoaderWrapper>
          </Grid>
          {openTariffGroupDialog && (
            <ElectricityTariffManagementDialog
              open={openTariffGroupDialog}
              onClose={onCloseTariffGroupDialog}
            />
          )}
          {openTariffDialog && (
            <ElectricityTarrifDialog
              open={openTariffDialog}
              onClose={onCloseTariffDialog}
              dialogMode={tariffDialogModeRef.current}
              onSubmitCallback={getTariffDialogSubmitCallback}
              tariffId={tariffId}
            />
          )}
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
