import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";

import useEquipmentService from "../../../services/equipmentService";

import BasicDialog from "../../base/BasicDialog";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import FloatField from "../../base/FloatField";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";
import { getErrorMsg } from "../../../helpers/methods";
import { INTERNAL_TYPE } from "../../../helpers/constants";

import { useSnackbarAlert } from "../../../context/snackbarAlert";

import useFileService from "../../../services/fileService";
import useFileOnMemoryData from "../../../hooks/useFileOnMemoryData";
import FileUploadList from "../../other/FileUploadList";

import useEnclosuresData from "../../../hooks/useEnclosuresData";

import DateService from "../../../services/dateService";

const EquipmentBmsOrderDetailsDialog = (props) => {
  const { t } = useTranslation();

  const { formValue, setFormValue, onChange } = useForm({});

  const snackbarAlert = useSnackbarAlert();

  const { getEquipmentDataForReading, updateEquipmentBmsOrder } =
    useEquipmentService();

  const equipmentData = useAsync(
    () => getEquipmentDataForReading(props.equipmentOrderId),
    []
  );

  const {
    enclosures: enclosuresForEquipment,
    onPreviewEnclosure: onPreviewEnclosureForEquipment,
    onDownloadEnclosure: onDownloadEnclosureForEquipment,
  } = useEnclosuresData(
    equipmentData.value?.equipment_id,
    undefined,
    "equipment"
  );

  const [
    enclosures,
    onAddEnclosure,
    onUpdateEnclosure,
    onDeleteEnclosure,
    prepareEnclosuresToSend,
  ] = useFileOnMemoryData();

  const { downloadFileByBlobUrl } = useFileService();

  const onDownloadEnclosure = (enclosureId, enclosureName, index) => {
    const enclosure = enclosures[index];
    downloadFileByBlobUrl(enclosure.blob_url, enclosureName);
  };

  const getPreparedDataToSend = () => {
    let tempFormValue = { ...formValue };
    tempFormValue["files"] = prepareEnclosuresToSend(enclosures);
    return tempFormValue;
  };

  const updateEquipmentBmsOrderFn = useAsyncFn(updateEquipmentBmsOrder);
  const onSubmit = (dataToSend) => {
    updateEquipmentBmsOrderFn
      .execute(props.equipmentOrderId, dataToSend)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.reading_added")
        );
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const handleClickAcceptReading = () => onSubmit(getPreparedDataToSend());
  const handleClickRejectReading = () => onSubmit(getPreparedDataToSend());

  const getDialogContent = () => {
    if (equipmentData.loading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <TextFieldFormControl
            label={t("form.meter_passport.equipment_nr")}
            value={equipmentData.value.equipment_nr}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormControl
            label={t("form.meter_passport.last_read_value")}
            value={equipmentData.value.last_read?.equipbms_value}
            readOnly={true}
            suffix={equipmentData.value.last_read?.equipbms_unit}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormControl
            label={t("form.meter_passport.last_read_date")}
            value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
              equipmentData.value.last_read?.equipbms_date
            )}
            readOnly={true}
          />
        </Grid>
        <FileUploadList
          addEnclosureButtonProps={{ size: "mini" }}
          defaultEnclosureType={INTERNAL_TYPE}
          enclosures={enclosuresForEquipment}
          onPreviewEnclosure={onPreviewEnclosureForEquipment}
          onDownloadEnclosure={onDownloadEnclosureForEquipment}
          onUpdateEnclosure={() => {}}
          onDeleteEnclosure={() => {}}
          onAddEnclosure={() => {}}
          canRemoveEnclosures={false}
          readOnly={true}
          showExtendedAddEnclosureDialog={false}
          showSubcontractorSelect={false}
          showEnclosureType={false}
          allowCopyEnclosure={false}
          enclosureWindowStyle={{ maxHeight: "200px" }}
          fileUploadListTitle={t(
            "other.file_upload_list.enclosures_for_equipment"
          )}
        />

        <Grid item xs={12}>
          <TextFieldFormControl
            label={t("form.meter_passport.equipinstal_desc")}
            value={equipmentData.value.equipinstal_desc}
            readOnly={true}
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={6}>
          <FloatField
            name={"equipbms_value"}
            label={t("form.meter_passport.new_read")}
            value={formValue.equipbms_value}
            onChange={onChange}
            suffix={equipmentData.value.equipbms_unit}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormControl
            name={"equipbms_note"}
            label={t("form.meter_passport.new_read_note")}
            value={formValue.equipbms_note}
            onChange={onChange}
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={12}>
          <FileUploadList
            addEnclosureButtonProps={{ size: "mini" }}
            defaultEnclosureType={INTERNAL_TYPE}
            enclosures={enclosures}
            onDownloadEnclosure={onDownloadEnclosure}
            onAddEnclosure={onAddEnclosure}
            onDeleteEnclosure={onDeleteEnclosure}
            onUpdateEnclosure={onUpdateEnclosure}
            showExtendedAddEnclosureDialog={true}
            showSubcontractorSelect={false}
            showEnclosureType={false}
            showEnclosurePartKind={false}
            allowCopyEnclosure={false}
            multiple={false}
          />
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            fullWidth
            variant="contained"
            color="success"
            loading={updateEquipmentBmsOrderFn.loading}
            onClick={handleClickAcceptReading}
            disabled={!formValue.equipbms_value || enclosures?.length === 0}
          >
            {t("form.meter_passport.accept_reading")}
          </LoadingButton>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            fullWidth
            variant="contained"
            color="error"
            loading={updateEquipmentBmsOrderFn.loading}
            onClick={handleClickRejectReading}
            disabled={
              !formValue.equipbms_note ||
              formValue.equipbms_value ||
              enclosures?.length === 0
            }
          >
            {t("form.meter_passport.reject_reading")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      title={t("dialog.equipment.equipment_bms_order")}
      maxWidth={"sm"}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

EquipmentBmsOrderDetailsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  equipmentOrderId: PropTypes.string,
};

EquipmentBmsOrderDetailsDialog.defaultProps = {};

export default EquipmentBmsOrderDetailsDialog;
