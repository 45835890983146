import i18n from "../../../i18n";

import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../../helpers/constants";

import DateService from "../../../services/dateService";

export const TICKET_EQUIPMENT_TABLE_NAME = "ticket_equipment";
export const TICKET_EQUIPMENT_TABLE_PREFIX = "ticket_equipment";

export const TICKET_EQUIPMENT_TABLE_CONFIG = [
  {
    name: "ticket_nr",
    getValueCallback: (rowData) => rowData.ticket_nr,
    label: i18n.t("table.ticket.ticket_nr"),
    filterType: NUMERIC_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "ticket_name",
    getValueCallback: (rowData) => rowData.ticket_name,
    label: i18n.t("table.ticket.ticket_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  // Change Serializer if show location
  // {
  //   name: "objects_level_1",
  //   getValueCallback: (rowData) => rowData.locations[0].level_1,
  //   label: i18n.t("table.ticket.object_level_1"),
  //   filterType: AUTOCOMPLETE_FIELD_TYPE,
  //   getFilterFieldConfigCallback: (filteringData) => ({
  //     options: filteringData.value.objects_level_1,
  //     isObjectOption: false,
  //     addNewValue: false,
  //     multiple: true,
  //   }),
  // },
  // {
  //   name: "objects_level_2",
  //   getValueCallback: (rowData) => rowData.locations[0].level_2,
  //   label: i18n.t("table.ticket.object_level_2"),
  //   filterType: AUTOCOMPLETE_FIELD_TYPE,
  //   getFilterFieldConfigCallback: (filteringData) => ({
  //     options: filteringData.value.objects_level_2,
  //     isObjectOption: false,
  //     addNewValue: false,
  //     multiple: true,
  //   }),
  // },
  // {
  //   name: "objects_level_3",
  //   getValueCallback: (rowData) => rowData.locations[0].level_3,
  //   label: i18n.t("table.ticket.object_level_3"),
  //   filterType: AUTOCOMPLETE_FIELD_TYPE,
  //   getFilterFieldConfigCallback: (filteringData) => ({
  //     options: filteringData.value.objects_level_3,
  //     isObjectOption: false,
  //     addNewValue: false,
  //     multiple: true,
  //   }),
  // },
  // {
  //   name: "objects_level_4",
  //   getValueCallback: (rowData) => rowData.locations[0].level_4,
  //   label: i18n.t("table.ticket.object_level_4"),
  //   filterType: AUTOCOMPLETE_FIELD_TYPE,
  //   getFilterFieldConfigCallback: (filteringData) => ({
  //     options: filteringData.value.objects_level_4,
  //     isObjectOption: false,
  //     addNewValue: false,
  //     multiple: true,
  //   }),
  // },
  // {
  //   name: "objects_level_5",
  //   getValueCallback: (rowData) => rowData.locations[0].level_5,
  //   label: i18n.t("table.ticket.object_level_5"),
  //   filterType: AUTOCOMPLETE_FIELD_TYPE,
  //   getFilterFieldConfigCallback: (filteringData) => ({
  //     options: filteringData.value.objects_level_5,
  //     isObjectOption: false,
  //     addNewValue: false,
  //     multiple: true,
  //   }),
  // },
  {
    name: "created",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.created),
    label: i18n.t("table.ticket.created"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "ticket_owner",
    getValueCallback: (rowData) => rowData.ticket_owner,
    label: i18n.t("table.ticket.ticket_owner"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_owner,
      isObjectOption: true,
      optionLabelKey: "full_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "ticket_desc_creator",
    getValueCallback: (rowData) => rowData.ticket_desc_creator,
    label: i18n.t("table.ticket.ticket_desc_creator"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: true,
  },
  {
    name: "ticket_status",
    getValueCallback: (rowData) => rowData.ticket_status,
    label: i18n.t("table.ticket.ticket_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_status,
      isObjectOption: true,
      optionLabelKey: "status_name",
      addNewValue: false,
      multiple: true,
    }),
  },
];
