const DateService = {
  ONE_DAY_IN_MILISECONDS: 24 * 60 * 60 * 1000,
  MINUTE_IN_SECONDS: 60,
  MIN_BMS_READING_DATE: "2025-01-01",

  getIsoDateFromDatetime: function (date) {
    return date.toISOString().split("T")[0];
  },

  convertDateToFormatYYYYMM: function (date) {
    if (!date) return date;

    if (typeof date === "string") return date.split("T")[0];
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    const splitedDate = this.getIsoDateFromDatetime(date).split("-");
    return `${splitedDate[0]}-${splitedDate[1]}`;
  },

  convertDateToFormatYYYYMMDD: function (date) {
    if (!date) return date;

    if (typeof date === "string") return date.split("T")[0];
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return this.getIsoDateFromDatetime(date);
  },

  convertDateToFormatYYYYMMDD_hhmmss: function (date) {
    if (!date) return date;
    if (typeof date === "string") return date.split(".")[0].replace("T", " ");
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split(".")[0].replace("T", " ");
  },

  convertDatetimeFromBackendToFormatYYYYMM: function (stringDatetime) {
    if (
      typeof stringDatetime === "string" ||
      stringDatetime instanceof String
    ) {
      return this.convertDateToFormatYYYYMM(new Date(stringDatetime));
    }
    return stringDatetime;
  },

  convertDatetimeFromBackendToFormatYYYYMMDD: function (stringDatetime) {
    if (
      typeof stringDatetime === "string" ||
      stringDatetime instanceof String
    ) {
      return this.convertDateToFormatYYYYMMDD(new Date(stringDatetime));
    }
    return stringDatetime;
  },

  convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss: function (stringDatetime) {
    if (
      typeof stringDatetime === "string" ||
      stringDatetime instanceof String
    ) {
      return this.convertDateToFormatYYYYMMDD_hhmmss(new Date(stringDatetime));
    }
    return stringDatetime;
  },

  isDateStartWithZero: function (d) {
    return d.toISOString().slice(0, 10).charAt(0) === "0";
  },

  isValidDate: function (d) {
    return d instanceof Date && !isNaN(d) && !this.isDateStartWithZero(d);
  },

  convertDatetimeListToUniqueDateList: function (dateTimeList) {
    return [
      ...new Set(
        dateTimeList.map((date) =>
          this.convertDatetimeFromBackendToFormatYYYYMMDD(date)
        )
      ),
    ];
  },

  getISOStringZeroHourOfDate: function (date) {
    if (!date) return "";
    var dateTemp = new Date(date);
    dateTemp.setHours(0, 0, 0, 0);
    return dateTemp.toISOString();
  },

  getISOStringleEndHourOfDate: function (date) {
    if (!date) return "";
    var dateTemp = new Date(date);
    dateTemp.setHours(23, 59, 59, 99);
    return dateTemp.toISOString();
  },

  getFilterDatetimeRangeForData: function (date) {
    return {
      start: DateService.getISOStringZeroHourOfDate(date),
      end: DateService.getISOStringleEndHourOfDate(date),
    };
  },

  getHoursAndMinutesStringFromData: function (date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes}`;
  },

  getHoursAndMinutesStringFromStringData: function (strDate) {
    if (!strDate || !this.isValidDate(new Date(strDate))) return strDate;

    return this.getHoursAndMinutesStringFromData(new Date(strDate));
  },

  generateDateRanges: function (startDateStr, endDateStr) {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    const dateRanges = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dateRanges.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRanges;
  },
  getHoursTimezoneOffset: function () {
    return new Date().getTimezoneOffset() / 60;
  },

  getMinutesTimezoneOffset: function () {
    return this.getHoursTimezoneOffset() * 60;
  },

  getMinutesIncludeTimeZoneOffset: function (minutes) {
    return minutes + this.getMinutesTimezoneOffset();
  },

  getHoursFromMinutes: function (minutes, asString = true) {
    let hours = Math.trunc(minutes / 60);
    if (asString) hours = hours.toString();
    return hours;
  },

  roundDateToHour: function (date) {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

    return date;
  },

  getStartAndEndTimeForPlanner: function (timeInvervalInHours = 10) {
    if (timeInvervalInHours < 0 || timeInvervalInHours > 24) {
      throw new RangeError(
        "Valid time inverval should be greater than 0 and less than 24"
      );
    }

    const currTime = this.roundDateToHour(new Date());

    const halfInterval = timeInvervalInHours / 2;
    let startIntervalToSubtract = halfInterval;

    let endTime = new Date(currTime);
    endTime.setHours(currTime.getHours() + halfInterval);
    if (
      this.getIsoDateFromDatetime(endTime) >
      this.getIsoDateFromDatetime(currTime)
    ) {
      startIntervalToSubtract =
        timeInvervalInHours - (24 - currTime.getHours());
      endTime.setHours(0);
    }

    let startTime = new Date(currTime);
    startTime.setHours(currTime.getHours() - startIntervalToSubtract);
    if (startTime.getHours() > currTime.getHours()) {
      startTime.setHours(0);
      endTime.setHours(timeInvervalInHours);
    }

    const startTimeHour = this.getHoursAndMinutesStringFromData(startTime);
    const endTimeHour =
      endTime.getHours() === 0
        ? "24:00"
        : this.getHoursAndMinutesStringFromData(endTime);

    return { startTimeHour, endTimeHour };
  },

  getFirstAndLastDayOfMonthAndYear: function (date) {
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return { lastDay, firstDay };
  },

  getFirstAndLastDayOfCurrentMonth: function () {
    return this.getFirstAndLastDayOfMonthAndYear(new Date());
  },

  getISOStringZeroAndEndHoursOfMonthAndYear: function (date) {
    const { firstDay, lastDay } = this.getFirstAndLastDayOfMonthAndYear(date);
    return {
      zeroHour: this.getISOStringZeroHourOfDate(firstDay),
      endHour: this.getISOStringleEndHourOfDate(lastDay),
    };
  },

  getMonthAndYearStringFromDayString: function (dateStr) {
    if (!dateStr) return null;
    return dateStr.substring(0, 7);
  },

  convertDateStrToDateIfNessesary: function (dateStr) {
    let date = dateStr;
    if (typeof dateStr === "string" || dateStr instanceof String) {
      date = new Date(dateStr);
    }

    return date;
  },

  getPreviousDayFromDate: function (dateStr) {
    const date = this.convertDateStrToDateIfNessesary(dateStr);
    return new Date(new Date(date).getTime() - this.ONE_DAY_IN_MILISECONDS);
  },

  getPreviousDayFromDateAsStringInFormatYYYYMMDD: function (dateStr) {
    const previousDay = this.getPreviousDayFromDate(dateStr);
    return this.convertDateToFormatYYYYMMDD(previousDay);
  },

  getNextDayFromDate: function (dateStr) {
    const date = this.convertDateStrToDateIfNessesary(dateStr);
    return new Date(new Date(date).getTime() + this.ONE_DAY_IN_MILISECONDS);
  },

  getNextDayFromDateAsStringInFormatYYYYMMDD: function (dateStr) {
    const nextDay = this.getNextDayFromDate(dateStr);
    return this.convertDateToFormatYYYYMMDD(nextDay);
  },

  convertDateToFormatDDMMYYY: function (dateObj) {
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = dateObj.getFullYear();

    return `${day}.${month}.${year}`;
  },

  convertDatetimeFromBackendToFormatDDMMYYYY: function (stringDatetime) {
    if (
      typeof stringDatetime === "string" ||
      stringDatetime instanceof String
    ) {
      return this.convertDateToFormatDDMMYYY(new Date(stringDatetime));
    }
    if (!stringDatetime) return null;
    return this.convertDateToFormatDDMMYYY(stringDatetime);
  },

  getPreviousMonth: function () {
    let date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() - 1);
    return date;
  },

  getYearFromDateStr(dateStr) {
    return this.convertDateStrToDateIfNessesary(dateStr).getFullYear();
  },

  getMonthFromDateStr(dateStr) {
    return this.convertDateStrToDateIfNessesary(dateStr).getMonth() + 1;
  },
};

export default DateService;
