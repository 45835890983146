import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import TextFieldFormControl from "../../field/TextFieldFormControl";

import useEquipmentService from "../../../services/equipmentService";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";

import {
  DIALOG_PREVIEW_MODE,
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const REQUIRED_FIELDS = ["ppe_number"];

const FIELDS_TO_SAVE = [...REQUIRED_FIELDS];

const PpeDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const dialogTitle = useMemo(() => {
    return props.dialogMode === DIALOG_CREATE_MODE
      ? t("dialog.ppe_dialog.add_new_ppe")
      : props.dialogMode === DIALOG_EDIT_MODE
      ? t("dialog.ppe_dialog.edit_ppe")
      : t("dialog.ppe_dialog.ppe_details");
  }, [props.dialogMode]);

  const isReadOnly = useMemo(
    () => props.readOnly || props.dialogMode === DIALOG_PREVIEW_MODE,
    [props.readOnly, props.dialogMode]
  );

  const fieldsToSave = useMemo(
    () => props.fieldsToSave || FIELDS_TO_SAVE,
    [props.fieldsToSave]
  );

  const { formValue, setFormValue, onChange } = useForm();

  const { getPpeDetailsData, createPpe, updatePpe } = useEquipmentService();

  const initialData = useAsync(() => {
    if (props.dialogMode === DIALOG_CREATE_MODE) {
      return Promise.resolve({});
    }

    return getPpeDetailsData(props.ppeId);
  }, [props.ppeId, props.dialogMode]);

  useEffect(() => {
    setFormValue(initialData.value);
  }, [initialData.loading]);

  const prepareDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (fieldsToSave.includes(key)) {
        dataToSend[key] = typeof value === "object" ? value.id : value;
      }
    }

    return dataToSend;
  };
  const updatePpeFn = useAsyncFn(updatePpe);
  const addPpeFn = useAsyncFn(createPpe);
  const submitFn = useMemo(
    () => (props.dialogMode === DIALOG_CREATE_MODE ? addPpeFn : updatePpeFn),
    [props.dialogMode]
  );

  const handleSubmit = (dataToSend) => {
    submitFn
      .execute(dataToSend, props.ppeId)
      .then((res) => {
        if (props.dialogMode === DIALOG_CREATE_MODE) {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.ppe_created")
          );
        } else {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.ppe_updated")
          );
        }
        if (props.onSubmitCallback) {
          props.onSubmitCallback();
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isFormValid =
    formValue &&
    REQUIRED_FIELDS.every((field) => !isEmptyValue(formValue[field]));

  const isLoading = formValue === undefined;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <TextFieldFormControl
            name={"ppe_number"}
            label={t("dialog.ppe_dialog.ppe_number")}
            value={formValue.ppe_number}
            onChange={onChange}
            readOnly={isReadOnly}
          />
        </Grid>
        {!isReadOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={addPpeFn.loading || updatePpeFn.loading}
              disabled={!isFormValid}
              onClick={() => handleSubmit(prepareDataToSend())}
            >
              {t("save")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={false}
      title={dialogTitle}
      maxWidth="xs"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

PpeDialog.propTypes = {
  dialogMode: PropTypes.oneOf([
    DIALOG_CREATE_MODE,
    DIALOG_EDIT_MODE,
    DIALOG_PREVIEW_MODE,
  ]),
  readOnly: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  fieldsToSave: PropTypes.array,
};

PpeDialog.defaultProps = {
  open: false,
};

export default PpeDialog;
