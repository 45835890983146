import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import CounterBasicInfoForm from "../CounterBasicInfoForm/CounterBasicInfoForm";
import CounterAttibutesForm from "../CounterAttributesForm";
import CounterDescriptionFieldsForm from "../CounterDescriptionFieldsForm";

import CounterTenantForm from "../CounterTenantForm/CounterTenantForm";
import CounterParametersForm from "../CounterParametersForm/CounterParametersForm";

import CounterInstalationLocationForm from "../CounterInstalationForm/CounterInstalationLocationForm";
import CounterMeasuringLocationForm from "../CounterMeasuringLocationForm";

const CounterDataForms = (props) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent={"stretch"}
      alignItems={"stretch"}
    >
      <Grid item xs={12}>
        <CounterBasicInfoForm
          formValue={props.formValue}
          onChange={props.onChange}
          onChangeAutocompleteFieldWithObjectOptions={
            props.onChangeAutocompleteFieldWithObjectOptions
          }
          onChangeDate={props.onChangeDate}
          readOnly={props.readOnly}
          filteringData={props.filteringData}
          readOnlyFields={props.readOnlyFields}
          unfillRequiredFields={props.unfillRequiredFields}
        />
      </Grid>
      <Grid item xs={12}>
        <CounterParametersForm
          parameters={props.parameters}
          setParameters={props.setParameters}
          readOnly={props.readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <CounterDescriptionFieldsForm
          formValue={props.formValue}
          onChange={props.onChange}
          onChangeAutocompleteFieldWithObjectOptions={
            props.onChangeAutocompleteFieldWithObjectOptions
          }
          onChangeDate={props.onChangeDate}
          readOnly={props.readOnly}
          filteringData={props.filteringData}
          readOnlyFields={props.readOnlyFields}
        />
      </Grid>
      <Grid item xs={3.5}>
        <CounterTenantForm
          formValue={props.tenants}
          setFormValue={props.setTenants}
          startSettlement={props.formValue.eits_start_settlement}
          power={props.formValue.eits_power}
          ppe={props.formValue.ppe}
          tariffGroup={props.formValue.electricity_tariff_group}
          onChange={props.onChange}
          onChangeDate={props.onChangeDate}
          onChangeInArrayForm={props.onChangeTenantInArrayForm}
          onChangeAutocompleteFieldWithObjectOptions={
            props.onChangeAutocompleteFieldWithObjectOptions
          }
          onChangeAutocompleteFieldWithObjectOptionsInArrayForm={
            props.onChangeAutocompleteTenantFieldWithObjectOptions
          }
          readOnly={props.readOnly}
          filteringData={props.filteringData}
          readOnlyFields={props.readOnlyFields}
          unfillRequiredFields={props.unfillRequiredFields}
          showTenantSetElectricityParams={props.showTenantSetElectricityParams}
          ref={props.tenantSetRef}
        />
      </Grid>
      <Grid item xs={8.5}>
        <CounterMeasuringLocationForm
          locations={props.measuringLocation}
          setLocations={props.setMeasuringLocation}
          locationFactors={props.measuringLocationFactors}
          onChangeLocationFactors={props.onChangeMeasuringLocationFactor}
          readOnly={props.readOnly}
          readOnlyFields={props.readOnlyFields}
          setMeasuringLocationFactors={props.setMeasuringLocationFactors}
          emptyFactorObject={props.emptyFactorObject}
          startSettlement={props.formValue.eios_start_settlement}
          onChangeDate={props.onChangeDate}
          unfillRequiredFields={props.unfillRequiredFields}
          factorsAreInvalid={props.factorsAreInvalid}
          factorsInvalidHelperText={props.factorsInvalidHelperText}
          measuringLocationRef={props.measuringLocationRef}
        />
      </Grid>
      <Grid item xs={12}>
        <CounterInstalationLocationForm
          formValue={props.instalationLocation}
          setFormValue={props.setInstalationLocation}
          onChangeDate={props.onChangeDate}
          readOnly={props.readOnly}
          readOnlyFields={props.readOnlyFields}
          instalationLocationRef={props.instalationLocationRef}
          instalationDate={props.formValue.equipinstal_date_instalation}
        />
      </Grid>

      <Grid item xs={12}>
        <CounterAttibutesForm
          formValue={props.formValue}
          onChange={props.onChange}
          onChangeAutocompleteFieldWithObjectOptions={
            props.onChangeAutocompleteFieldWithObjectOptions
          }
          readOnly={props.readOnly}
          filteringData={props.filteringData}
          readOnlyFields={props.readOnlyFields}
          unfillRequiredFields={props.unfillRequiredFields}
        />
      </Grid>
    </Grid>
  );
};

CounterDataForms.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeTenantInArrayForm: PropTypes.func,
  onChangeAutocompleteTenantFieldWithObjectOptions: PropTypes.func,
  tenants: PropTypes.func,
  setTenants: PropTypes.func,
  parameters: PropTypes.array,
  setParameters: PropTypes.func,
  instalationLocation: PropTypes.object,
  setInstalationLocation: PropTypes.func,
  measuringLocation: PropTypes.object,
  setMeasuringLocation: PropTypes.func,
  measuringLocationFactors: PropTypes.array,
  onChangeMeasuringLocationFactor: PropTypes.func,
  setMeasuringLocationFactors: PropTypes.func,
  readOnly: PropTypes.bool,
  filteringData: PropTypes.object,
  readOnlyFields: PropTypes.bool,
  empptyFactorObject: PropTypes.object,
  unfillRequiredFields: PropTypes.array,
  instalationLocationRef: PropTypes.any,
  measuringLocationRef: PropTypes.any,
  tenantSetRef: PropTypes.any,
  showTenantSetElectricityParams: PropTypes.bool,
};

CounterDataForms.defaultProps = {
  readOnly: false,
  readOnlyFields: [],
};

export default CounterDataForms;
