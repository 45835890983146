import { useState, useEffect, useCallback, useRef, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { TASK_TABLE_NAME, TASK_TABLE_FIELDS_CONFIG } from "./PageTablesConfig";

import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import UniversalToolBar from "../../components/bar/UniversalToolBar";

import useTaskService from "../../services/taskService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";

import useDialog from "../../hooks/useDialog";
import FilterDialog from "../../components/base/FilterDialog";
import UserFilterDialog from "../../components/dialog/UserFilterDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";

import UniversalFilterForm from "../../components/form/UniversalFilterForm";

import TableService from "../../services/tableService";

import DetailsDrawer from "../../components/drawer/UniversalDetailsDrawer";

import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";

import useBasicDrawer from "../../hooks/useBasicDrawer";
import CreateTaskDialog from "../../components/dialog/CreateTaskDialog";
import { getErrorMsg, isSearchParamsEmpty } from "../../helpers/methods";
import SelectOrderToAddTaskDialog from "../../components/dialog/SelectOrderToAddTaskDialog/SelectOrderToAddTaskDialog";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import DateService from "../../services/dateService";

import TaskTable from "../../components/table/TaskTable";
import {
  exportMyTaskToFileUrl,
  exportTaskListToFileUrl,
} from "../../helpers/apiUrls";

import DateFilterSelectField from "../../components/field/DateFilterSelectField";
import { MANAGE_TASKS_PERMISSION } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";

const DATE_FILTER_KEY = "date_start";
// const INITIAL_SEARCH_PARAMS = {
//   [DATE_FILTER_KEY]: DateService.getISOStringZeroHourOfDate(new Date()),
// };

const TABLE_CONFIGS = [
  { name: TASK_TABLE_NAME, config: TASK_TABLE_FIELDS_CONFIG },
];

export default function TaskPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [hasPermissionToManageTasks] = useCheckPermission(
    MANAGE_TASKS_PERMISSION
  );

  const [taskDataLocaly, setTaskDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const [isDialogWithParamsClosed, setIsDialogWithParamsClosed] =
    useState(true);

  const {
    page: taskDataPage,
    pageSize: taskDataPageSize,
    handleChangePageWithSearchParams: handleChangeTaskDataPage,
    handleChangePageSizeWithSearchParams: handleChangeTaskDataPageSize,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(undefined);

  const handleChangeRowsPerPage = (e) => {
    handleChangeTaskDataPageSize(e, parseInt(e.target.value, 10));
  };

  const {
    getTaskDataByPageName,
    updateTaskData,
    getTaskDetailsData,
    getTaskFilteringData,
  } = useTaskService();

  const taskData = useAsync(
    () =>
      getTaskDataByPageName(pageName, searchParams, isDialogWithParamsClosed),
    [pageName, searchParams, isDialogWithParamsClosed]
  );

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const fullRefreshTable = () => {
    setTaskDataLocaly(undefined);
    taskData.refetch();
  };

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [TASK_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [TASK_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        fullRefreshTable();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const taskFilteringData = useAsync(getTaskFilteringData);

  useEffect(() => {
    if (taskData.loading) {
      return;
    }
    setTaskDataLocaly(taskData.value.results);
    setCountRecords(taskData.value.count);
  }, [taskData.loading]);

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [
    openSelectOrderToAddTaskDialog,
    onOpenSelectOrderToAddTaskDialog,
    onCloseSelectOrderToAddTaskDialog,
  ] = useDialog();

  const [
    openCreateTaskDialog,
    onOpenCreateTaskDialog,
    onCloseCreateTaskDialog,
  ] = useDialog();

  const selectedOrderIdToAddTask = useRef();
  const handleSelectOrderToAddTaskDialog = (orderId) => {
    selectedOrderIdToAddTask.current = orderId;
    onCloseSelectOrderToAddTaskDialog();
    onOpenCreateTaskDialog();
  };

  const onCleanFlitersInFilterDialog = () => {
    setTaskDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const isLoading =
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    taskFilteringData.loading ||
    taskDataLocaly === undefined ||
    countRecords === undefined;

  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);
  const openedTaskIdRef = useRef();
  const opendedTaskDetailsDataRef = useRef();
  const getTaskDetailsDataFn = useAsyncFn(getTaskDetailsData);
  const handleOpenDetailsDrawer = useCallback(
    (taskId) => {
      if (
        openDetailsDrawer &&
        (!taskId || taskId === opendedTaskDetailsDataRef.current?.id)
      ) {
        setOpenDetailsDrawer(false);
        openedTaskIdRef.current = null;
        opendedTaskDetailsDataRef.current = null;
      } else {
        setOpenDetailsDrawer(true);
        openedTaskIdRef.current = taskId;
        executeGetTaskDetailsDataFn(taskId);
      }
    },
    [openDetailsDrawer, openedTaskIdRef.current]
  );

  const executeGetTaskDetailsDataFn = (taskId) => {
    getTaskDetailsDataFn
      .execute(taskId)
      .then((data) => (opendedTaskDetailsDataRef.current = data));
  };

  const updateTaskDataFn = useAsyncFn(updateTaskData);

  const onUpdateTaskDataPromise = (taskId, dataToSend) => {
    return updateTaskDataFn
      .execute(taskId, dataToSend)
      .then((data) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.task_data_updated")
        );
        taskData.refetch();
        return Promise.resolve(data);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_updating_task_data")
          )
        );
        return Promise.reject(error);
      });
  };

  const handleCreateTask = () => {
    if (isSearchParamsEmpty(searchParams)) {
      taskData.refetch();
    } else {
      setSearchParams({});
    }
  };

  const getExportToFileUrlByPageName = () => {
    if (pageName === "my_tasks") return exportMyTaskToFileUrl;
    return exportTaskListToFileUrl;
  };

  const taskExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      TASK_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[TASK_TABLE_NAME],
      columnsOrdersForTables?.[TASK_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[TASK_TABLE_NAME],
    columnsOrdersForTables?.[TASK_TABLE_NAME],
    TASK_TABLE_FIELDS_CONFIG,
  ]);

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={handleOpenDetailsDrawer}
      itemData={opendedTaskDetailsDataRef.current}
      filteringData={taskFilteringData?.value}
      itemType={"task"}
      isLoading={
        getTaskDetailsDataFn.loading || !opendedTaskDetailsDataRef.current
      }
      onUpdateDataPromise={onUpdateTaskDataPromise}
      onOpenDialogParamsAction={setIsDialogWithParamsClosed}
      onRefetchData={() =>
        executeGetTaskDetailsDataFn(opendedTaskDetailsDataRef.current?.id)
      }
      hasPermissionToManageTasks={
        pageName === "my_tasks" ? true : hasPermissionToManageTasks
      }
      onRefetchTableData={fullRefreshTable}
      availableActionButtons={
        pageName === "my_tasks" || hasPermissionToManageTasks
          ? ["enclosures", "change_status", "equipment_readings"]
          : ["enclosures"]
      }
    >
      <NavigationDrawer pageName={pageName}>
        <DefaultPageWrapper titleKey={pageName}>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <UniversalToolBar
                style={{ marginBlock: "5px" }}
                onClickSearch={onOpenFilterDialog}
                onClickMyFilters={onOpenUserFiltersDialog}
                onClickAdjustTable={onOpenTableColumnVisibilityDialog}
                onClickCreateItem={onOpenSelectOrderToAddTaskDialog}
                showCreateButton={true}
                showMassActionButton={false}
                itemType={"task"}
                extraButtonList={[
                  <DateFilterSelectField
                    // initialSearchParams={INITIAL_SEARCH_PARAMS}
                    filterKey={DATE_FILTER_KEY}
                  />,
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <LoaderWrapper showLoader={isLoading}>
                <TaskTable
                  showDetailsIcon={true}
                  showChangesCircle={false}
                  data={taskDataLocaly}
                  showCheckbox={false}
                  showCleanFilterIcon={false}
                  onClickRow={handleOpenDetailsDrawer}
                  countRecords={countRecords}
                  page={taskDataPage}
                  onPageChange={handleChangeTaskDataPage}
                  rowsPerPage={taskDataPageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={taskFilteringData}
                  hiddenColumns={
                    hiddenColumnsForTables
                      ? hiddenColumnsForTables[TASK_TABLE_NAME]
                      : []
                  }
                  columnsOrders={
                    columnsOrdersForTables
                      ? columnsOrdersForTables[TASK_TABLE_NAME]
                      : undefined
                  }
                  selectedTaskId={openedTaskIdRef.current}
                  style={{ maxHeight: "75vh" }}
                  tableConfig={TASK_TABLE_FIELDS_CONFIG}
                  showExportToFileButton={true}
                  exportToFileUrl={getExportToFileUrlByPageName()}
                  exportToFileSearchParams={searchParams}
                  exportToFileHeaders={taskExportHeaders}
                  exportToFileFileName={`${t("page.task_page.tasks").replace(
                    " ",
                    "_"
                  )}.xlsx`}
                  isLoading={taskData.loading || isLoading}
                />
              </LoaderWrapper>
            </Grid>
          </Grid>
          <FilterDialog
            open={openFilterDialog}
            onCleanFilters={onCleanFlitersInFilterDialog}
            onClose={onCloseFilterDialog}
            resetPageNumber={resetPageNumber}
            filterForm={
              <UniversalFilterForm
                filteringData={taskFilteringData}
                filtersConfig={TASK_TABLE_FIELDS_CONFIG}
                includeOpenCloseFilter={true}
                includeDateFilter={true}
              />
            }
          />
          {openUserFiltersDialog && (
            <UserFilterDialog
              open={openUserFiltersDialog}
              onClose={onCloseUserFiltersDialog}
              pageName={pageName}
              filterForm={
                <UniversalFilterForm
                  filteringData={taskFilteringData}
                  filtersConfig={TASK_TABLE_FIELDS_CONFIG}
                  includeOpenCloseFilter={true}
                  includeDateFilter={true}
                />
              }
            />
          )}
          {openTableColumnVisibilityDialog && (
            <TableColumnVisibilityDialog
              open={openTableColumnVisibilityDialog}
              onClose={onCloseTableColumnVisibilityDialog}
              onSubmit={handleUpdateUserPreferences}
              tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
                TABLE_CONFIGS,
                columnsOrdersForTables
              )}
              tablesHiddenColumns={hiddenColumnsForTables}
              isLoading={userPreferencesForPage.loading}
            />
          )}
          {openSelectOrderToAddTaskDialog && (
            <SelectOrderToAddTaskDialog
              open={openSelectOrderToAddTaskDialog}
              onClose={onCloseSelectOrderToAddTaskDialog}
              onSubmit={handleSelectOrderToAddTaskDialog}
            />
          )}
          {openCreateTaskDialog && (
            <CreateTaskDialog
              open={openCreateTaskDialog}
              onClose={onCloseCreateTaskDialog}
              orderId={selectedOrderIdToAddTask.current}
              onSubmit={handleCreateTask}
            />
          )}
        </DefaultPageWrapper>
      </NavigationDrawer>
    </DetailsDrawer>
  );
}
