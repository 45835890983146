import * as React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

import { StyledTableHead } from "../../base/BaseTable/BaseTable";

import {
  SELECTED_ROW_COLOR,
  FAVORITE_ROW_COLOR,
  CLAIM_ROW_COLOR,
  CLOSED_ROW_COLOR,
  OFFER_CR_ROW_COLOR,
  IS_IN_WAITING_ROOM_COLOR,
} from "../../../helpers/constants";
import FilterTableDataRow from "./FilterTableDataRow";
import { Typography } from "@mui/material";
import NoRecordsTableFooter from "../../footer/NoRecordsTableFooter";
import UniversalTableHeaders from "../UniversalTable/Headers/UniversalTableHeaders";
import BottomTable from "../../other/BottomTable";
import WindowService from "../../../services/windowService";
import { convertBoolenValueToZero } from "../../../helpers/methods";

const FilterTable = React.memo((props) => {
  const getRowBackgroundColor = (props, rowData) => {
    return props.selectedItemId === rowData.id
      ? SELECTED_ROW_COLOR
      : rowData.is_offer_cr_requested
      ? OFFER_CR_ROW_COLOR
      : rowData.is_favorite === true
      ? FAVORITE_ROW_COLOR
      : (!rowData.is_closed && rowData.is_claim === true) || rowData.is_exceed
      ? CLAIM_ROW_COLOR
      : rowData.is_closed
      ? CLOSED_ROW_COLOR
      : rowData.equipment_is_in_waiting_room
      ? IS_IN_WAITING_ROOM_COLOR
      : undefined;
  };

  const runDetailsMethod = React.useCallback(
    (rowData) => {
      const idToRunDetailsMethod = props.dataKeyToRunDetailsMethod
        ? rowData.data.find(
            (tableField) => tableField.name === props.dataKeyToRunDetailsMethod
          )?.value
        : rowData.id;
      if (props.onClickDetailsIcon) {
        props.onClickDetailsIcon(idToRunDetailsMethod);
      } else {
        WindowService.openInNewTab(
          `/${props.itemDetailsLinkPrefix}/${idToRunDetailsMethod}/`
        );
      }
    },
    [
      props.onClickDetailsIcon,
      props.dataKeyToRunDetailsMethod,
      props.itemDetailsLinkPrefix,
    ]
  );


  return (
    <>
      <TableContainer component={Paper} style={props.style}>
        {props.showTitle && props.title && (
          <Typography variant="h6" color="primary">
            {props.title}
          </Typography>
        )}
        <Table stickyHeader>
          <StyledTableHead
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <UniversalTableHeaders
              showDetailsIcon={props.showDetailsIcon}
              showChangesCircle={props.showChangesCircle}
              showFilters={props.showFilters}
              showCheckbox={props.showCheckbox}
              checkedAll={props.checkedAll}
              onClickCheckAll={props.onClickCheckAll}
              headersConfig={props.headersConfig}
              resetPageNumber={props.resetPageNumber}
              filterPrefix={props.filterPrefix}
              showCleanFilterIcon={props.showCleanFilterIcon}
              initialExpanded={props.initialExpandedFilterHeader}
              showChangesCircleFilter={props.showChangesCircleFilter}
              excludedKeysForClearSearchParams={
                props.excludedKeysForClearSearchParams
              }
            />
          </StyledTableHead>
          <TableBody>
            {props.data.map((rowData, index) => (
              <FilterTableDataRow
                hasChanges={rowData.hasChanges}
                showChangesCircle={props.showChangesCircle}
                showFilters={props.showFilters}
                showCheckbox={props.showCheckbox}
                showDetailsIcon={props.showDetailsIcon}
                onClickDetailsIcon={() => runDetailsMethod(rowData)}
                key={rowData.id}
                id={rowData.id}
                updated={rowData.updated}
                backgroundColor={getRowBackgroundColor(props, rowData)}
                selected={rowData.selected}
                data={rowData.data}
                onClickCheck={props.onClickCheck}
                onClickRow={props.onClickRow}
                showContextMenu={props.showContextMenu}
                contextMenuActions={props.contextMenuActions}
                draggableRow={props.draggableRows}
                onDragEnd={props.onDragEndRow}
                onDragStart={props.onDragStartRow}
                detailsTitle={props.detailsTitle}
              />
            ))}
          </TableBody>

          {props.data.length === 0 && (
            <NoRecordsTableFooter
              colspan={
                convertBoolenValueToZero(props.showCheckbox) +
                convertBoolenValueToZero(props.showChangesCircle) +
                convertBoolenValueToZero(props.showDetailsIcon) +
                convertBoolenValueToZero(props.showFilters) +
                props.headersConfig.length
              }
            />
          )}
        </Table>
      </TableContainer>
      <BottomTable
        showExportToFileButton={props.showExportToFileButton}
        showCounterRecords={props.showCounterRecords}
        withPagination={props.withPagination}
        page={props.page}
        pageKey={props.pageKey}
        countRecords={
          props.countRecords ? props.countRecords : props.data.length
        }
        onPageChange={props.onPageChange}
        rowsPerPage={props.rowsPerPage}
        onRowsPerPageChange={props.onRowsPerPageChange}
        rowsPerPageOptions={props.rowsPerPageOptions}
        exportToFileUrl={props.exportToFileUrl}
        exportToFileSearchParams={props.exportToFileSearchParams}
        exportToFileHeaders={props.exportToFileHeaders}
        exportToFileFileName={props.exportToFileFileName}
        exportToFileRequestContent={props.exportToFileRequestContent}
        filterPrefix={props.filterPrefix}
        isLoading={props.isLoading}
      />
    </>
  );
});

export default FilterTable;

FilterTable.propTypes = {
  showCheckbox: PropTypes.bool,
  showDetailsIcon: PropTypes.bool,
  itemDetailsLinkPrefix: PropTypes.string,
  checkedAll: PropTypes.bool,
  onClickCheckAll: PropTypes.func,
  onClickCheck: PropTypes.func,
  onClickRow: PropTypes.func,
  data: PropTypes.array,
  headersConfig: PropTypes.object,
  filteringData: PropTypes.object,
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageKey: PropTypes.string,
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  resetPageNumber: PropTypes.func,
  CollapsibleElement: PropTypes.element,
  selectedItemId: PropTypes.string,
  style: PropTypes.object,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  filterPrefix: PropTypes.string,
  showCleanFilterIcon: PropTypes.bool,
  showFilters: PropTypes.bool,
  initialExpandedFilterHeader: PropTypes.bool,
  withPagination: PropTypes.bool,
  showContextMenu: PropTypes.bool,
  contextMenuActions: PropTypes.arrayOf(PropTypes.object),
  draggableRows: PropTypes.bool,
  onDragEndRow: PropTypes.func,
  onDragStartRow: PropTypes.func,
  showChangesCircle: PropTypes.bool,
  showChangesCircleFilter: PropTypes.bool,
  hasChanges: PropTypes.bool,
  showExportToFileButton: PropTypes.bool,
  exportToFileUrl: PropTypes.string,
  exportToFileSearchParams: PropTypes.object,
  exportToFileHeaders: PropTypes.array,
  exportToFileFileName: PropTypes.string,
  showCounterRecords: PropTypes.bool,
  exportToFileRequestContent: PropTypes.object,
  excludedKeysForClearSearchParams: PropTypes.array,
  dataKeyToRunDetailsMethod: PropTypes.string,
  showFavorite: PropTypes.bool,
};

FilterTable.defaultProps = {
  showDetailsIcon: false,
  showCheckbox: true,
  showTitle: false,
  title: "",
  showCleanFilterIcon: false,
  showFilters: true,
  initialExpandedFilterHeader: false,
  onClickRow: () => {},
  showContextMenu: false,
  contextMenuActions: [],
  withPagination: true,
  draggableRows: false,
  showPagination: true,
  showChangesCircle: false,
  showChangesCircleFilter: true,
  hasChanges: true,
  showCounterRecords: true,
  showFavorite: true,
};
