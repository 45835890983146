import React from "react";
import PropTypes from "prop-types";
import TableService from "../../../services/tableService";
import FilterTable from "../FilterTable";

export default function TariffTable(props) {
  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        props.tableConfig,
        props.data,
        props.hiddenColumns,
        props.columnsOrders,
        props.onClickEdit
      ),
    [props.data]
  );
  return (
    <FilterTable
      checkedAll={props.checkedAll}
      onClickRow={props.onClickRow}
      onClickCheck={props.onClickCheck}
      onClickCheckAll={props.onClickCheckAll}
      data={data}
      filteringData={props.filteringData}
      countRecords={props.countRecords}
      page={props.page}
      onPageChange={props.onPageChange}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      rowsPerPageOptions={props.rowsPerPageOptions}
      resetPageNumber={props.resetPageNumber}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        props.tableConfig,
        props.filteringData,
        props.hiddenColumns,
        props.columnsOrders
      )}
      selectedItemId={props.selectedItemId}
      style={props.style}
      showCheckbox={props.showCheckbox}
      showTitle={props.showTitle}
      title={props.title}
      filterPrefix={props.filterPrefix}
      showCleanFilterIcon={true}
      showExportToFileButton={props.showExportToFileButton}
      exportToFileUrl={props.exportToFileUrl}
      exportToFileSearchParams={props.exportToFileSearchParams}
      exportToFileHeaders={props.exportToFileHeaders}
      exportToFileFileName={props.exportToFileFileName}
      withPagination={props.withPagination}
      showFilters={props.showFilters}
    />
  );
}

TariffTable.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  hiddenColumns: PropTypes.array,
  columnsOrders: PropTypes.array,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  filterPrefix: PropTypes.string,
  onClickEdit: PropTypes.func,
};

TariffTable.defaultProps = {
  hiddenColumns: [],
  data: [],
  showTitle: false,
  filteringData: [],
};
