import { useState, useEffect,  useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useDialog from "../../hooks/useDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import TableService from "../../services/tableService";
import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import AgreementsToolBar from "../../components/bar/AgreementsToolBar";
import FilterDialog from "../../components/base/FilterDialog";
import UserFilterDialog from "../../components/dialog/UserFilterDialog";
import {
  AGREEMENT_TABLE_FIELDS_CONFIG,
  AGREEMENT_TABLE_NAME,
} from "./PageTablesConfig";
import UniversalFilterForm from "../../components/form/UniversalFilterForm";
import useAgreementService from "../../services/agreementService";
import AgreementTable from "../../components/table/AgreementTable";
import { exportAgreementsToFileUrl } from "../../helpers/apiUrls";
import AgreementDetailsDialog from "../../components/dialog/AgreementDetailsDialog/AgreementDetailsDialog";
import RelatedAgreementsBox from "../../components/box/RelatedAgreementsBox/RelatedAgreementsBox";
import AgreementsGuaranteeBox from "../../components/box/AgreementsGuaranteeBox/AgreementsGuaranteeBox";
import FileUploadList from "../../components/other/FileUploadList";
import BaseBox from "../../components/base/BaseBox/baseBox";
import { NAVIGATION_DRAWER_TYPE_LEGAL } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import useDocsData from "../../hooks/useDocsData";
import SelectAgreementDialog from "../../components/dialog/SelectAgreementDialog";
import useGuaranteeService from "../../services/guaranteeService";
import AgreementGuaranteeDetailsDialog from "../../components/dialog/AgreementGuaranteeDetailsDialog/AgreementGuaranteeDetailsDialog";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

import { DOCS_RESTRICTED_OWNER, DOCS_RESTRICTED_USER, EDIT_AGREEMENTS_PERMISSION } from "../../helpers/constants";
import useCheckPermission from "../../hooks/usePermission";
import AddEditDocsRestrictedDialog from "../../components/dialog/AddEditDocsRestrictedDialog";

const TABLE_CONFIGS = [
  { name: AGREEMENT_TABLE_NAME, config: AGREEMENT_TABLE_FIELDS_CONFIG },
];

export default function AgreementsPage(props) {
  const { pageName } = props;

  const avaibleRestrictedAreas =  [DOCS_RESTRICTED_OWNER, DOCS_RESTRICTED_USER]

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [hasEditPermission] = useCheckPermission(EDIT_AGREEMENTS_PERMISSION)
  const [agreementsTableConfigLocal, setAgreementsTableConfigLocal] = useState(
    AGREEMENT_TABLE_FIELDS_CONFIG
  );
  const [agreementsDataLocal, setAgreementsDataLocal] = useState([]);
  const [countAgreementRecord, setCountAgreementRecord] = useState();
  const [selectedAgreementId, setSelectedAgreementId] = useState();
  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState([]);
  const [unhiddenColumnsForTables, setUnhiddenColumnsForTables] = useState([]);
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  const [relatedAgreementsLocal, setRelatedAgreementsLocal] = useState([]);
  const [relatedGuranteesLocal, setRelatedGuranteesLocal] = useState([]);
  const [selectAgreementActionType, setSelectedAgreementActionType] =
    useState();
  const [selectedAgreementGuaranteeId, setSelectedAgreemenGuaranteeId] =
    useState();

  const onCleanFlitersInFilterDialog = () => {
    setAgreementsDataLocal(undefined);
    setCountAgreementRecord(undefined);
  };

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const [
    openUserFiltersDialog,
    onOpenUserFiltersDialog,
    onCloseUserFiltersDialog,
  ] = useDialog();

  const [openAgreementDialog, onOpenAgreementDialog, onCloseAgreementDialog] =
    useDialog();

  const [openNewDocDialog, onOpenNewDocDialog, onCloseNewDocDialog] =
    useDialog();

  const [
    openSelectAgreementDialog,
    onOpenSelectAgreementDialog,
    onCloseSelectAgreementDialog,
  ] = useDialog();

  const [
    openGuaranteeAgreementDialog,
    onOpenGuaranteeAgreementDialog,
    onCloseGuaranteeAgreementDialog,
  ] = useDialog();

  const {
    resetPageNumber,
    searchParams
  } = usePaginationWithSearchParams();

  const {
    docs,
    isDocsLoading,
    hideDocs,
    onDownloadDoc,
    onPreviewDoc,
    refetchDocs,
    isSavedCorrectly,
    setIsSaveCorrectly,
  } = useDocsData(selectedAgreementId, "agreement");

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getUnhiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const { getAgreements, getAgreementsFilteringData, getRelatedAgreements } =
    useAgreementService();

  const { getGuaranteeDataByAgreement } = useGuaranteeService();


  const agreementsData = useAsync(
    () => getAgreements(searchParams),
    [searchParams]
  );

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const agreementFilteringData = useAsync(getAgreementsFilteringData);
  const getGuaranteeDataByAgreementFn = useAsyncFn(getGuaranteeDataByAgreement);
  const getRelatedAgreementsFn = useAsyncFn(getRelatedAgreements);

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const onRelatedAgreements = (agreementId) => {
    getRelatedAgreementsFn.execute(agreementId).then((data) => {
      setRelatedAgreementsLocal(data);
    });
  };

  const onRelatedGuarantees = (agreementId) => {
    getGuaranteeDataByAgreementFn
      .execute({ agreement: agreementId })
      .then((data) => {
        setRelatedGuranteesLocal(data);
      });
  };

  useEffect(() => {
    if (
      hasEditPermission &&
      agreementsTableConfigLocal.find((config) => config.name === "edit") ===
        undefined
    ) {
      const tempConfig = [...AGREEMENT_TABLE_FIELDS_CONFIG];
      tempConfig.push({
        name: "edit",
        getValueCallback: (rowData, onClickEdit, hasPermission) => {
          const content = (
            <ModeEditOutlineOutlined
              onClick={(e) => onClickEdit(e, rowData.id)}
            />
          );
          return rowData.agreement_active && hasPermission ? content : "";
        },
        label: t("table.agreements_table.edit"),
        filterType: null,
        getFilterFieldConfigCallback: (filteringData) => undefined,
      });
      setAgreementsTableConfigLocal(tempConfig);
    }
  }, [hasEditPermission]);

  useEffect(() => {
    if (agreementsData.loading) {
      return;
    }else if (agreementsData?.value){
      setAgreementsDataLocal(agreementsData.value.results);
      setCountAgreementRecord(agreementsData.value.count);
    }
  }, [agreementsData.loading]);

  useEffect(() => {
    if (isSavedCorrectly) {
      onCloseNewDocDialog();
      setIsSaveCorrectly(false);
    }
  }, [isSavedCorrectly]);

  useEffect(() => {
    if (userPreferencesForPage.loading) {
      setHiddenColumnsForTables(undefined);
      setColumnsOrdersForTables(undefined);
      setUnhiddenColumnsForTables(undefined)
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [AGREEMENT_TABLE_NAME],
        userPreferencesForPage.value,
        {[AGREEMENT_TABLE_NAME]: AGREEMENT_TABLE_FIELDS_CONFIG}
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [AGREEMENT_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    
    setUnhiddenColumnsForTables(() =>
      getUnhiddenColumnFormValueFromBackend(
        [AGREEMENT_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

  }, [userPreferencesForPage.loading]);

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders,
    uhiddenDefaultColumns
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders,
          uhiddenDefaultColumns
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );
        userPreferencesForPage.refetch();
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const handleClickAgreementRow = (agreementId) => {
    if (!agreementId || agreementId === selectedAgreementId) {
      setSelectedAgreementId(undefined);
      setRelatedAgreementsLocal([]);
      setRelatedGuranteesLocal([]);
    } else {
      setSelectedAgreementId(agreementId);
      onRelatedAgreements(agreementId);
      onRelatedGuarantees(agreementId);
    }
  };

  const handleOpenAgreementDetails = useCallback(
    (e, agreementId) => {
      e.stopPropagation();
      setSelectedAgreementId(agreementId);
      onOpenAgreementDialog();
    },
    [selectedAgreementId]
  );

  const handleOpenAgreementGuaranteeDetails = useCallback(
    (e, agreementGuaranteeId) => {
      e.stopPropagation();
      setSelectedAgreemenGuaranteeId(agreementGuaranteeId);
      onOpenGuaranteeAgreementDialog();
    },
    [selectedAgreementGuaranteeId]
  );

  const handleOpenNewAgreement = useCallback(() => {
    onOpenAgreementDialog();
  }, [selectedAgreementId]);

  const onClickAddNewDoc = useCallback(() => {
    if (selectedAgreementId) {
      onOpenNewDocDialog();
    } else {
      setSelectedAgreementActionType("newDocs");
      onOpenSelectAgreementDialog();
    }
  }, [selectedAgreementId]);

  const onClickAddNewGuarantee = useCallback(() => {
    if (selectedAgreementId) {
      onOpenGuaranteeAgreementDialog();
    } else {
      setSelectedAgreementActionType("newGuarantee");
      onOpenSelectAgreementDialog();
    }
  }, [selectedAgreementId]);

  const onChooseAgreement = useCallback(
    (agreementId) => {
      setSelectedAgreementId(agreementId);
      if (selectAgreementActionType === "newDocs") {
        onOpenNewDocDialog();
      } else if (selectAgreementActionType === "newGuarantee") {
        onOpenGuaranteeAgreementDialog();
      }
      setSelectedAgreementActionType(undefined);
      onCloseSelectAgreementDialog();
    },
    [selectedAgreementId, selectAgreementActionType]
  );


  const onCloseDocumentDialog = () =>{
    setSelectedAgreementId(undefined);
    onCloseNewDocDialog();
  }


  const onRefetchDetails = () => {
    if (selectedAgreementId) {
      onRelatedAgreements(selectedAgreementId);
      onRelatedGuarantees(selectedAgreementId);
      refetchDocs();
    }
  };

  const closeAgreementDialog = useCallback(() => {
    setSelectedAgreementId(undefined);
    onCloseAgreementDialog();
  }, [selectedAgreementId]);

  const closeGuaranteeAgreementDialog = useCallback(() => {
    setSelectedAgreemenGuaranteeId(undefined);
    onCloseGuaranteeAgreementDialog();
  }, [selectedAgreementGuaranteeId]);

  const isAgreementTableLoading =
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    columnsOrdersForTables === undefined ||
    agreementFilteringData.loading ||
    agreementsDataLocal === undefined ||
    countAgreementRecord === undefined;

  const isDetailsLoading = getRelatedAgreementsFn.loading;

  const agreementExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      AGREEMENT_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[AGREEMENT_TABLE_NAME],
      columnsOrdersForTables?.[AGREEMENT_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[AGREEMENT_TABLE_NAME],
    columnsOrdersForTables?.[AGREEMENT_TABLE_NAME],
    AGREEMENT_TABLE_FIELDS_CONFIG,
  ]);


  const getSelectedAgreementDetails = () => {
    return (
      <LoaderWrapper showLoader={isDetailsLoading}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RelatedAgreementsBox relatedAgreements={relatedAgreementsLocal} />
          </Grid>
          <Grid item xs={8}>
            <AgreementsGuaranteeBox
              guaranteeAgreements={relatedGuranteesLocal}
              hasPermission={hasEditPermission}
              onClickEdit={handleOpenAgreementGuaranteeDetails}
            />
          </Grid>
          <Grid item xs={4}>
            <BaseBox>
              <FileUploadList
                addEnclosureButtonProps={{ size: "mini" }}
                enclosures={docs}
                fileType={"docs"}
                onPreviewEnclosure={onPreviewDoc}
                onDownloadEnclosure={onDownloadDoc}
                onDeleteEnclosure={hideDocs}
                canRemoveEnclosures={true}
                readOnly={isDocsLoading || !hasEditPermission}
                agreement = {selectedAgreementId}
                refetchDocs = {refetchDocs}
                availableDocsRestrictedTypes= {avaibleRestrictedAreas}
                multiple = {true}
              />
            </BaseBox>
          </Grid>
        </Grid>
      </LoaderWrapper>
    );
  };
  return (
    <NavigationDrawer
      drawerType={NAVIGATION_DRAWER_TYPE_LEGAL}
      pageName={pageName}
    >
      <DefaultPageWrapper titleKey={"agreements"}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <AgreementsToolBar
              style={{ marginBlock: "5px" }}
              params={searchParams}
              onClickSearch={onOpenFilterDialog}
              onClickCreateDocument={onClickAddNewDoc}
              onClickCreateGuarantee={onClickAddNewGuarantee}
              onClickMyFilters={onOpenUserFiltersDialog}
              onClickAdjustTable={onOpenTableColumnVisibilityDialog}
              onClickCreateAgreement={handleOpenNewAgreement}
              hasPermission={hasEditPermission}
              selectedAgreementId={selectedAgreementId}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isAgreementTableLoading}>
              <AgreementTable
                data={agreementsDataLocal}
                onClickRow={handleClickAgreementRow}
                countRecords={countAgreementRecord}
                resetPageNumber={resetPageNumber}
                filteringData={
                  agreementFilteringData ? agreementFilteringData : []
                }
                hiddenColumns={
                  hiddenColumnsForTables
                    ? hiddenColumnsForTables[AGREEMENT_TABLE_NAME]
                    : []
                }
                columnsOrders={
                  columnsOrdersForTables
                    ? columnsOrdersForTables[AGREEMENT_TABLE_NAME]
                    : undefined
                }
                selectedAgreementId={selectedAgreementId}
                style={{ maxHeight: "40vh" }}
                tableConfig={agreementsTableConfigLocal}
                showCleanFilterIcon={true}
                showContextMenu={false}
                showExportToFileButton={true}
                exportToFileUrl={exportAgreementsToFileUrl}
                exportToFileSearchParams={searchParams}
                exportToFileHeaders={agreementExportHeaders}
                exportToFileFileName={`${t(
                  "page.agreement_page.agreements"
                ).replace(" ", "_")}.xlsx`}
                showDetailsIcon={false}
                onClickEdit={handleOpenAgreementDetails}
                detailsTitle={"page.agreement_page.open_dialog_with_details"}
                hasPermission={hasEditPermission}
                isLoading = {agreementsData.loading || isAgreementTableLoading}
                
              />
            </LoaderWrapper>
          </Grid>
          <Grid item xs={12}>
            {selectedAgreementId ? (
              getSelectedAgreementDetails()
            ) : (
              <Typography
                textAlign="center"
                variant="overline"
                display="block"
                gutterBottom
                style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
              >
                {t("page.agreement_page.choose_agreement_to_show_details")}
              </Typography>
            )}
          </Grid>
        </Grid>

        {openTableColumnVisibilityDialog && (
          <TableColumnVisibilityDialog
            open={openTableColumnVisibilityDialog}
            onClose={onCloseTableColumnVisibilityDialog}
            onSubmit={handleUpdateUserPreferences}
            tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
              TABLE_CONFIGS,
              columnsOrdersForTables
            )}
            tablesHiddenColumns={hiddenColumnsForTables}
            tablesUnhiddenDefaultColumns = {unhiddenColumnsForTables}
            isLoading={userPreferencesForPage.loading}
          />
        )}
        {openFilterDialog && (
          <FilterDialog
            open={openFilterDialog}
            onCleanFilters={onCleanFlitersInFilterDialog}
            onClose={onCloseFilterDialog}
            resetPageNumber={resetPageNumber}
            filterForm={
              <UniversalFilterForm
                filteringData={agreementFilteringData}
                filtersConfig={agreementsTableConfigLocal}
                includeOpenCloseFilter={false}
              />
            }
          />
        )}
        {openUserFiltersDialog && (
          <UserFilterDialog
            open={openUserFiltersDialog}
            onClose={onCloseUserFiltersDialog}
            pageName={pageName}
            filterForm={
              <UniversalFilterForm
                filteringData={agreementFilteringData}
                filtersConfig={agreementsTableConfigLocal}
                includeOpenCloseFilter={false}
              />
            }
          />
        )}
        {openAgreementDialog && (
          <AgreementDetailsDialog
            open={openAgreementDialog}
            onClose={closeAgreementDialog}
            filteringData={agreementFilteringData}
            onRefetchData={agreementsData.refetch}
            onRefetchDetails={onRefetchDetails}
            agreementId={selectedAgreementId}
          />
        )}
        {openGuaranteeAgreementDialog && (
          <AgreementGuaranteeDetailsDialog
            open={openGuaranteeAgreementDialog}
            onClose={closeGuaranteeAgreementDialog}
            filteringData={agreementFilteringData}
            onRefetchDetails={onRefetchDetails}
            agreementId={selectedAgreementId}
            guaranteeId={selectedAgreementGuaranteeId}
          />
        )}
        {openNewDocDialog && (
     
          <AddEditDocsRestrictedDialog
           open={openNewDocDialog}
            onClose={onCloseNewDocDialog}
          docsId={undefined}
          submitButtonLabel={t("dialog.enclosures_dialog.add_docs")}
          agreement ={selectedAgreementId}
          onSubmit={onCloseDocumentDialog }
          multiple={true}
          availableDocsRestrictedTypes= {avaibleRestrictedAreas}
          readOnly={isDocsLoading || !hasEditPermission}

        />
        )}
        {openSelectAgreementDialog && (
          <SelectAgreementDialog
            open={openSelectAgreementDialog}
            onClose={onCloseSelectAgreementDialog}
            onSubmit={onChooseAgreement}
          />
        )}
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
