import {
  equipmentsForMetersUrl,
  equipmentForMetersFilteringDataUrl,
  equipmentForCreateMeterFilteringDataUrl,
  equipmentForMetersDetailsUrl,
  equipmentForMetersShortDetailsUrl,
  equipmentParameterValuesUrl,
  equipmentStateForMetersUrl,
  equipmentTenantsForTenantSetUrl,
  equipmentMediaTypeForMetersUrl,
  equipmentDataForAssignMeterUrl,
  equipmentItemDetailsUrl,
  equipmentItemUrl,
  equipmentSelectListUrl,
  equipmentItemParamterValueUrl,
  equipmentItemParamterValueDetailsUrl,
  equipmentItemParamtersValueHideUrl,
  equipmentInstalationUrl,
  equipmentInstalationTenantSetUrl,
  equipmentInstalationObjectSetUrl,
  equipmentItemBranchUrl,
  equipmentInstalationDetailsUrl,
  equipmentInstalationObjectSetDetailsUrl,
  equipmentInstalationTenantSetDetailsUrl,
  equipmentInstalationsForEquipmentUrl,
  equipmentInstalationObjectSetsForEquipmentUrl,
  equipmentInstalationTenantSetsForEquipmentUrl,
  addEquipmentsToWaitingRoomUrl,
  deleteEquipemntsFromWaitingRoomUrl,
  stdTicketConfigForEquipments,
  equipmentBmsForOrderUrl,
  equipmentBmsOrderUrl,
  equipmentDataForReadingUrl,
  equipmentBmsListDataUrl,
  equipmentBmsFilteringDataUrl,
  equipmentBmsChangeStatusUrl,
  equipmentBmsDetailsUrl,
  ppeDetailsUrl,
  ppeUrl,
  ppeFilteringDataUrl,
  electicityDistributionUrl,
  electricityDistibutionFilteringDataUrl,
  electricityDistibutionRecalculateUrl,
  equipmentBmsUnmatchedListDataUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import { makeRequestWithLanguage } from "../helpers/makeRequestWithLanguage";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useFileService from "./fileService";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../context/snackbarAlert";

export default function useEquipmentService() {
  const axiosPrivate = useAxiosPrivate();

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const { uploadAttachments } = useFileService();

  const getEquipmentForMeters = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(equipmentsForMetersUrl, searchParams)
    );
  };

  const getEquipmentForMeterDetails = (equipmentId) => {
    return makeRequest(axiosPrivate, equipmentForMetersDetailsUrl(equipmentId));
  };

  const getEquipmentDataForAssignMeter = (equipmentId) => {
    return makeRequest(
      axiosPrivate,
      equipmentDataForAssignMeterUrl(equipmentId)
    );
  };

  const updateEquipment = (equipmentId, dataToSend) => {
    return makeRequest(
      axiosPrivate,
      equipmentForMetersDetailsUrl(equipmentId),
      {
        method: "PATCH",
        data: dataToSend,
      }
    );
  };

  const createNewEquipmentItem = async (dataToSend) => {
    const files = dataToSend.files;
    delete dataToSend.files;

    let results = await uploadAttachments(files, {});
    let uploadedData = results["uploadedData"];
    let showWaring = results["showWaring"];

    dataToSend.enclosures = uploadedData.filter(
      (file) => file.objectType === "enclosure"
    );
    dataToSend.certs = uploadedData.filter(
      (file) => file.objectType === "certs"
    );

    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, equipmentItemUrl, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          if (showWaring) {
            snackbarAlert.openWarningSnackbarAlert(
              t("snackbar_alert.occurred_warning_during_upload_file")
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const removeEquipment = (equipmentId) => {
    return makeRequest(
      axiosPrivate,
      equipmentForMetersDetailsUrl(equipmentId),
      {
        method: "DELETE",
      }
    );
  };

  const getEquipmentItemDetials = (equipmentId) => {
    return makeRequest(axiosPrivate, equipmentItemDetailsUrl(equipmentId));
  };

  const getEquipmentForMetersFilteringData = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        equipmentForMetersFilteringDataUrl,
        searchParams
      )
    );
  };

  const getEquipmentForCreateMeterFilteringData = (
    searchParams = undefined
  ) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        equipmentForCreateMeterFilteringDataUrl,
        searchParams
      )
    );
  };

  const getEquipmentParameterValues = (equipmentId) => {
    return makeRequest(axiosPrivate, equipmentParameterValuesUrl(equipmentId));
  };

  const updateEquipmentParameterValues = (equipmentId, dataToSend) => {
    return makeRequest(axiosPrivate, equipmentParameterValuesUrl(equipmentId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getEquipmentStateForMeters = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(equipmentStateForMetersUrl, searchParams)
    );
  };

  const getEquipmentInstalationForMeters = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(equipmentInstalationUrl, searchParams)
    );
  };

  const getEquipmentInstalationTenantSetForMeters = (
    searchParams = undefined
  ) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(equipmentInstalationTenantSetUrl, searchParams)
    );
  };

  const getEquipmentInstalationObjectSetForMeters = (
    searchParams = undefined
  ) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(equipmentInstalationObjectSetUrl, searchParams)
    );
  };

  const getEquipmentTenantForTenantSet = (tenantSetId) => {
    return makeRequest(
      axiosPrivate,
      equipmentTenantsForTenantSetUrl(tenantSetId)
    );
  };

  const getEquipmentMediaTypeForMeters = () => {
    return makeRequest(axiosPrivate, equipmentMediaTypeForMetersUrl);
  };

  const getEquipmentSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(equipmentSelectListUrl, searchParams)
    );
  };

  const createParameterValue = (parameterData) => {
    return makeRequest(axiosPrivate, equipmentItemParamterValueUrl, {
      method: "POST",
      data: parameterData,
    });
  };

  const updateParameterValue = (equipmentParameterValueId, parameterData) => {
    return makeRequest(
      axiosPrivate,
      equipmentItemParamterValueDetailsUrl(equipmentParameterValueId),
      {
        method: "PATCH",
        data: parameterData,
      }
    );
  };

  const removeParameterValue = (equipmentParameterValueId) => {
    return makeRequest(
      axiosPrivate,
      equipmentItemParamterValueDetailsUrl(equipmentParameterValueId),
      {
        method: "DELETE",
      }
    );
  };

  const getEquipmentTenantFieldId = (index, fieldName = "tenant") => {
    return `equipment_tenant__${fieldName}__level_${index}`;
  };

  const getEquipmentSelectListData = (searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(equipmentSelectListUrl, searchParams)
    );
  };

  const getEquipmentItemRecordById = (equipmentId) => {
    return makeRequest(axiosPrivate, equipmentItemBranchUrl(equipmentId));
  };

  const getEquipmentInstalationsForEquipmentData = (
    equipmentEquipmentInstalationId
  ) => {
    return makeRequest(
      axiosPrivate,
      equipmentInstalationsForEquipmentUrl(equipmentEquipmentInstalationId)
    );
  };

  const getEquipmentInstalationDetailsData = (
    equipmentEquipmentInstalationId
  ) => {
    return makeRequest(
      axiosPrivate,
      equipmentInstalationDetailsUrl(equipmentEquipmentInstalationId)
    );
  };

  const updateEquipmentInstalation = (
    equipmentEquipmentInstalationId,
    data
  ) => {
    return makeRequest(
      axiosPrivate,
      equipmentInstalationDetailsUrl(equipmentEquipmentInstalationId),
      {
        method: "PATCH",
        data: data,
      }
    );
  };

  const removeEquipmentInstalation = (equipmentEquipmentInstalationId) => {
    return makeRequest(
      axiosPrivate,
      equipmentInstalationDetailsUrl(equipmentEquipmentInstalationId),
      {
        method: "DELETE",
      }
    );
  };

  const getEquipmentInstalationTenantSetsForEquipmentData = (
    equipmentEquipmentInstalationTenantSetId
  ) => {
    return makeRequest(
      axiosPrivate,
      equipmentInstalationTenantSetsForEquipmentUrl(
        equipmentEquipmentInstalationTenantSetId
      )
    );
  };

  const getEquipmentInstalationTenantSetDetailsData = (
    equipmentEquipmentInstalationTenantSetId
  ) => {
    return makeRequest(
      axiosPrivate,

      equipmentInstalationTenantSetDetailsUrl(
        equipmentEquipmentInstalationTenantSetId
      )
    );
  };

  const updateEquipmentInstalationTenantSet = (
    equipmentEquipmentInstalationTenantSetId,
    data
  ) => {
    return makeRequest(
      axiosPrivate,
      equipmentInstalationTenantSetDetailsUrl(
        equipmentEquipmentInstalationTenantSetId
      ),
      {
        method: "PATCH",
        data: data,
      }
    );
  };

  const removeEquipmentInstalationTenantSet = (
    equipmentEquipmentInstalationTenantSetId
  ) => {
    return makeRequest(
      axiosPrivate,
      equipmentInstalationTenantSetDetailsUrl(
        equipmentEquipmentInstalationTenantSetId
      ),
      {
        method: "DELETE",
      }
    );
  };

  const getEquipmentInstalationObjectSetsForEquipmentData = (
    equipmentEquipmentInstalationObjectSetId
  ) => {
    return makeRequest(
      axiosPrivate,
      equipmentInstalationObjectSetsForEquipmentUrl(
        equipmentEquipmentInstalationObjectSetId
      )
    );
  };

  const getEquipmentInstalationObjectSetDetailsData = (
    equipmentEquipmentInstalationObjectSetId
  ) => {
    return makeRequest(
      axiosPrivate,

      equipmentInstalationObjectSetDetailsUrl(
        equipmentEquipmentInstalationObjectSetId
      )
    );
  };

  const updateEquipmentInstalationObjectSet = (
    equipmentEquipmentInstalationObjectSetId,
    data
  ) => {
    return makeRequest(
      axiosPrivate,
      equipmentInstalationObjectSetDetailsUrl(
        equipmentEquipmentInstalationObjectSetId
      ),
      {
        method: "PATCH",
        data: data,
      }
    );
  };

  const removeEquipmentInstalationObjectSet = (
    equipmentEquipmentInstalationObjectSetId
  ) => {
    return makeRequest(
      axiosPrivate,
      equipmentInstalationObjectSetDetailsUrl(
        equipmentEquipmentInstalationObjectSetId
      ),
      {
        method: "DELETE",
      }
    );
  };

  const addEquipmentsToWaitingRoom = (equipments_ids) => {
    return makeRequest(axiosPrivate, addEquipmentsToWaitingRoomUrl, {
      method: "POST",
      data: equipments_ids,
    });
  };

  const deleteEquipmentsFromWaitingRoom = (equipments_ids) => {
    return makeRequest(axiosPrivate, deleteEquipemntsFromWaitingRoomUrl, {
      method: "POST",
      data: equipments_ids,
    });
  };

  const getStdTicketsConfigForEquipment = () => {
    return makeRequestWithLanguage(axiosPrivate, stdTicketConfigForEquipments);
  };

  const getEquipmentBmsDataForOrder = (orderId) => {
    return makeRequest(axiosPrivate, equipmentBmsForOrderUrl(orderId));
  };

  const updateEquipmentBmsOrder = async (equipmentOrderId, dataToSend) => {
    const files = dataToSend.files;
    delete dataToSend.files;

    let results = await uploadAttachments(files, {});
    let uploadedData = results["uploadedData"];
    let showWaring = results["showWaring"];

    dataToSend.enclosures = uploadedData.filter(
      (file) => file.objectType === "enclosure"
    );

    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, equipmentBmsOrderUrl(equipmentOrderId), {
        method: "PATCH",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          if (showWaring) {
            snackbarAlert.openWarningSnackbarAlert(
              t("snackbar_alert.occurred_warning_during_upload_file")
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getEquipmentDataForReading = (equipmentId) => {
    return makeRequest(axiosPrivate, equipmentDataForReadingUrl(equipmentId));
  };

  const getEquipmentBmsListData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(equipmentBmsListDataUrl, searchParams)
    );
  };

  const getEquipmentBmsUnmatchedListData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(equipmentBmsUnmatchedListDataUrl, searchParams)
    );
  };

  const getEquipmentBmsFilteringData = () => {
    return makeRequest(axiosPrivate, equipmentBmsFilteringDataUrl);
  };

  const changeEquipmentBmsStatus = (equipemntBmsIds, statusKind) => {
    return makeRequest(axiosPrivate, equipmentBmsChangeStatusUrl, {
      method: "POST",
      data: {
        equipment_bms: equipemntBmsIds,
        equipbms_status: statusKind,
      },
    });
  };

  const getEquipmentBmsDetailsData = (equipmentBmsId) => {
    return makeRequest(
      axiosPrivate,

      equipmentBmsDetailsUrl(equipmentBmsId)
    );
  };

  const updateEquipmentBms = (equipmentBmsId, data) => {
    return makeRequest(axiosPrivate, equipmentBmsDetailsUrl(equipmentBmsId), {
      method: "PATCH",
      data: data,
    });
  };

  const getPpeListData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(ppeUrl, searchParams)
    );
  };

  const getPpeFilteringData = () => {
    return makeRequest(axiosPrivate, ppeFilteringDataUrl);
  };

  const getPpeDetailsData = (id) => {
    return makeRequest(
      axiosPrivate,

      ppeDetailsUrl(id)
    );
  };

  const createPpe = (data) => {
    return makeRequest(axiosPrivate, ppeUrl, {
      method: "POST",
      data: data,
    });
  };

  const updatePpe = (data, id) => {
    return makeRequest(axiosPrivate, ppeDetailsUrl(id), {
      method: "PATCH",
      data: data,
    });
  };

  const getElectricityDistributionListData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(electicityDistributionUrl, searchParams)
    );
  };

  const getElectricityDistributionFilteringData = () => {
    return makeRequest(axiosPrivate, electricityDistibutionFilteringDataUrl);
  };

  const recalculateElectricityDistribution = (
    equipment,
    year,
    month,
    end_year,
    end_month
  ) => {
    return makeRequest(axiosPrivate, electricityDistibutionRecalculateUrl, {
      method: "POST",
      data: { equipment, year, month, end_year, end_month },
    });
  };

  return {
    getEquipmentForMeters,
    getEquipmentInstalationForMeters,
    getEquipmentInstalationObjectSetForMeters,
    getEquipmentInstalationTenantSetForMeters,
    getEquipmentForMeterDetails,
    getEquipmentForMetersFilteringData,
    getEquipmentForCreateMeterFilteringData,
    getEquipmentParameterValues,
    createNewEquipmentItem,
    updateEquipment,
    removeEquipment,
    updateEquipmentParameterValues,
    getEquipmentStateForMeters,
    getEquipmentTenantForTenantSet,
    getEquipmentMediaTypeForMeters,
    getEquipmentDataForAssignMeter,
    getEquipmentItemDetials,
    getEquipmentSelectList,
    createParameterValue,
    updateParameterValue,
    removeParameterValue,
    getEquipmentTenantFieldId,
    getEquipmentSelectListData,
    getEquipmentItemRecordById,
    getEquipmentInstalationsForEquipmentData,
    getEquipmentInstalationDetailsData,
    updateEquipmentInstalation,
    removeEquipmentInstalation,
    getEquipmentInstalationTenantSetsForEquipmentData,
    getEquipmentInstalationTenantSetDetailsData,
    updateEquipmentInstalationTenantSet,
    removeEquipmentInstalationTenantSet,
    getEquipmentInstalationObjectSetsForEquipmentData,
    getEquipmentInstalationObjectSetDetailsData,
    updateEquipmentInstalationObjectSet,
    removeEquipmentInstalationObjectSet,
    addEquipmentsToWaitingRoom,
    deleteEquipmentsFromWaitingRoom,
    getStdTicketsConfigForEquipment,
    getEquipmentBmsDataForOrder,
    updateEquipmentBmsOrder,
    getEquipmentDataForReading,
    getEquipmentBmsListData,
    getEquipmentBmsFilteringData,
    changeEquipmentBmsStatus,
    getEquipmentBmsDetailsData,
    updateEquipmentBms,
    getPpeListData,
    getPpeDetailsData,
    getPpeFilteringData,
    createPpe,
    updatePpe,
    getElectricityDistributionListData,
    getElectricityDistributionFilteringData,
    recalculateElectricityDistribution,
    getEquipmentBmsUnmatchedListData,
  };
}
