import { useMemo, useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import useEquipmentService from "../../services/equipmentService";

import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

import { NAVIGATION_DRAWER_TYPE_METERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import ElectricityDistributionTable from "../../components/table/ElectricityDistributionTable";
import TableService from "../../services/tableService";
import { exportElecticityDistributionUrl } from "../../helpers/apiUrls";

import { useSnackbarAlert } from "../../context/snackbarAlert";

import {
  ED_TABLE_NAME,
  ED_FILTER_PREFIX,
  ED_TABLE_FIELDS_CONFIG,
} from "./TableConfig";

const CONFIG_FOR_USER_PREFERENCEDIALOG = {
  name: ED_TABLE_NAME,
  config: ED_TABLE_FIELDS_CONFIG,
};

export default function ElectricityDistributionPage(props) {
  const { pageName } = props;

  const snackbarAlert = useSnackbarAlert();
  const { t } = useTranslation();

  const [dataLocaly, setDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(ED_FILTER_PREFIX);

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefixes,
  } = useFilterSearchParams(searchParams);

  const [filterSearchParams, setFilterSearchParams] = useState(
    getSearchParamsByFilterPrefix(ED_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      ED_FILTER_PREFIX,
      filterSearchParams,
      setFilterSearchParams
    );
  }, [searchParams]);

  const {
    getElectricityDistributionListData,
    getElectricityDistributionFilteringData,
    recalculateElectricityDistribution,
  } = useEquipmentService();

  const filteringData = useAsync(getElectricityDistributionFilteringData);
  const recalculateElectricityDistributionFn = useAsyncFn(
    recalculateElectricityDistribution
  );

  const backendData = useAsync(
    () => getElectricityDistributionListData(filterSearchParams),
    [filterSearchParams]
  );

  useEffect(() => {
    if (backendData.loading) {
      return;
    }
    setDataLocaly(backendData.value.results);
    setCountRecords(backendData.value.count);
  }, [backendData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setDataLocaly(undefined);
    handleChangePageWithSearchParams(undefined, 1);
    backendData.refetch();
  };

  const exportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      ED_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[ED_TABLE_NAME],
      columnsOrdersForTables?.[ED_TABLE_NAME]
    );
  }, [
    ED_TABLE_FIELDS_CONFIG,
    hiddenColumnsForTables?.[ED_TABLE_NAME],
    columnsOrdersForTables?.[ED_TABLE_NAME],
  ]);

  const handleRecalculateElectricityDistribution = (
    equipmentId,
    year,
    month
  ) => {
    recalculateElectricityDistributionFn
      .execute(equipmentId, year, month)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.electricity_distribution_updated")
        );
        refreshTable();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_electricity_distribution"
          )
        );
      });
  };

  const isLoading =
    dataLocaly === undefined ||
    filteringData.loading ||
    recalculateElectricityDistributionFn.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_METERS}
    >
      <DefaultPageWrapper titleKey={"electricity_distribution"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <UniversalToolBarWithDialogs
              pageName={props.pageName}
              tableConfig={CONFIG_FOR_USER_PREFERENCEDIALOG}
              filteringData={filteringData}
              refreshTable={refreshTable}
              onCleanFlitersInFilterDialog={() => {}}
              resetPageNumber={resetPageNumber}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showOpenCloseFilterButton={false}
              showCreateButton={false}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isLoading}>
              {hiddenColumnsForTables && columnsOrdersForTables && (
                <ElectricityDistributionTable
                  showCheckbox={false}
                  data={dataLocaly}
                  showCleanFilterIcon={true}
                  countRecords={countRecords}
                  page={page}
                  onPageChange={handleChangePageWithSearchParams}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={filteringData}
                  filterPrefix={ED_FILTER_PREFIX}
                  style={{ maxHeight: "75vh" }}
                  tableConfig={ED_TABLE_FIELDS_CONFIG}
                  hiddenColumns={hiddenColumnsForTables[ED_TABLE_NAME]}
                  columnsOrders={columnsOrdersForTables[ED_TABLE_NAME]}
                  onClickRefresh={handleRecalculateElectricityDistribution}
                  showExportToFileButton={true}
                  exportToFileUrl={exportElecticityDistributionUrl}
                  exportToFileSearchParams={filterSearchParams}
                  exportToFileHeaders={exportHeaders}
                  exportToFileFileName={`${t(
                    "headers.electricity_distribution"
                  )}.xlsx`}
                />
              )}
            </LoaderWrapper>
          </Grid>
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
