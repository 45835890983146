
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../context/snackbarAlert";
import { calculationScheduleUrl, consolidationInitDataUrl, createIcoUrl, currencyDetailsUrl, currencyRateUrl, currencyUrl, defaultIcoParticipiantsUrl, defaultIcoProductUrl, draftDetailsUrl, earliestInterestDateUrl, icoAcceptListUrl, icoAgreementGenerateDetailsUrl, icoCurrencytSelectListUrl, icoDetailsUrl, icoDocsRegistryListUrl, icoDraftSelectListUrl, icoDraftsFilteringDataUrl, icoDraftsUrl, icoFilteringDataUrl, icoParticipiantsUrl, icoPaymentFilteringDataUrl, icoPaymentListDataUrl, icoProductDetailsUrl, icoProductUrl, icoRegistryFlatListUrl, icoRegistryListUrl, icoScheduleListDataUrl, icoSelectDataUrl, icoTransferListDataUrl, icoTransferTitleUrl, interestDetailsUrl, interestRateDetailsUrl, interestRateUrl, interestUrl, minMaxTransferDateUrl, relatedIcosUrl, transferDetailsUrl, transferShortDetailsUrl } from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import {
  prepareUrlWithQueryParams
} from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useDocsService from "./docsService";

export default function useIcoService() {
  const axiosPrivate = useAxiosPrivate();

  const { uploadFiles } = useDocsService();

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const getIcoRegistryList = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(icoRegistryListUrl, searchParams)
    );
  };

  const getIcoRegistryFlatList = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(icoRegistryFlatListUrl, searchParams)
    );
  };

  const getIcoAcceptList = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(icoAcceptListUrl, searchParams)
    );
  };

  const getIcoDocsRegistryList = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(icoDocsRegistryListUrl, searchParams)
    );
  };

  const getRelatedIcos = (icoId) => {
    if (icoId) {
      return makeRequest(axiosPrivate, relatedIcosUrl(icoId));
    }
    return Promise.resolve([]);
  };

  const getIcoDetails = (icoId) => {
    return makeRequest(axiosPrivate, icoDetailsUrl(icoId));
  };

  const getIcoAgreementGenerateDetails = (icoId) => {
    return makeRequest(axiosPrivate, icoAgreementGenerateDetailsUrl(icoId));
  };

  const getParticipiants = (icoId) => {
    if (icoId) {
      return makeRequest(axiosPrivate, icoParticipiantsUrl(icoId));
    }
    return Promise.resolve([]);
  };

  const getIcoFilteringData = () => {
    return makeRequest(
      axiosPrivate,
      icoFilteringDataUrl
    );
  }
  const getIcoSelectData = () => {
    return makeRequest(
      axiosPrivate,
      icoSelectDataUrl
    );
  }

  const getDefaultIcoProductData = () => {
    return makeRequest(
      axiosPrivate,
      defaultIcoProductUrl
    );
  }

  const getDefaultUsersForBorrowerAndLender = (data) => {
    return makeRequest(axiosPrivate, defaultIcoParticipiantsUrl, {
      method: "POST",
      data: data,
    });
  };

  const calculateIcoSchedule = (data) => {
    return makeRequest(axiosPrivate, calculationScheduleUrl, {
      method: "POST",
      data: data,
    });
  };

  const getEarliestInterestDate = (interestId) => {
    return makeRequest(axiosPrivate, earliestInterestDateUrl(interestId));
  };

  const createNewIco = async (data) => {

    const files = data.files;
    delete data.files;

    let results = await uploadFiles(files, {});
    let uploadedData = results["uploadedData"];
    let showWaring = results["showWaring"];
    data.files = uploadedData;

    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, createIcoUrl, {
        method: "POST",
        data: data,
      })
        .then((result) => {
          resolve(result);
          if (showWaring) {
            snackbarAlert.openWarningSnackbarAlert(
              t("snackbar_alert.occurred_warning_during_upload_file")
            );
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateIco = (icoId, data) => {
    return makeRequest(axiosPrivate, icoDetailsUrl(icoId), {
      method: "PATCH",
      data: data,
    });
  };

  const getInitConsolidationData = (data) => {
    return makeRequest(axiosPrivate, consolidationInitDataUrl, {
      method: "POST",
      data: data,
    });
  };

  const getDrafts = () => {
    return makeRequest(axiosPrivate, icoDraftsUrl);
  }

  const saveDraft = (data) => {
    return axiosPrivate.post(icoDraftsUrl, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  const getDraftDetails = (draftId) => {
    return makeRequest(axiosPrivate, draftDetailsUrl(draftId));
  };

  const updateDraft = (draftId, data) => {
    return makeRequest(axiosPrivate, draftDetailsUrl(draftId), {
      method: "PATCH",
      data: data,
    });
  };

  const getDraftSelectList = () => {
    return makeRequest(axiosPrivate, icoDraftSelectListUrl);
  };

  const getDraftFilteringData = () => {
    return makeRequest(
      axiosPrivate,
      icoDraftsFilteringDataUrl
    );
  }

  const getSchedulesTransferData = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(icoScheduleListDataUrl, searchParams)
    );
  }

  const getTransferData = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(icoTransferListDataUrl, searchParams)
    );
  }

  const getPaymentTransferData = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(icoPaymentListDataUrl, searchParams)
    );
  }
  const getPaymentFilteringData = () => {
    return makeRequest(
      axiosPrivate,
      icoPaymentFilteringDataUrl
    );
  }


  const getInitTransferTitle = (paymentData) => {
    return makeRequest(axiosPrivate, icoTransferTitleUrl, {
      method: "POST",
      data: paymentData,
    });
  };

  const getMinMaxTransferDate = (paymentIds) => {
    return makeRequest(axiosPrivate, minMaxTransferDateUrl, {
      method: "POST",
      data: paymentIds,
    });
  };

  const getCurrencyList = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(currencyUrl, searchParams)
    );
  }

  const getCurrencyRateList = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(currencyRateUrl, searchParams)
    );
  };

  const getCurrencySelectList = () => {
    return makeRequest(axiosPrivate, icoCurrencytSelectListUrl);
  };

  const getCurrencyDetails = (currencyId) => {
    return makeRequest(axiosPrivate, currencyDetailsUrl(currencyId));
  };


  const createCurrency = (data) => {
    return makeRequest(axiosPrivate, currencyUrl, {
      method: "POST",
      data: data,
    });
  };

  const updateCurrency = (currencyId, data) => {
    return makeRequest(axiosPrivate, currencyDetailsUrl(currencyId), {
      method: "PATCH",
      data: data,
    });
  };

  const getTransferShortDetails = (transferId) => {
    return makeRequest(axiosPrivate, transferShortDetailsUrl(transferId));
  };

  const createTransfer = (data) => {
    return makeRequest(axiosPrivate, icoTransferListDataUrl, {
      method: "POST",
      data: data,
    });
  };

  const updateTransfer = (transferId, data) => {
    return makeRequest(axiosPrivate, transferDetailsUrl(transferId), {
      method: "PATCH",
      data: data,
    });
  };

  const getIcoProductList = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(icoProductUrl, searchParams)
    );
  }

  const getIcoProductDetails = (productId) => {
    return makeRequest(axiosPrivate, icoProductDetailsUrl(productId));
  };

  const createIcoProduct = (data) => {
    return makeRequest(axiosPrivate, icoProductUrl, {
      method: "POST",
      data: data,
    });
  };

  const updateIcoProduct = (productId, data) => {
    return makeRequest(axiosPrivate, icoProductDetailsUrl(productId), {
      method: "PATCH",
      data: data,
    });
  };

  const getInterestList = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(interestUrl, searchParams)
    );
  };

  const getInterestDetails = (interestId) => {
    return makeRequest(axiosPrivate, interestDetailsUrl(interestId));
  };


  const createInterest = (data) => {
    return makeRequest(axiosPrivate, interestUrl, {
      method: "POST",
      data: data,
    });
  };

  const updateInterest = (interestId, data) => {
    return makeRequest(axiosPrivate, interestDetailsUrl(interestId), {
      method: "PATCH",
      data: data,
    });
  };

  const getInterestRateList = (searchParams = undefined) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(interestRateUrl, searchParams)
    );
  };


  const getInterestRateDetails = (interestRateId) => {
    return makeRequest(axiosPrivate, interestRateDetailsUrl(interestRateId));
  };
  const createInterestRate = (data) => {
    return makeRequest(axiosPrivate, interestRateUrl, {
      method: "POST",
      data: data,
    });
  };
  const updateInterestRate = (interestRateId, data) => {
    return makeRequest(axiosPrivate, interestRateDetailsUrl(interestRateId), {
      method: "PATCH",
      data: data,
    });
  };


  return {
    getIcoRegistryList,
    getIcoRegistryFlatList,
    getIcoAcceptList,
    getIcoDocsRegistryList,
    getIcoFilteringData,
    getIcoSelectData,
    getDefaultIcoProductData,
    getDefaultUsersForBorrowerAndLender,
    calculateIcoSchedule,
    getEarliestInterestDate,
    createNewIco,
    getRelatedIcos,
    getIcoDetails,
    updateIco,
    getInitConsolidationData,
    getParticipiants,
    getDrafts,
    saveDraft,
    getDraftDetails,
    updateDraft,
    getDraftSelectList,
    getIcoAgreementGenerateDetails,
    getDraftFilteringData,
    getSchedulesTransferData,
    getTransferData,
    getPaymentTransferData,
    getPaymentFilteringData,
    getInitTransferTitle,
    getMinMaxTransferDate,
    getCurrencyList,
    getCurrencySelectList,
    getCurrencyDetails,
    createCurrency,
    updateCurrency,
    getTransferShortDetails,
    createTransfer,
    updateTransfer,
    getIcoProductList,
    getIcoProductDetails,
    createIcoProduct,
    updateIcoProduct,
    getCurrencyRateList,
    getInterestList,
    getInterestDetails,
    createInterest,
    updateInterest,
    getInterestRateList,
    getInterestRateDetails,
    createInterestRate,
    updateInterestRate,

  };
}
