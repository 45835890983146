import { useTranslation } from "react-i18next";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import "./pricelist.css";
import NewPriceTable from "./newPriceTable";


export default function PriceListPage(props) {
    const { pageName } = props;
    const { t } = useTranslation();

    return (
        <NavigationDrawer pageName={pageName}>
            <div style={{ padding: "30px" }}>
                <br></br>
                <h3 className="priceh3">{t("page.price_list_page.price_list_effective_new")}</h3>
                <NewPriceTable/>

            </div>
        </NavigationDrawer>
    );
}
