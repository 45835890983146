import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import TrueFalseSelectField from "../../field/TrueFalseSelectField/trueFalseSelectField";
import DateField from "../../field/DateField/DateField";
import AutocompleteField from "../../field/AutocompleteField";
import FloatField from "../../base/FloatField";

import useTariffService from "../../../services/tariffsService";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import {
  getErrorMsg,
  isEmptyValue,
  isObjectEmpty,
} from "../../../helpers/methods";

import {
  DIALOG_PREVIEW_MODE,
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const REQUIRED_FIELDS = [
  "electricity_tariff_group",
  "et_start",
  "et_end",
  "et_active",
  "et_subscription_rate_fixed",
  "et_network_rate_fixed",
  "et_quality_rate_changeable",
  "et_transition_fee_rate_fixed",
  "et_oze_rate_changeable",
  "et_cogeneration_rate_changeable",
  "et_network_rate_changeable",
  "et_power_rate_changeable",
  "et_power_rate_lump_below_500",
  "et_power_rate_lump_between_500_1200",
  "et_power_rate_lump_between_1200_2800",
  "et_power_rate_lump_above_2800",
];

const FIELDS_TO_SAVE = [...REQUIRED_FIELDS];

const ElectricityTarrifDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const dialogTitle = useMemo(() => {
    return props.dialogMode === DIALOG_CREATE_MODE
      ? t("dialog.electricity_tariff.add_new_tariff")
      : props.dialogMode === DIALOG_EDIT_MODE
      ? t("dialog.electricity_tariff.edit_tariff")
      : t("dialog.electricity_tariff.tariff_details");
  }, [props.dialogMode]);

  const isReadOnly = useMemo(
    () => props.readOnly || props.dialogMode === DIALOG_PREVIEW_MODE,
    [props.readOnly, props.dialogMode]
  );

  const fieldsToSave = useMemo(
    () => props.fieldsToSave || FIELDS_TO_SAVE,
    [props.fieldsToSave]
  );

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
    onChangeDate,
  } = useForm();

  const {
    getElectricityTariffDetailsData,
    createElectricityTariff,
    updateElectricityTariff,
    getElectricityTariffFilteringData,
  } = useTariffService();

  const initialData = useAsync(() => {
    if (props.dialogMode === DIALOG_CREATE_MODE) {
      return Promise.resolve({ et_active: true });
    }

    return getElectricityTariffDetailsData(props.tariffId);
  }, [props.tariffId, props.dialogMode]);

  useEffect(() => {
    setFormValue(initialData.value);
  }, [initialData.loading]);

  const prepareDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (fieldsToSave.includes(key)) {
        dataToSend[key] = typeof value === "object" ? value.id : value;
      }
    }

    return dataToSend;
  };
  const filteringData = useAsync(getElectricityTariffFilteringData);
  const updateTariffGroupFn = useAsyncFn(updateElectricityTariff);
  const addTariffGroupFn = useAsyncFn(createElectricityTariff);
  const submitFn = useMemo(
    () =>
      props.dialogMode === DIALOG_CREATE_MODE
        ? addTariffGroupFn
        : updateTariffGroupFn,
    [props.dialogMode]
  );

  const handleSubmit = (dataToSend) => {
    submitFn
      .execute(dataToSend, props.tariffId)
      .then((res) => {
        if (props.dialogMode === DIALOG_CREATE_MODE) {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.tariff_created")
          );
        } else {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.tariff_updated")
          );
        }
        if (props.onSubmitCallback) {
          props.onSubmitCallback();
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isFormValid =
    formValue &&
    REQUIRED_FIELDS.every((field) => !isEmptyValue(formValue[field]));

  const isLoading = formValue === undefined || filteringData.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={2}
      >
        <Grid item xs={props.dialogMode !== DIALOG_CREATE_MODE ? 6 : 12}>
          <AutocompleteField
            name="electricity_tariff_group"
            isObjectOption={true}
            value={formValue.electricity_tariff_group}
            options={filteringData.value.electricity_tariff_group}
            optionLabelKey={"etg_name"}
            label={t("dialog.electricity_tariff.electricity_tariff_group")}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            multiple={false}
          />
        </Grid>
        {props.dialogMode !== DIALOG_CREATE_MODE && (
          <Grid item xs={6}>
            <TrueFalseSelectField
              name={"et_active"}
              label={t("dialog.electricity_tariff.et_active")}
              value={formValue.et_active}
              onChange={onChange}
              addEmptyOptions={false}
              valuesAsBool
              required
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <DateField
            name={"et_start"}
            label={t("dialog.electricity_tariff.et_start")}
            value={formValue.et_start}
            onChange={onChangeDate}
            showClearIcon={false}
            maxDate={formValue.et_stop ? formValue.et_stop : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <DateField
            name={"et_end"}
            label={t("dialog.electricity_tariff.et_end")}
            value={formValue.et_end}
            onChange={onChangeDate}
            showClearIcon={false}
            minDate={formValue.et_start ? formValue.et_start : undefined}
          />
        </Grid>

        <Grid item xs={6}>
          <FloatField
            name={"et_subscription_rate_fixed"}
            label={t("dialog.electricity_tariff.et_subscription_rate_fixed")}
            value={formValue.et_subscription_rate_fixed}
            onChange={onChange}
            suffix={"zł/mc"}
          />
        </Grid>
        <Grid item xs={6}>
          <FloatField
            name={"et_network_rate_fixed"}
            label={t("dialog.electricity_tariff.et_network_rate_fixed")}
            value={formValue.et_network_rate_fixed}
            onChange={onChange}
            suffix={"zł/kW/m-c"}
          />
        </Grid>
        <Grid item xs={6}>
          <FloatField
            name={"et_transition_fee_rate_fixed"}
            label={t("dialog.electricity_tariff.et_transition_fee_rate_fixed")}
            value={formValue.et_transition_fee_rate_fixed}
            onChange={onChange}
            suffix={"zł/kW/m-c"}
          />
        </Grid>

        <Grid item xs={6}>
          <FloatField
            name={"et_quality_rate_changeable"}
            label={t("dialog.electricity_tariff.et_quality_rate_changeable")}
            value={formValue.et_quality_rate_changeable}
            onChange={onChange}
            suffix={"zł/kWh"}
          />
        </Grid>

        <Grid item xs={6}>
          <FloatField
            name={"et_oze_rate_changeable"}
            label={t("dialog.electricity_tariff.et_oze_rate_changeable")}
            value={formValue.et_oze_rate_changeable}
            onChange={onChange}
            suffix={"zł/MWh"}
          />
        </Grid>
        <Grid item xs={6}>
          <FloatField
            name={"et_cogeneration_rate_changeable"}
            label={t(
              "dialog.electricity_tariff.et_cogeneration_rate_changeable"
            )}
            value={formValue.et_cogeneration_rate_changeable}
            onChange={onChange}
            suffix={"zł/MWh"}
          />
        </Grid>
        <Grid item xs={6}>
          <FloatField
            name={"et_network_rate_changeable"}
            label={t("dialog.electricity_tariff.et_network_rate_changeable")}
            value={formValue.et_network_rate_changeable}
            onChange={onChange}
            suffix={"zł/kWh"}
          />
        </Grid>
        <Grid item xs={6}>
          <FloatField
            name={"et_power_rate_changeable"}
            label={t("dialog.electricity_tariff.et_power_rate_changeable")}
            value={formValue.et_power_rate_changeable}
            onChange={onChange}
            suffix={"zł/kWh"}
          />
        </Grid>
        <Grid item xs={6.1}>
          <FloatField
            name={"et_power_rate_lump_below_500"}
            label={t("dialog.electricity_tariff.et_power_rate_lump_below_500")}
            value={formValue.et_power_rate_lump_below_500}
            onChange={onChange}
            suffix={"zł/kWh"}
          />
        </Grid>
        <Grid item xs={6.1}>
          <FloatField
            name={"et_power_rate_lump_between_500_1200"}
            label={t(
              "dialog.electricity_tariff.et_power_rate_lump_between_500_1200"
            )}
            value={formValue.et_power_rate_lump_between_500_1200}
            onChange={onChange}
            suffix={"zł/kWh"}
          />
        </Grid>
        <Grid item xs={6.1}>
          <FloatField
            name={"et_power_rate_lump_between_1200_2800"}
            label={t(
              "dialog.electricity_tariff.et_power_rate_lump_between_1200_2800"
            )}
            value={formValue.et_power_rate_lump_between_1200_2800}
            onChange={onChange}
            suffix={"zł/kWh"}
          />
        </Grid>
        <Grid item xs={6.1}>
          <FloatField
            name={"et_power_rate_lump_above_2800"}
            label={t("dialog.electricity_tariff.et_power_rate_lump_above_2800")}
            value={formValue.et_power_rate_lump_above_2800}
            onChange={onChange}
            suffix={"zł/kWh"}
          />
        </Grid>
        {!isReadOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={addTariffGroupFn.loading || updateTariffGroupFn.loading}
              disabled={!isFormValid}
              onClick={() => handleSubmit(prepareDataToSend())}
            >
              {t("save")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={false}
      title={dialogTitle}
      maxWidth="md"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

ElectricityTarrifDialog.propTypes = {
  dialogMode: PropTypes.oneOf([
    DIALOG_CREATE_MODE,
    DIALOG_EDIT_MODE,
    DIALOG_PREVIEW_MODE,
  ]),
  readOnly: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  fieldsToSave: PropTypes.array,
};

ElectricityTarrifDialog.defaultProps = {
  open: false,
};

export default ElectricityTarrifDialog;
