import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Grid } from "@mui/material";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import { useAsync } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import AgreementTable from "../../components/table/AgreementTable";
import { NAVIGATION_DRAWER_TYPE_ICO } from "../../components/drawer/NavigationDrawer/NavigationDrawer";
import useIcoService from "../../services/icoService";
import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";
import { FUTURE_TRANSFERS_TABLE_FIELDS_CONFIG, FUTURE_TRANSFERS_TABLE_NAME, NEXT_TRANSFERS_TABLE_FIELDS_CONFIG, NEXT_TRANSFERS_TABLE_NAME, REALIZED_TRANSFERS_TABLE_FIELDS_CONFIG, REALIZED_TRANSFERS_TABLE_NAME } from "./PageTablesConfig";
import BoxWithTypography from "../../components/box/BoxWithTypography/BoxWithTypography";
import { useTranslation } from "react-i18next";
import BaseTable from "../../components/base/BaseTable/BaseTable";
import TableService from "../../services/tableService";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import FilterTable from "../../components/table/FilterTable";
import useDialog from "../../hooks/useDialog";
import CreateTransferDialog from "../../components/dialog/CreateTransferDialog/CreateTransferDialog";
import useDialogWithId from "../../hooks/useDialogWithId";
import ChangePaidTransferDateDialog from "../../components/dialog/ChangePaidTransferDateDialog/ChangePaidTransferDateDialog";
import { exportRealizedTransferToFileUrl, icoPaymentToFileUrl, icoScheduleToFileUrl } from "../../helpers/apiUrls";

const TABLE_CONFIGS = [
  { name: REALIZED_TRANSFERS_TABLE_NAME, config: REALIZED_TRANSFERS_TABLE_FIELDS_CONFIG },
  { name: NEXT_TRANSFERS_TABLE_NAME, config: NEXT_TRANSFERS_TABLE_FIELDS_CONFIG },
  { name: FUTURE_TRANSFERS_TABLE_NAME, config: FUTURE_TRANSFERS_TABLE_FIELDS_CONFIG },
];

const REALIZED_TRANSFERS_TABLE_FILTER_PREFIX = "realized_transfers";
const NEXT_TRANSFERS_TABLE_FILTER_PREFIX = "next_transfers";
const FUTURE_TRANSFERS_TABLE_FILTER_PREFIX = "future_transfers";

export default function IcoTransferPage(props) {
  const { pageName } = props;
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setNewItemSearchParamsIfAreChanged } = useFilterSearchParams(searchParams);
  const [checkedAll, setCheckedAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState(false);

  const [realizedTransferLocal, setRealizedTransferLocal] = useState();
  const [nextTransfersLocal, setNextTransfersLocal] = useState();
  const [futureTransfersLocal, setFutureTransfersLocal] = useState();
  const [countRealizedTransfersRecord, setCountRealizedTransfersRecord] = useState();
  const [countNextTransfersRecord, setCountNextTransfersRecord] = useState();
  const [countFutureTransfersRecord, setCountFutureTransfersRecord] = useState();

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState(undefined);
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState(undefined);

  const [realizedTransfersTableSearchParams, setRealizedTransfersTableSearchParams] = useState({});
  const [nextTransfresTableSearchParams, setNextTransfresTableSearchParams] = useState({});
  const [futureTransfresTableSearchParams, setFutureTransfresTableSearchParams] = useState({});

  const [enabledGenerateTransferButton, setEnabledGenerateTransferButton] = useState(false);

  // const [hasEditPermission] = useCheckPermission(EDIT_AGREEMENTS_PERMISSION)
  const [hasEditPermission] = [true]

  const [
    openGenerateTransferDialog,
    onOpenGenerateTransferDialog,
    onCloseGenerateTransferDialog,
  ] = useDialog();

  const [
    openTransferDatePaidDialog,
    transferId,
    onOpenTransferDatePaidDialog,
    onCloseTransferDatePaidDialog,
  ] = useDialogWithId();

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      REALIZED_TRANSFERS_TABLE_FILTER_PREFIX,
      realizedTransfersTableSearchParams,
      setRealizedTransfersTableSearchParams
    );
    setNewItemSearchParamsIfAreChanged(
      NEXT_TRANSFERS_TABLE_FILTER_PREFIX,
      nextTransfresTableSearchParams,
      setNextTransfresTableSearchParams
    );
    setNewItemSearchParamsIfAreChanged(
      FUTURE_TRANSFERS_TABLE_FILTER_PREFIX,
      futureTransfresTableSearchParams,
      setFutureTransfresTableSearchParams
    );
  }, [searchParams]);

  const {
    page: realizedTranfersPage,
    pageSize: realizedTranferPageSize,
    handleChangePageWithSearchParams: handleChangeRealizedTranferPage,
    handleChangePageSizeWithSearchParams: handleChangeRealizedTranferPageSize,
    resetRealizedTranferPageNumber,
  } = usePaginationWithSearchParams(REALIZED_TRANSFERS_TABLE_FILTER_PREFIX);

  const {
    page: nextTranferPage,
    pageSize: nextTranferPageSize,
    handleChangePageWithSearchParams: handleChangeNextTranferPage,
    handleChangePageSizeWithSearchParams: handleChangeNextTranferPageSize,
    resetNextTranferPageNumber,
  } = usePaginationWithSearchParams(NEXT_TRANSFERS_TABLE_FILTER_PREFIX);

  const {
    page: futureTranfersPage,
    pageSize: futureTranferPageSize,
    handleChangePageWithSearchParams: handleChangeFutureTranferPage,
    handleChangePageSizeWithSearchParams: handleChangeFutureTranferPageSize,
    resetFutureTranferPageNumber,
  } = usePaginationWithSearchParams(FUTURE_TRANSFERS_TABLE_FILTER_PREFIX);

  const {
    getSchedulesTransferData,
    getTransferData,
    getPaymentTransferData,
    getPaymentFilteringData } = useIcoService();


  const paymentFilteringData = useAsync(getPaymentFilteringData);

  const transfersData = useAsync(
    () => getTransferData(realizedTransfersTableSearchParams),
    [realizedTransfersTableSearchParams]
  );

  const paymentsData = useAsync(
    () => getPaymentTransferData(nextTransfresTableSearchParams),
    [nextTransfresTableSearchParams]
  );

  const schedulesData = useAsync(
    () => getSchedulesTransferData(futureTransfresTableSearchParams),
    [futureTransfresTableSearchParams]
  );


  useEffect(() => {
    if (transfersData.loading) {
      return;
    }
    setRealizedTransferLocal(transfersData.value.results)
    setCountRealizedTransfersRecord(transfersData.value.count)
  }, [transfersData.loading]);

  useEffect(() => {
    if (paymentsData.loading) {
      return;
    }
    setNextTransfersLocal(paymentsData.value.results);
    setCountNextTransfersRecord(paymentsData.value.count);
  }, [paymentsData.loading]);

  useEffect(() => {
    if (schedulesData.loading) {
      return;
    }
    setFutureTransfersLocal(schedulesData.value.results);
    setCountFutureTransfersRecord(schedulesData.value.count);
  }, [schedulesData.loading]);

  const onRefetchTables = () => {
    transfersData.refetch()
    paymentsData.refetch()
    schedulesData.refetch()
  }

  useEffect(() => {
    let selectedRows = nextTransfersLocal ? nextTransfersLocal?.filter((payment) => payment.selected) : []
    setEnabledGenerateTransferButton(selectedRows.every(payment =>
      payment.icopayment_payer_id === selectedRows[0].icopayment_payer_id && payment.icopayment_receiver_id === selectedRows[0].icopayment_receiver_id
      && payment.currency_id === selectedRows[0].currency_id
    ) && selectedRows.length > 0)

  }, [nextTransfersLocal]);


  useEffect(() => {
    if (enabledGenerateTransferButton && nextTransfersLocal) {
      setSelectedRows(nextTransfersLocal?.filter((payment) => payment.selected))
    } else {
      setSelectedRows([])
    }
  }, [enabledGenerateTransferButton, nextTransfersLocal]);

  const onClickSetPaidDate = useCallback((e, id) => {
    e.stopPropagation();
    onOpenTransferDatePaidDialog(id)
  }, [])


  const handleClickCheckAll = useCallback(
    (value) => {
      let currValue = !checkedAll;
      setCheckedAll(currValue);

      setNextTransfersLocal((prev) => {
        return prev.map((rowData) => ({
          ...rowData,
          selected: currValue,
        }));
      });
    },
    [checkedAll]
  );

  const handleClickCheck = useCallback((id) => {
    setNextTransfersLocal((prevData) => {
      let selectedRow = { ...prevData.find((rowData) => rowData.id === id) };
      selectedRow.selected = !selectedRow.selected;
      return prevData.map((rowData) => {
        if (rowData.id === id) return selectedRow;
        return rowData;
      });
    });
  }, []);

  const realizedTransfersTableData = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        REALIZED_TRANSFERS_TABLE_FIELDS_CONFIG,
        realizedTransferLocal ? realizedTransferLocal : [],
        hiddenColumnsForTables ? hiddenColumnsForTables[REALIZED_TRANSFERS_TABLE_NAME] : [],
        columnsOrdersForTables ? columnsOrdersForTables[REALIZED_TRANSFERS_TABLE_NAME] : undefined,
        onClickSetPaidDate,
        hasEditPermission
      ),
    [REALIZED_TRANSFERS_TABLE_FIELDS_CONFIG,
      realizedTransferLocal,
      hiddenColumnsForTables,
      columnsOrdersForTables]
  );


  const realizedTransferExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      REALIZED_TRANSFERS_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables ? hiddenColumnsForTables[REALIZED_TRANSFERS_TABLE_NAME] : [],
      columnsOrdersForTables ? columnsOrdersForTables[REALIZED_TRANSFERS_TABLE_NAME] : undefined,
    );
  }, [REALIZED_TRANSFERS_TABLE_FIELDS_CONFIG,
    hiddenColumnsForTables,
    columnsOrdersForTables]);



  const nextTransfersTableData = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        NEXT_TRANSFERS_TABLE_FIELDS_CONFIG,
        nextTransfersLocal ? nextTransfersLocal : [],
        hiddenColumnsForTables ? hiddenColumnsForTables[NEXT_TRANSFERS_TABLE_NAME] : [],
        columnsOrdersForTables ? columnsOrdersForTables[NEXT_TRANSFERS_TABLE_NAME] : undefined
      ),
    [NEXT_TRANSFERS_TABLE_FIELDS_CONFIG,
      nextTransfersLocal,
      hiddenColumnsForTables,
      columnsOrdersForTables]
  );

  const nextTransferExportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      NEXT_TRANSFERS_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables ? hiddenColumnsForTables[NEXT_TRANSFERS_TABLE_NAME] : [],
      columnsOrdersForTables ? columnsOrdersForTables[NEXT_TRANSFERS_TABLE_NAME] : undefined,
    );
  }, [   NEXT_TRANSFERS_TABLE_FIELDS_CONFIG,
    hiddenColumnsForTables,
    columnsOrdersForTables]);

  const futureTransfersTableData = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        FUTURE_TRANSFERS_TABLE_FIELDS_CONFIG,
        futureTransfersLocal ? futureTransfersLocal : [],
        hiddenColumnsForTables ? hiddenColumnsForTables[FUTURE_TRANSFERS_TABLE_NAME] : [],
        columnsOrdersForTables ? columnsOrdersForTables[FUTURE_TRANSFERS_TABLE_NAME] : undefined
      ),
    [FUTURE_TRANSFERS_TABLE_FIELDS_CONFIG,
      futureTransfersLocal,
      hiddenColumnsForTables,
      columnsOrdersForTables]
  );

  const futureTransferExportHeaders  = useMemo(() => {
    return TableService.getTableExportHeaders(
      FUTURE_TRANSFERS_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables ? hiddenColumnsForTables[FUTURE_TRANSFERS_TABLE_NAME] : [],
      columnsOrdersForTables ? columnsOrdersForTables[FUTURE_TRANSFERS_TABLE_NAME] : undefined,
    );
  }, [   FUTURE_TRANSFERS_TABLE_FIELDS_CONFIG,
    hiddenColumnsForTables,
    columnsOrdersForTables]);

  return (
    <NavigationDrawer
      drawerType={NAVIGATION_DRAWER_TYPE_ICO}
      pageName={pageName}
    >
      <DefaultPageWrapper titleKey={pageName}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12} marginY={1}>
            <UniversalToolBarWithDialogs
              pageName={pageName}
              tableConfig={TABLE_CONFIGS}
              filteringData={paymentFilteringData ? paymentFilteringData : []}
              refreshTable={onRefetchTables}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showMassActionButton={false}
              showOpenCloseFilterButton={false}
              showCleanfiltersButton={true}
              showMyFiltersButton={false}
              showSearchButton={false}
              itemType={"ico"}
              style={{ marginBlock: "5px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <BoxWithTypography
              style={{ padding: "10px" }}
              label={t("page.ico_transfers_page.realized_transfers")}
              component={"div"}
            >
              <LoaderWrapper showLoader={paymentFilteringData.loading || realizedTransferLocal === undefined}>
                <FilterTable
                  data={realizedTransfersTableData}
                  onClickRow={() => undefined}
                  countRecords={countRealizedTransfersRecord}
                  resetPageNumber={resetRealizedTranferPageNumber}
                  page={realizedTranfersPage}
                  onPageChange={handleChangeRealizedTranferPage}
                  rowsPerPage={realizedTranferPageSize}
                  onRowsPerPageChange={handleChangeRealizedTranferPageSize}
                  style={{ maxHeight: "40vh" }}
                  showCleanFilterIcon={true}
                  showContextMenu={false}
                  showExportToFileButton={true}
                  exportToFileUrl={exportRealizedTransferToFileUrl}
                  exportToFileSearchParams={realizedTransfersTableSearchParams}
                  exportToFileHeaders={realizedTransferExportHeaders}
                  exportToFileFileName={`${t(
                    "page.ico_transfers_page.realized_transfers"
                  ).replace(" ", "_")}.xlsx`}
                  filterPrefix={REALIZED_TRANSFERS_TABLE_FILTER_PREFIX}
                  headersConfig={TableService.getHeadersConfigForCollapsibleTable(
                    REALIZED_TRANSFERS_TABLE_FIELDS_CONFIG,
                    paymentFilteringData ? paymentFilteringData : [],
                    hiddenColumnsForTables ? hiddenColumnsForTables[REALIZED_TRANSFERS_TABLE_NAME] : [],
                    columnsOrdersForTables ? columnsOrdersForTables[REALIZED_TRANSFERS_TABLE_NAME] : undefined
                  )}
                  showDetailsIcon={false}
                  showCounterRecords={false}
                  showCheckbox={false}
                />
              </LoaderWrapper>
            </BoxWithTypography>
          </Grid>
          <Grid item xs={12}>
            <BoxWithTypography
              style={{ padding: "10px" }}
              label={t("page.ico_transfers_page.next_transfers")}
              component={"div"}
            >
              <LoaderWrapper showLoader={paymentFilteringData.loading || nextTransfersLocal === undefined}>
                <FilterTable
                  data={nextTransfersTableData}
                  onClickRow={() => undefined}
                  countRecords={countNextTransfersRecord}
                  resetPageNumber={resetNextTranferPageNumber}
                  page={nextTranferPage}
                  onPageChange={handleChangeNextTranferPage}
                  rowsPerPage={nextTranferPageSize}
                  onRowsPerPageChange={handleChangeNextTranferPageSize}
                  style={{ maxHeight: "40vh" }}
                  showCleanFilterIcon={true}
                  showContextMenu={false}
                  showExportToFileButton={true}
                  exportToFileUrl={icoPaymentToFileUrl}
                  exportToFileSearchParams={nextTransfresTableSearchParams}
                  exportToFileHeaders={nextTransferExportHeaders}
                  exportToFileFileName={`${t(
                    "page.ico_transfers_page.next_transfers"
                  ).replace(" ", "_")}.xlsx`}
                  filterPrefix={NEXT_TRANSFERS_TABLE_FILTER_PREFIX}
                  headersConfig={TableService.getHeadersConfigForCollapsibleTable(
                    NEXT_TRANSFERS_TABLE_FIELDS_CONFIG,
                    paymentFilteringData ? paymentFilteringData : [],
                    hiddenColumnsForTables ? hiddenColumnsForTables[NEXT_TRANSFERS_TABLE_NAME] : [],
                    columnsOrdersForTables ? columnsOrdersForTables[NEXT_TRANSFERS_TABLE_NAME] : undefined
                  )}
                  showDetailsIcon={false}
                  showCounterRecords={false}
                  showCheckbox={true}
                  checkedAll={checkedAll}
                  onClickCheckAll={handleClickCheckAll}
                  onClickCheck={handleClickCheck}
                />
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  rowSpacing={1}
                >
                  <Button
                    variant="contained"
                    onClick={() => onOpenGenerateTransferDialog()}
                    disabled={!enabledGenerateTransferButton}
                  >
                    {t("page.ico_transfers_page.generate_transfer")}
                  </Button>
                </Grid>
              </LoaderWrapper>
            </BoxWithTypography>
          </Grid>
          <Grid item xs={12}>
            <BoxWithTypography
              style={{ padding: "10px" }}
              label={t("page.ico_transfers_page.future_transfers")}
              component={"div"}
            >
              <LoaderWrapper showLoader={paymentFilteringData.loading || futureTransfersLocal === undefined}>
                <FilterTable
                  data={futureTransfersTableData}
                  onClickRow={() => undefined}
                  countRecords={countFutureTransfersRecord}
                  resetPageNumber={resetFutureTranferPageNumber}
                  page={futureTranfersPage}
                  onPageChange={handleChangeFutureTranferPage}
                  rowsPerPage={futureTranferPageSize}
                  onRowsPerPageChange={handleChangeFutureTranferPageSize}
                  style={{ maxHeight: "40vh" }}
                  showCleanFilterIcon={true}
                  showContextMenu={false}
                  showExportToFileButton={true}
                  exportToFileUrl={icoScheduleToFileUrl}
                  exportToFileSearchParams={futureTransfresTableSearchParams}
                  exportToFileHeaders={futureTransferExportHeaders}
                  exportToFileFileName={`${t(
                    "page.ico_transfers_page.future_transfers"
                  ).replace(" ", "_")}.xlsx`}
                  filterPrefix={FUTURE_TRANSFERS_TABLE_FILTER_PREFIX}
                  headersConfig={TableService.getHeadersConfigForCollapsibleTable(
                    FUTURE_TRANSFERS_TABLE_FIELDS_CONFIG,
                    paymentFilteringData ? paymentFilteringData : [],
                    hiddenColumnsForTables ? hiddenColumnsForTables[FUTURE_TRANSFERS_TABLE_NAME] : [],
                    columnsOrdersForTables ? columnsOrdersForTables[FUTURE_TRANSFERS_TABLE_NAME] : undefined
                  )}
                  showDetailsIcon={false}
                  showCounterRecords={false}
                  showCheckbox={false}
                />
              </LoaderWrapper>
            </BoxWithTypography>
          </Grid>
        </Grid>
        {openGenerateTransferDialog &&
          <CreateTransferDialog
            open={openGenerateTransferDialog}
            onClose={onCloseGenerateTransferDialog}
            onRefetch={onRefetchTables}
            payments={selectedRows}
          />}
        {openTransferDatePaidDialog &&
          <ChangePaidTransferDateDialog
            open={openTransferDatePaidDialog}
            onClose={onCloseTransferDatePaidDialog}
            onRefetch={onRefetchTables}
            transferId={transferId}
          />}
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
