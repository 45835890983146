import React, { useRef, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useAsync } from "../../../hooks/useAsync";
import useOrderService from "../../../services/orderService";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import CollapsibleTable from "../CollapsibleTable";
import TableService from "../../../services/tableService";
import OrderFilterTable from "../OrderFilterTable";

import AddButton from "../../button/AddButton";

import CreateOrderDialog from "../../dialog/CreateOrderDialog";
import MultipleLocalizationDialog from "../../dialog/MutlipleLocalizationDialog";
import useDialog from "../../../hooks/useDialog";

import {
  TICKET_TABLE_FIELDS_CONFIG,
  TICKET_TABLE_NAME,
  ORDER_TABLE_FIELDS_CONFIG,
  ORDER_TABLE_NAME,
} from "./TableConfigs";

const InnerOrderTable = React.memo(
  ({
    id,
    updatedInnerElementData,
    hiddenColumns,
    columnsOrders,
    onClickInnerTableRow,
    selectedOrderId,
    showChangesCircle,
    isClosed,
  }) => {
    const [orderDataLocal, setOrderDataLocal] = useState(undefined);
    const { getOrderWithoutPaginationData } = useOrderService();

    const orderData = useAsync(
      () => getOrderWithoutPaginationData(`ticket=${id}`),
      [updatedInnerElementData, id]
    );

    useEffect(() => {
      if (!orderData.loading) {
        setOrderDataLocal(orderData.value);
      }
    }, [orderData.loading]);

    const [
      openCreateOrderDialog,
      onOpenCreateOrderDialog,
      onCloseCreateOrderDialog,
    ] = useDialog();

    const [
      openMultipleLocalizationDialog,
      onOpenMultipleLocalizationDialog,
      onCloseMultipleLocalizationDialog,
    ] = useDialog();

    const clickedOrderMultipleLocalization = useRef();
    const handleOpenMultipleLocalizationDialog = useCallback(
      (e, orderId) => {
        e.stopPropagation();
        clickedOrderMultipleLocalization.current = orderData.value.find(
          (order) => order.id === orderId
        ).locations;
        onOpenMultipleLocalizationDialog();
      },
      [onOpenMultipleLocalizationDialog, orderData.value]
    );

    const handleCloseMultipleLocalizationDialog = () => {
      clickedOrderMultipleLocalization.current = null;
      onCloseMultipleLocalizationDialog();
    };

    const handleCloseCreateOrderDialog = (refresh) => {
      if (refresh) orderData.refetch();
      onCloseCreateOrderDialog();
    };

    // const [openedOrderId, setOpenedOrderId] = useState();
    const handleClickRow = (orderId) => {
      // setOpenedOrderId(orderId);
      let ticketId = orderData.value.find(
        (rowData) => rowData.id === orderId
      ).ticket_id;
      onClickInnerTableRow(orderId, ticketId);
      setOrderDataLocal((prevData) => {
        return prevData.map((rowData) => {
          if (rowData.id === orderId)
            return {
              ...rowData,
              has_changes: false,
            };
          return rowData;
        });
      });
    };

    return (
      <LoaderWrapper showLoader={orderData.loading}>
        <>
          {orderDataLocal && (
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              {!isClosed && <AddButton onClick={onOpenCreateOrderDialog} />}
              <OrderFilterTable
                showChangesCircle={showChangesCircle}
                showChangesCircleFilter={false}
                data={orderDataLocal}
                showCheckbox={false}
                showCleanFilterIcon={false}
                onClickRow={handleClickRow}
                selectedOrderId={selectedOrderId}
                hiddenColumns={hiddenColumns}
                columnsOrders={columnsOrders}
                tableConfig={ORDER_TABLE_FIELDS_CONFIG}
                onClickMultipleLocationAlert={
                  handleOpenMultipleLocalizationDialog
                }
                showFilters={false}
                withPagination={false}
                showCounterRecords={false}
              />
              {openCreateOrderDialog && (
                <CreateOrderDialog
                  open={openCreateOrderDialog}
                  onClose={handleCloseCreateOrderDialog}
                  ticketId={id}
                />
              )}
              {openMultipleLocalizationDialog &&
                clickedOrderMultipleLocalization.current && (
                  <MultipleLocalizationDialog
                    open={openMultipleLocalizationDialog}
                    onClose={handleCloseMultipleLocalizationDialog}
                    localizationData={clickedOrderMultipleLocalization.current}
                    itemType={"order"}
                  />
                )}
            </div>
          )}
        </>
      </LoaderWrapper>
    );
  }
);

const 
TicketCollapsibleTable = React.memo((props) => {
  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        TICKET_TABLE_FIELDS_CONFIG,
        props.data,
        props.hiddenColumns[TICKET_TABLE_NAME],
        props.columnsOrders[TICKET_TABLE_NAME],
        props.onClickMultipleLocationAlert
      ),
    [
      props.data,
      props.hiddenColumns[TICKET_TABLE_NAME],
      props.columnsOrders[TICKET_TABLE_NAME],
    ]
  );
  return (
    <CollapsibleTable
      showChangesCircle={props.showChangesCircle}
      checkedAll={props.checkedAll}
      showDetailsIcon={props.showDetailsIcon}
      showCleanFilterIcon={props.showCleanFilterIcon}
      itemDetailsLinkPrefix={"ticket_details"}
      onClickRow={props.onClickRow}
      onClickCheck={props.onClickCheck}
      onClickCheckAll={props.onClickCheckAll}
      data={data}
      filteringData={props.filteringData}
      countRecords={props.countRecords}
      page={props.page}
      onPageChange={props.onPageChange}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      rowsPerPageOptions={props.rowsPerPageOptions}
      resetPageNumber={props.resetPageNumber}
      headersConfig={TableService.getHeadersConfigForCollapsibleTable(
        TICKET_TABLE_FIELDS_CONFIG,
        props.filteringData,
        props.hiddenColumns[TICKET_TABLE_NAME],
        props.columnsOrders[TICKET_TABLE_NAME]
      )}
      CollapsibleElement={
        <InnerOrderTable
          hiddenColumns={props.hiddenColumns[ORDER_TABLE_NAME]}
          columnsOrders={props.columnsOrders[ORDER_TABLE_NAME]}
          onClickInnerTableRow={props.onClickInnerTableRow}
          selectedOrderId={props.selectedOrderId}
          showChangesCircle={props.showChangesCircle}
        />
      }
      selectedItemId={props.selectedTicketId}
      isSelectedOuterItem={props.isSelectedTicket}
      selectedInnerItemId={props.selectedOrderId}
      style={props.style}
      filterPrefix={props.filterPrefix}
      showExportToFileButton={props.showExportToFileButton}
      exportToFileUrl={props.exportToFileUrl}
      exportToFileSearchParams={props.exportToFileSearchParams}
      exportToFileHeaders={props.exportToFileHeaders}
      exportToFileFileName={props.exportToFileFileName}
      isLoading={props.isLoading}
    />
  );
});

TicketCollapsibleTable.propTypes = {
  showDetailsIcon: PropTypes.bool,
  checkedAll: PropTypes.bool,
  onClickCheck: PropTypes.func,
  onClickCheckAll: PropTypes.func,
  onClickRow: PropTypes.func,
  showCleanFilterIcon: PropTypes.bool,
  is_closed: PropTypes.bool,
  data: PropTypes.array,
  filteringData: PropTypes.object,
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  resetPageNumber: PropTypes.func,
  hiddenColumns: PropTypes.object,
  columnsOrders: PropTypes.object,
  isSelectedTicket: PropTypes.bool,
  selectedTicketId: PropTypes.string,
  selectedOrderId: PropTypes.string,
  style: PropTypes.object,
  filterPrefix: PropTypes.string,
  onClickInnerTableRow: PropTypes.func,
  showChangesCircle: PropTypes.func,
  showExportToFileButton: PropTypes.bool,
  exportToFileUrl: PropTypes.string,
  exportToFileSearchParams: PropTypes.object,
  exportToFileHeaders: PropTypes.array,
  exportToFileFileName: PropTypes.string,
};

TicketCollapsibleTable.defaultProps = {
  hiddenColumns: {},
  columnsOrders: {},
  isSelectedTicket: true,
  showDetailsIcon: false,
  showCleanFilterIcon: false,
  showChangesCircle: false,
};

export default TicketCollapsibleTable;
