import { useState, useEffect, useRef } from "react";

import { useSearchParams } from "react-router-dom";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import BasicDialog from "../../base/BasicDialog";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useAsync } from "../../../hooks/useAsync";

import TariffTable from "../../table/TariffTable";

import ElectricityTarrifGroupDialog from "../ElectricityTariffGroupDialog";

import useDialogWithId from "../../../hooks/useDialogWithId";

import useTariffService from "../../../services/tariffsService";

import { isSearchParamsObjectEmpty } from "../../../helpers/methods";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import {
  centerVericalAlignIconStyle,
  overflowButtonStyle,
} from "../../../helpers/styles";

import {
  DIALOG_CREATE_MODE,
  DIALOG_EDIT_MODE,
} from "../../../helpers/constants";

import {
  ELECTRICITY_TARIFF_GROUP_TABLE_CONFIG,
  ELECTRICITY_TARIFF_GROUP_TABLE_PREFIX,
} from "./TableConfig";

import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

const ElectricityTariffManagementDialog = (props) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
  } = useFilterSearchParams(searchParams);

  const [filterSearchParams, setFilterSearchParams] = useState(
    getSearchParamsByFilterPrefix(ELECTRICITY_TARIFF_GROUP_TABLE_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      ELECTRICITY_TARIFF_GROUP_TABLE_PREFIX,
      filterSearchParams,
      setFilterSearchParams
    );
  }, [searchParams, ELECTRICITY_TARIFF_GROUP_TABLE_PREFIX]);

  const {
    getElectricityTariffGroupData,
    getElectricityTariffGroupFilteringData,
  } = useTariffService();

  const filteringData = useAsync(getElectricityTariffGroupFilteringData);
  const backendData = useAsync(
    () => getElectricityTariffGroupData(filterSearchParams),
    [filterSearchParams]
  );
  const [dataLocaly, setDataLocaly] = useState();

  useEffect(() => {
    if (backendData.loading) {
      return;
    }
    setDataLocaly(backendData.value);
  }, [backendData.loading]);

  const [existsChanges, setExistsChanges] = useState(false);
  const [
    openTariffGroupDialog,
    tariffGroupId,
    onOpenTariffGroupDialog,
    onCloseTariffGroupDialog,
  ] = useDialogWithId();

  const tariffDialogModeRef = useRef();
  const handleOpenTariffDialogInCreateMode = () => {
    tariffDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenTariffGroupDialog(undefined);
  };

  const handleOpenElectricityTarrifGroupDialogInEditMode = (
    ticketCategoryId
  ) => {
    tariffDialogModeRef.current = DIALOG_EDIT_MODE;
    onOpenTariffGroupDialog(ticketCategoryId);
  };

  const getTariffDialogSubmitCallback = () => {
    setDataLocaly(undefined);

    if (
      tariffDialogModeRef.current === DIALOG_CREATE_MODE &&
      !isSearchParamsObjectEmpty(filterSearchParams)
    ) {
      setSearchParams(
        clearSearchParamsByFilterPrefix(ELECTRICITY_TARIFF_GROUP_TABLE_PREFIX)
      );
    } else {
      backendData.refetch();
    }

    setExistsChanges(true);
  };

  const isLoading = dataLocaly === undefined || filteringData.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid container spacing={1} justifyContent={"flex-end"}>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={
              <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
            }
            onClick={handleOpenTariffDialogInCreateMode}
            sx={overflowButtonStyle}
          >
            {t("dialog.electricity_tariff.add_tariff_group")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TariffTable
            showFilters={true}
            data={dataLocaly}
            tableConfig={ELECTRICITY_TARIFF_GROUP_TABLE_CONFIG}
            hiddenColumns={[]}
            filteringData={filteringData}
            filterPrefix={ELECTRICITY_TARIFF_GROUP_TABLE_PREFIX}
            initialExpandedFilterHeader={
              !isSearchParamsObjectEmpty(filterSearchParams)
            }
            // style={{ maxHeight: "70vh" }}
            showCleanFilterIcon={true}
            showExportToFileButton={false}
            withPagination={false}
            onClickEdit={handleOpenElectricityTarrifGroupDialogInEditMode}
            showCheckbox={false}
          />
        </Grid>
        {openTariffGroupDialog && (
          <ElectricityTarrifGroupDialog
            open={openTariffGroupDialog}
            onClose={onCloseTariffGroupDialog}
            dialogMode={tariffDialogModeRef.current}
            onSubmitCallback={getTariffDialogSubmitCallback}
            tariffGroupId={tariffGroupId}
          />
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => props.onClose(existsChanges)}
      titleAlign="center"
      maxWidth={"md"}
      title={t("dialog.electricity_tariff.tariff_group_management")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

ElectricityTariffManagementDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

ElectricityTariffManagementDialog.defaultProps = {};

export default ElectricityTariffManagementDialog;
