import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";

import useEquipmentService from "../../../services/equipmentService";

import BasicDialog from "../../base/BasicDialog";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import FloatField from "../../base/FloatField";
import AutocompleteField from "../../field/AutocompleteField";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";
import { getErrorMsg } from "../../../helpers/methods";
import { INTERNAL_TYPE } from "../../../helpers/constants";

import { useSnackbarAlert } from "../../../context/snackbarAlert";

import useFileService from "../../../services/fileService";
import FileUploadList from "../../other/FileUploadList";

import useEnclosuresData from "../../../hooks/useEnclosuresData";

import DateService from "../../../services/dateService";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../../helpers/methods";
import { EQUIPBMS_STATUSES_TO_CHANGED } from "../../button/ChangeEquipmentBmsStatusButton/ChangeEquipmentBmsStatusButton";

const EquipmentBmsDetailsDialog = (props) => {
  const { t } = useTranslation();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm({});

  const snackbarAlert = useSnackbarAlert();

  const { getEquipmentBmsDetailsData, updateEquipmentBms } =
    useEquipmentService();

  const equipmentBmsData = useAsync(
    () => getEquipmentBmsDetailsData(props.equipmentBmsId),
    []
  );

  useEffect(() => {
    if (equipmentBmsData.loading) return;

    setFormValue(equipmentBmsData.value);
  }, [equipmentBmsData.loading]);

  const {
    enclosures: enclosuresForEquipment,
    onPreviewEnclosure: onPreviewEnclosureForEquipment,
    onDownloadEnclosure: onDownloadEnclosureForEquipment,
  } = useEnclosuresData(
    equipmentBmsData.value?.equipment_id,
    undefined,
    "equipment"
  );

  const { handlePreviewEnclosure, handleDownloadEnclosure } = useFileService();

  const onDownloadEnclosure = () => {
    handleDownloadEnclosure(
      equipmentBmsData.value.enclosure.id,
      equipmentBmsData.value.enclosure?.enclosure_name
    );
  };

  const onPreviewEnclosure = (id) => {
    handlePreviewEnclosure(id);
  };

  const updateEquipmentBmsOrderFn = useAsyncFn(updateEquipmentBms);
  const onSubmit = (dataToSend) => {
    updateEquipmentBmsOrderFn
      .execute(props.equipmentBmsId, dataToSend)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.reading_updated")
        );
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const getPreparedDataToSend = () => {
    let tempFormValue = {
      equipbms_value: formValue.equipbms_value,
      equipbms_note: formValue.equipbms_note,
      equipbms_status: formValue.equipbms_status,
    };
    return tempFormValue;
  };

  const handleSubmit = () => onSubmit(getPreparedDataToSend());

  const getDialogContent = () => {
    if (equipmentBmsData.loading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <TextFieldFormControl
            label={t("form.meter_passport.equipment_nr")}
            value={equipmentBmsData.value.equipment_nr}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormControl
            label={t("form.meter_passport.last_read_value")}
            value={equipmentBmsData.value.last_read?.equipbms_value}
            readOnly={true}
            suffix={equipmentBmsData.value.last_read?.equipbms_unit}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormControl
            label={t("form.meter_passport.last_read_date")}
            value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
              equipmentBmsData.value.last_read?.equipbms_date
            )}
            readOnly={true}
          />
        </Grid>
        <FileUploadList
          addEnclosureButtonProps={{ size: "mini" }}
          defaultEnclosureType={INTERNAL_TYPE}
          enclosures={enclosuresForEquipment}
          onPreviewEnclosure={onPreviewEnclosureForEquipment}
          onDownloadEnclosure={onDownloadEnclosureForEquipment}
          onUpdateEnclosure={() => {}}
          onDeleteEnclosure={() => {}}
          onAddEnclosure={() => {}}
          canRemoveEnclosures={false}
          readOnly={true}
          showExtendedAddEnclosureDialog={false}
          showSubcontractorSelect={false}
          showEnclosureType={false}
          allowCopyEnclosure={false}
          enclosureWindowStyle={{ maxHeight: "200px" }}
          fileUploadListTitle={t(
            "other.file_upload_list.enclosures_for_equipment"
          )}
        />
        <Grid item xs={6}>
          <FloatField
            name={"equipbms_value"}
            label={t("form.meter_passport.new_read")}
            value={formValue.equipbms_value}
            onChange={onChange}
            suffix={formValue.equipbms_unit}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormControl
            label={t("form.meter_passport.read_date")}
            value={DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
              equipmentBmsData.value.equipbms_date
            )}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteField
            name="equipbms_status"
            label={t("table.counter_repository.equipbms_status")}
            value={formValue?.equipbms_status}
            options={convertFlatListFromBackendToTranslatedOptionObject(
              EQUIPBMS_STATUSES_TO_CHANGED
            )}
            isObjectOption={true}
            addNewValue={false}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            multiple={false}
            disableClearable
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormControl
            name={"equipbms_note"}
            label={t("form.meter_passport.new_read_note")}
            value={formValue.equipbms_note}
            onChange={onChange}
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={12}>
          <FileUploadList
            addEnclosureButtonProps={{ size: "mini" }}
            defaultEnclosureType={INTERNAL_TYPE}
            enclosures={[equipmentBmsData.value.enclosure]}
            onDownloadEnclosure={onDownloadEnclosure}
            onPreviewEnclosure={onPreviewEnclosure}
            onAddEnclosure={() => {}}
            onDeleteEnclosure={() => {}}
            onUpdateEnclosure={() => {}}
            showExtendedAddEnclosureDialog={true}
            showSubcontractorSelect={false}
            showEnclosureType={false}
            showEnclosurePartKind={false}
            allowCopyEnclosure={false}
            multiple={false}
            readOnly={true}
            fileUploadListTitle={t("other.file_upload_list.reading_photo")}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            variant="contained"
            color="primary"
            loading={updateEquipmentBmsOrderFn.loading}
            onClick={handleSubmit}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      title={t("dialog.equipment.equipment_bms_order")}
      maxWidth={"sm"}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

EquipmentBmsDetailsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  equipmentBmsId: PropTypes.string,
};

EquipmentBmsDetailsDialog.defaultProps = {};

export default EquipmentBmsDetailsDialog;
