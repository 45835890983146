import { useState, useEffect, useRef, useCallback } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";
import useTicketService from "../../services/ticketService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import useDialog from "../../hooks/useDialog";
import TableColumnVisibilityDialog from "../../components/dialog/TableColumnVisibilityDialog";
import TicketTable from "../../components/table/TicketTable";
import TableService from "../../services/tableService";
import useUserPreferencesService from "../../services/userPreferencesService";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import useBasicDrawer from "../../hooks/useBasicDrawer";
import OffersManagmentToolBar from "../../components/bar/OffersManagmentToolBar";
import OfferTable from "../../components/table/OfferTable/OfferTable";
import {
  OFFER_EXTRA_FIELDS_CONFIG,
  OFFER_TABLE_FIELDS_CONFIG,
  OFFER_TABLE_NAME,
  TICKET_OFFER_TABLE_FIELDS_CONFIG,
  TICKET_OFFER_TABLE_NAME,
} from "./PageTablesConfig";
import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import {
  OFFER_CANCEL_ACTION,
} from "../../helpers/constants";
import useOfferService from "../../services/offerService";
import DetailsDrawer from "../../components/drawer/UniversalDetailsDrawer/UniversalDetailsDrawer";
import { useAuth } from "../../context/auth";
import OfferConfiguratorDialog from "../../components/dialog/OfferConfiguratorDialog";
import AddIcon from "@mui/icons-material/Add";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import SelectTicketDialog from "../../components/dialog/SelectTicketDialog";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import CancelOfferDialog from "../../components/dialog/CancelOfferDialog";
import OfferDocumentDialog from "../../components/dialog/OfferDocumentDialog";
import { NAVIGATION_DRAWER_TYPE_OFFERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

const TABLE_CONFIGS = [
  { name: TICKET_OFFER_TABLE_NAME, config: TICKET_OFFER_TABLE_FIELDS_CONFIG },
  { name: OFFER_TABLE_NAME, config: OFFER_TABLE_FIELDS_CONFIG },
];

const OFFER_TABLE_FILTER_PREFIX = "offertable";

const TICKET_TABLE_FILTER_PREFIX = "tickettable";

export default function OffersManagmentPage(props) {
  const { pageName } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const [ticketDataLocaly, setTicketDataLocaly] = useState();
  const [countTicketRecords, setCountTicketRecords] = useState();
  const [offersDataLocaly, setOffersDataLocaly] = useState([]);
  const [countOfferRecords, setCountOfferRecords] = useState();
  const [offerTableTitle, setOfferTableTitle] = useState(undefined);
  const [offerTableConfigLocal, setOfferTableConfigLocal] = useState(
    OFFER_TABLE_FIELDS_CONFIG
  );
  const [ticketTableSearchParams, setTicketTableSearchParams] = useState({});
  const [offerTableSearchParams, setOfferTableSearchParams] = useState({});

  const [isTicketTableView, setIsTicketTableView] = useState(true);
  const [isOffersForTicketLoading, setIsOffersForTicketLoading] = useState(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);
  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();
  const [chosenOfferDetails, setChosenOfferDetails] = useState(undefined);
  const [configuratorOfferMode, setConfiguratorOfferMode] = useState(undefined);

  const openedTicketIdRef = useRef();
  const openedOfferIdRef = useRef();
  const selectedTicketIdToNewOfferIdRef = useRef();
  const selectedOfferIdToConfiguratorRef = useRef();
  const selectedOfferIdCancelRef = useRef();

  const [checkedAll, setCheckedAll] = useState(false);
  const { user } = useAuth();

  const [searchParamsFromPage, setSearchParamsFromPage] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(searchParams.entries());
  });

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      TICKET_TABLE_FILTER_PREFIX,
      ticketTableSearchParams,
      setTicketTableSearchParams
    );
    setNewItemSearchParamsIfAreChanged(
      OFFER_TABLE_FILTER_PREFIX,
      offerTableSearchParams,
      setOfferTableSearchParams
    );
  }, [searchParams]);

  const [
    openTableColumnVisibilityDialog,
    onOpenTableColumnVisibilityDialog,
    onCloseTableColumnVisibilityDialog,
  ] = useDialog();

  const [
    openOfferConfiguratorDialog,
    onOpenOfferConfiguratorDialog,
    onCloseOfferConfiguratorDialog,
  ] = useDialog();

  const [
    openSelectTicketDialog,
    onOpenSelectTicketDialog,
    onCloseSelectTicketDialog,
  ] = useDialog();

  const [
    openCancelOfferDialog,
    onOpenCancelOfferDialog,
    handleCloseCancelOfferDialog,
  ] = useDialog();

  const [
    openViewerOfferDialog,
    onOpenViewerOfferDialog,
    handleCloseViewerOfferDialog,
  ] = useDialog();

  const {
    page: ticketDataPage,
    pageSize: ticketDataPageSize,
    handleChangePageWithSearchParams: handleChangeTicketDataPage,
    handleChangePageSizeWithSearchParams: handleChangeTicketDataPageSize,
    resetTicketPageNumber,
  } = usePaginationWithSearchParams(TICKET_TABLE_FILTER_PREFIX);

  const {
    page: offerDataPage,
    pageSize: offerDataPageSize,
    handleChangePageWithSearchParams: handleChangeOfferDataPage,
    handleChangePageSizeWithSearchParams: handleChangeOfferDataPageSize,
    resetOfferPageNumber,
  } = usePaginationWithSearchParams(
    isTicketTableView ? OFFER_TABLE_FILTER_PREFIX : undefined
  );

  const { getTicketOfferData, getTicketOffersFilteringData } =
    useTicketService();

  const {
    getAllOffersList,
    getOfferListByTicket,
    getOffersFilteringData,
    getOfferDrawerDetailsData,
    addOffersToFavority,
    deleteOffersFromFavority,
    updateOfferData,
  } = useOfferService();

  const { setNewItemSearchParamsIfAreChanged } =
    useFilterSearchParams(searchParams);

  const {
    getUserPreferencesForPage,
    updateUserPreferencesForPage,
    getHiddenColumnFormValueFromBackend,
    getColumnOrdersFormValueFromBackend,
    convertUserPreferencesFromFrontendToBackend,
  } = useUserPreferencesService();

  const ticketData = useAsync(
    () => getTicketDataBySelectedView(),
    [ticketTableSearchParams, isTicketTableView]
  );

  const offersData = useAsync(
    () => getOffersDataBySelectedView(searchParams),
    [searchParams, isTicketTableView]
  );

  const userPreferencesForPage = useAsync(
    () => getUserPreferencesForPage(pageName),
    [pageName]
  );

  const getOffersDataBySelectedView = () => {
    if (!isTicketTableView) {
      return getAllOffersList(searchParams);
    }
    return Promise.resolve([]);
  };

  const getTicketDataBySelectedView = () => {
    if (isTicketTableView) {
      return getTicketOfferData(ticketTableSearchParams);
    }
    return Promise.resolve([]);
  };

  const ticketFilteringData = useAsync(getTicketOffersFilteringData);

  const offerFilteringData = useAsync(getOffersFilteringData);

  const updateUserPreferencesForPageFn = useAsyncFn(
    updateUserPreferencesForPage
  );

  const offersByTicketFn = useAsyncFn(getOfferListByTicket);

  const offersDetailsFn = useAsyncFn(getOfferDrawerDetailsData);

  const addOfferToFavorityFn = useAsyncFn(addOffersToFavority);

  const updateOfferDataFn = useAsyncFn(updateOfferData);

  const deleteOfferToFavorityFn = useAsyncFn(deleteOffersFromFavority);

  useEffect(() => {
    if (ticketData.loading) {
      return;
    }
    setTicketDataLocaly(ticketData.value.results);
    setCountTicketRecords(ticketData.value.count);
  }, [ticketData.loading]);

  useEffect(() => {
    if (offersData.loading) {
      return;
    }
    setOffersDataLocaly(offersData.value.results);
    setCountOfferRecords(offersData.value.count);
  }, [offersData.loading]);

  useEffect(() => {
    if (userPreferencesForPage.loading) {
      return;
    }
    setHiddenColumnsForTables(() =>
      getHiddenColumnFormValueFromBackend(
        [TICKET_OFFER_TABLE_NAME, OFFER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );

    setColumnsOrdersForTables(() =>
      getColumnOrdersFormValueFromBackend(
        [TICKET_OFFER_TABLE_NAME, OFFER_TABLE_NAME],
        userPreferencesForPage.value
      )
    );
  }, [userPreferencesForPage.loading]);

  useEffect(() => {
    if (openedTicketIdRef.current) {
      offersByTicketFn
        .execute(openedTicketIdRef.current, offerTableSearchParams)
        .then((data) => {
          setOffersDataLocaly(data.results);
          setCountOfferRecords(data.count);
        });
    }
  }, [offerTableSearchParams]);

  useEffect(() => {
    if (isTicketTableView) {
      setOfferTableConfigLocal(OFFER_TABLE_FIELDS_CONFIG);
    } else {
      const tempConfig = OFFER_TABLE_FIELDS_CONFIG;

      var newOfferConfig = [
        ...tempConfig.slice(0, 1),
        ...OFFER_EXTRA_FIELDS_CONFIG,
        ...tempConfig.slice(1),
      ];
      setOfferTableConfigLocal(newOfferConfig);
    }
  }, [isTicketTableView]);
  const handleChangeTicketRowsPerPage = (e) => {
    handleChangeTicketDataPageSize(e, parseInt(e.target.value, 10));
  };

  const handleChangeOfferRowsPerPage = (e) => {
    handleChangeOfferDataPageSize(e, parseInt(e.target.value, 10));
  };

  const handleUpdateUserPreferences = (
    tablesHiddenColumns,
    tablesColumnOrders
  ) => {
    updateUserPreferencesForPageFn
      .execute(
        pageName,
        convertUserPreferencesFromFrontendToBackend(
          tablesHiddenColumns,
          tablesColumnOrders
        )
      )
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.table_columns_visibility_updated")
        );

        userPreferencesForPage.refetch();
        if (isTicketTableView) {
          setTicketDataLocaly(undefined);
          ticketData.refetch();
        } else {
          handleToRefreshView();
        }
        onCloseTableColumnVisibilityDialog();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t(
            "snackbar_alert.occurred_error_during_updating_table_columns_visibility"
          )
        );
      });
  };

  const handleClickTicketRow = (ticketId) => {
    let selectedTicket = ticketDataLocaly.find(
      (ticket) => ticket.id === ticketId
    );

    if (!ticketId || ticketId === openedTicketIdRef.current) {
      openedTicketIdRef.current = null;
      setOfferTableTitle(undefined);
      setOffersDataLocaly([]);
      setCountOfferRecords(0);
      setOpenDetailsDrawer(false);
    } else {
      openedOfferIdRef.current = null;
      setOpenDetailsDrawer(false);
      openedTicketIdRef.current = ticketId;
      setOfferTableTitle(
        `${t("page.offers_page.offers_for_ticket_nr")} ${
          selectedTicket.ticket_nr
        }`
      );
      offersByTicketFn
        .execute(ticketId, offerTableSearchParams)
        .then((data) => {
          setOffersDataLocaly(data.results);
          setCountOfferRecords(data.count);
        });
    }
  };

  const handleClickOfferRow = (offerId) => {
    if (!offerId || offerId === openedOfferIdRef.current) {
      openedOfferIdRef.current = null;
      setOpenDetailsDrawer(false);
    } else {
      openedOfferIdRef.current = offerId;
      setOpenDetailsDrawer(true);
      setIsOffersForTicketLoading(true);
      offersDetailsFn.execute(openedOfferIdRef.current).then((data) => {
        setChosenOfferDetails(data);
        setIsOffersForTicketLoading(false);
      });
    }
  };

  const handleChangeTablesView = (isTableViewVisibility) => {
    setIsTicketTableView(isTableViewVisibility);
    openedOfferIdRef.current = null;
    openedTicketIdRef.current = null;
    setOpenDetailsDrawer(false);
    handleUnCheckAll();
  };

  const handleClickCheckAll = useCallback(
    (value) => {
      let currValue = !checkedAll;
      setCheckedAll(currValue);

      setOffersDataLocaly((prev) => {
        return prev.map((rowData) => ({
          ...rowData,
          selected: currValue,
        }));
      });
    },
    [checkedAll]
  );

  const handleUnCheckAll = () => {
    setCheckedAll(false);
    setOffersDataLocaly((prev) => {
      return prev?.map((rowData) => ({
        ...rowData,
        selected: false,
      }));
    });
  };

  const handleClickCheck = useCallback((id) => {
    setOffersDataLocaly((prevData) => {
      let selectedRow = { ...prevData.find((rowData) => rowData.id === id) };
      selectedRow.selected = !selectedRow.selected;
      return prevData.map((rowData) => {
        if (rowData.id === id) return selectedRow;
        return rowData;
      });
    });
  }, []);

  const getSeletedOffersIds = () =>
    offersDataLocaly.filter((offer) => offer.selected).map((offer) => offer.id);

  const handleToRefreshView = () => {
    if (isTicketTableView) {
      setIsOffersForTicketLoading(true);
      offersByTicketFn
        .execute(openedTicketIdRef.current, offerTableSearchParams)
        .then((data) => {
          setOffersDataLocaly(data.results);
          setCountOfferRecords(data.count);
          setIsOffersForTicketLoading(false);
        });
    } else {
      offersData.refetch();
    }
  };

  const handleAddOffersForUserFavority = () => {
    let offerIds = getSeletedOffersIds();
    if (offerIds.length === 0) return;

    addOfferToFavorityFn
      .execute(offerIds)
      .then((res) => {
        setOffersDataLocaly(undefined);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.offer_to_favority_added")
        );
        handleToRefreshView();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_adding_offer_to_favority")
        );
      });
  };

  const handleDeleteOffersFromFavority = () => {
    let offerIds = getSeletedOffersIds();
    if (offerIds.length === 0) return;

    deleteOfferToFavorityFn
      .execute(offerIds)
      .then((res) => {
        setOffersDataLocaly(undefined);
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.offer_from_favority_deleted")
        );
        handleToRefreshView();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_deleting_offer_from_favority")
        );
      });
  };

  const handleClickVisibilityState = (filterKey) => {
    switch (filterKey) {
      case "ticket_offer":
        handleChangeTablesView(true);
        setSearchParams({});
        break;
      case "all-offers":
        handleChangeTablesView(false);
        setSearchParams({});
        break;
      case "my-offers":
        handleChangeTablesView(false);
        setSearchParams({ creator: user.user_id });
        break;
      default:
        handleChangeTablesView(true);
        setSearchParams({});
    }
  };

  const handleNewOfferForTicket = (ticketId) => {
    selectedTicketIdToNewOfferIdRef.current = ticketId;
    onOpenOfferConfiguratorDialog();
    setConfiguratorOfferMode("newOffer");
    const newSearchParams = new URLSearchParams(window.location.search);
    setSearchParamsFromPage(Object.fromEntries(newSearchParams.entries()));
    setSearchParams({});
  };

  const location = useLocation();
  useEffect(() => {
    if (location.state?.open_configurator && location.state?.ticket_id) {
      openedTicketIdRef.current = location.state.ticket_id;
      handleNewOfferForTicket(location.state.ticket_id);
    }
  }, []);

  const handleOpenOffer = (offerId) => {
    selectedOfferIdToConfiguratorRef.current = offerId;
    onOpenOfferConfiguratorDialog();
    setConfiguratorOfferMode("existOffer");

    const newSearchParams = new URLSearchParams(window.location.search);
    setSearchParamsFromPage(Object.fromEntries(newSearchParams.entries()));
    setSearchParams({});
  };

  const handleToCancelOffer = (offerId) => {
    onOpenCancelOfferDialog();
    selectedOfferIdCancelRef.current = offerId;
  };

  const handleToConfirmCancelOffer = (offerId) => {
    if (offerId) {
      selectedOfferIdCancelRef.current = offerId;
    }
    handleSimplyOfferAction(
      selectedOfferIdCancelRef.current,
      OFFER_CANCEL_ACTION
    );
  };

  const handleSimplyOfferAction = (offerId, action) => {
    updateOfferDataFn
      .execute(offerId, { offer_details: { id: offerId }, action: action })
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.offer_data_updated")
        );

        setIsTicketTableView(false);
        offersData.refetch();
        handleCloseCancelOfferDialog();

        selectedOfferIdCancelRef.current = undefined;
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_updating_offer")
        );
      });
  };

  const navigate = useNavigate();
  const handleToCloseConfiguratorOffer = useCallback(
    (setParams = true, existChanges = false) => {
      // if open configurator on init and no exsist changes redirect to source page
      if (existChanges === false && location.state?.open_configurator) {
        return navigate(location.state.from, {
          replace: true,
          state: {
            from_unsaved_configurator: true,
            ticket_id: location.state?.ticket_id,
          },
        });
      }

      onCloseOfferConfiguratorDialog();
      selectedTicketIdToNewOfferIdRef.current = null;
      selectedOfferIdToConfiguratorRef.current = null;
      openedOfferIdRef.current = null;
      setOpenDetailsDrawer(false);
      setConfiguratorOfferMode(undefined);
      if (setParams) {
        setSearchParams({ ...searchParamsFromPage });
        setSearchParamsFromPage({});
      }
      if (isTicketTableView) {
        ticketData.refetch();
        if (openedTicketIdRef.current) {
          offersByTicketFn
            .execute(openedTicketIdRef.current, offerTableSearchParams)
            .then((data) => {
              setOffersDataLocaly(data.results);
              setCountOfferRecords(data.count);
              setIsOffersForTicketLoading(false);
            });
        }
      } else {
        offersData.refetch();
      }
    },
    [isTicketTableView]
  );



  const handleToChooseTicket = useCallback((ticketId) => {
    onCloseSelectTicketDialog();
    handleNewOfferForTicket(ticketId);
  }, []);

  const massActionButtonOptions = [
    {
      label: t("page.offers_page.add_offer_to_favority"),
      callback: handleAddOffersForUserFavority,
    },
    {
      label: t("page.offers_page.delete_offer_from_favority"),
      callback: handleDeleteOffersFromFavority,
    },
  ];

  const filterChoiceButtonOptions = [
    {
      label: t("button.ticket_offer_button.tickets_with_offers"),
      key: "ticket_offer",
      callback: () => handleClickVisibilityState("ticket_offer"),
    },
    {
      label: t("button.ticket_offer_button.all_offers"),
      key: "all-offers",
      callback: () => handleClickVisibilityState("all-offers"),
    },
    {
      label: t("button.ticket_offer_button.my_offers"),
      key: "my-offers",
      callback: () => handleClickVisibilityState("my-offers"),
    },
  ];

  const ticketTableContextMenuActions = [
    {
      label: t("page.offers_page.create_offer"),
      callback: (ticketId) => handleNewOfferForTicket(ticketId),
      icon: <AddIcon fontSize="small" />,
    },
  ];

  const offerTableContextMenuActions = [
    {
      label: t("page.offers_page.open_offer"),
      callback: (offerId) => handleOpenOffer(offerId),
      icon: <ZoomInIcon fontSize="medium" />,
    },
  ];

  const isTicketTableLoading =
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    columnsOrdersForTables === undefined ||
    ticketFilteringData.loading ||
    ticketDataLocaly === undefined ||
    countTicketRecords === undefined;

  const isOfferTableLoading =
    userPreferencesForPage.loading ||
    hiddenColumnsForTables === undefined ||
    columnsOrdersForTables === undefined ||
    offerFilteringData.loading ||
    offersDataLocaly === undefined ||
    countOfferRecords === undefined;

  const getOfferTable = () => {
    return (
      <OfferTable
        data={offersDataLocaly ? offersDataLocaly : []}
        showCheckbox={true}
        checkedAll={checkedAll}
        onClickRow={handleClickOfferRow}
        onClickCheckAll={handleClickCheckAll}
        onClickCheck={handleClickCheck}
        countRecords={countOfferRecords}
        page={offerDataPage}
        onPageChange={handleChangeOfferDataPage}
        rowsPerPage={offerDataPageSize}
        onRowsPerPageChange={handleChangeOfferRowsPerPage}
        resetPageNumber={resetOfferPageNumber}
        filteringData={offerFilteringData}
        hiddenColumns={
          hiddenColumnsForTables ? hiddenColumnsForTables[OFFER_TABLE_NAME] : []
        }
        columnsOrders={
          columnsOrdersForTables ? columnsOrdersForTables[OFFER_TABLE_NAME] : []
        }
        selectedOfferId={openedOfferIdRef.current}
        showTitle={isTicketTableView}
        tableConfig={offerTableConfigLocal}
        title={offerTableTitle}
        style={isTicketTableView ? { maxHeight: "40vh" } : {}}
        filterPrefix={isTicketTableView ? OFFER_TABLE_FILTER_PREFIX : undefined}
        showContextMenu={true}
        contextMenuActions={offerTableContextMenuActions}
        isLoading = {offersData.loading ||isOfferTableLoading ||offersByTicketFn.loading}
      />
    );
  };

  const getPageContent = () => {
    if (isTicketTableView) {
      return (
        <LoaderWrapper showLoader={isTicketTableLoading}>
          <TicketTable
            data={ticketDataLocaly}
            onClickRow={handleClickTicketRow}
            countRecords={countTicketRecords}
            page={ticketDataPage}
            onPageChange={handleChangeTicketDataPage}
            rowsPerPage={ticketDataPageSize}
            onRowsPerPageChange={handleChangeTicketRowsPerPage}
            resetPageNumber={resetTicketPageNumber}
            filteringData={ticketFilteringData}
            hiddenColumns={
              hiddenColumnsForTables
                ? hiddenColumnsForTables[TICKET_OFFER_TABLE_NAME]
                : []
            }
            columnsOrders={
              columnsOrdersForTables
                ? columnsOrdersForTables[TICKET_OFFER_TABLE_NAME]
                : undefined
            }
            selectedTicketId={openedTicketIdRef.current}
            style={{ maxHeight: "40vh" }}
            tableConfig={TICKET_OFFER_TABLE_FIELDS_CONFIG}
            filterPrefix={TICKET_TABLE_FILTER_PREFIX}
            showCleanFilterIcon={true}
            showContextMenu={true}
            contextMenuActions={ticketTableContextMenuActions}
            isLoading = {isTicketTableLoading || ticketData.loading}
          />
          {openedTicketIdRef.current ? (
            <LoaderWrapper showLoader={isOfferTableLoading}>
              {getOfferTable()}
            </LoaderWrapper>
          ) : (
            <Typography
              textAlign="center"
              variant="overline"
              display="block"
              gutterBottom
              style={{ margin: 0, fontSize: "20px", color: "var(--primary)" }}
            >
              {t("page.offers_page.choose_ticket_to_show_offers")}
            </Typography>
          )}
        </LoaderWrapper>
      );
    } else {
      return (
        <LoaderWrapper showLoader={isOfferTableLoading}>
          {getOfferTable()}
        </LoaderWrapper>
      );
    }
  };

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={setOpenDetailsDrawer}
      itemData={chosenOfferDetails}
      itemType={"offer"}
      isLoading={offersDetailsFn.loading}
      readOnly={true}
    >
      <NavigationDrawer
        drawerType={NAVIGATION_DRAWER_TYPE_OFFERS}
        pageName={pageName}
      >
        <DefaultPageWrapper titleKey={"offer_management"}>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <OffersManagmentToolBar
                style={{ marginBlock: "5px" }}
                onClickAdjustTable={onOpenTableColumnVisibilityDialog}
                onClickCreateOfferTicket={onOpenSelectTicketDialog}
                massActionButtonOptions={massActionButtonOptions}
                filterOptions={filterChoiceButtonOptions}
                isTicketTableView={isTicketTableView}
              />
            </Grid>
            <Grid item xs={12}>
              {getPageContent()}
            </Grid>
          </Grid>

          {openTableColumnVisibilityDialog && (
            <TableColumnVisibilityDialog
              open={openTableColumnVisibilityDialog}
              onClose={onCloseTableColumnVisibilityDialog}
              onSubmit={handleUpdateUserPreferences}
              tablesConfigs={TableService.getTableConfigsForTableColumnVisibilityDialog(
                isTicketTableView
                  ? TABLE_CONFIGS
                  : [{ name: OFFER_TABLE_NAME, config: offerTableConfigLocal }],
                columnsOrdersForTables
              )}
              tablesHiddenColumns={hiddenColumnsForTables}
              isLoading={userPreferencesForPage.loading}
            />
          )}
          {openSelectTicketDialog && (
            <SelectTicketDialog
              open={openSelectTicketDialog}
              onSubmit={(ticketId) => handleToChooseTicket(ticketId)}
              onClose={onCloseSelectTicketDialog}
              searchParams = {{'is_open' : true, 'ticket_created_by_tenant' : true}}
            />
          )}
          {openOfferConfiguratorDialog && (
            <OfferConfiguratorDialog
              open={openOfferConfiguratorDialog}
              offerId={selectedOfferIdToConfiguratorRef.current}
              ticketId={selectedTicketIdToNewOfferIdRef.current}
              dialogMode={configuratorOfferMode}
              filteringData={offerFilteringData.value}
              onClose={handleToCloseConfiguratorOffer}
            />
          )}
          {openCancelOfferDialog && (
            <CancelOfferDialog
              open={openCancelOfferDialog}
              onYes={handleToConfirmCancelOffer}
              onNo={() => {
                selectedOfferIdCancelRef.current = undefined;
                handleCloseCancelOfferDialog();
              }}
            />
          )}
          {openViewerOfferDialog && (
            <OfferDocumentDialog
              open={openViewerOfferDialog}
              onClose={handleCloseViewerOfferDialog}
              validatedOffer={{}}
              readOnly={true}
              isMyOfferPage={false}
            />
          )}
        </DefaultPageWrapper>
      </NavigationDrawer>
    </DetailsDrawer>
  );
}
