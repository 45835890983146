import React from "react";

import BoxWithLabel from "../../box/BoxWithLabel";

import { useTranslation } from "react-i18next";

import IconButtonWithTooltip from "../../button/IconButtonWithTooltip";

import useDialog from "../../../hooks/useDialog";
import ElectricityDistributionRelalculateDialog from "../../dialog/ElectricityDistributionRecalculateDialog";

import TungstenIcon from "@mui/icons-material/Tungsten";

const ElectricityDistributionForm = (props) => {
  const { t } = useTranslation();

  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();

  return (
    <BoxWithLabel
      label={t("form.meter_passport.electricity_distribution")}
      boxStyle={{ height: "100%" }}
      style={{ height: "100%", display: "flex", justifyContent: "center" }}
    >
      <IconButtonWithTooltip
        title={t("form.meter_passport.electricity_distribution")}
        fontSize={"500%"}
        onClick={onOpenDialog}
      >
        <TungstenIcon fontSize="inherit" color="primary" />
      </IconButtonWithTooltip>
      {openDialog && (
        <ElectricityDistributionRelalculateDialog
          equipmentId={props.equipmentId}
          onClose={onCloseDialog}
          open={openDialog}
        />
      )}
    </BoxWithLabel>
  );
};

export default ElectricityDistributionForm;
