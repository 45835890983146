import React from "react";
import PropTypes from "prop-types";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import DateField from "../../field/DateField/DateField";
import BoxWithLabel from "../../box/BoxWithLabel";

import { Grid, Tooltip } from "@mui/material";

import { useTranslation } from "react-i18next";

const CounterBasicInfoForm = (props) => {
  const { t } = useTranslation();

  return (
    <BoxWithLabel label={t("form.meter_passport.basic_info")}>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12} md={2}>
          <TextFieldFormControl
            name={"equipment_nr"}
            value={props.formValue.equipment_nr}
            label={t("form.meter_passport.equipment_nr")}
            onChange={props.onChange}
            readOnly={
              true ||
              props.readOnly ||
              props.readOnlyFields.includes("equipment_nr")
            }
          />
        </Grid>
        <Grid item xs={12} md={2.5}>
          <DateField
            name={"equipment_date_production"}
            label={t("form.meter_passport.equipment_date_production")}
            value={props.formValue.equipment_date_production}
            onChange={props.onChangeDate}
            maxDate={new Date()}
            showClearIcon={false}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipment_date_production")
            }
            view={"month"}
          />
        </Grid>
        <Grid item xs={12} md={2.5}>
          <DateField
            name={"equipment_date_legalization"}
            label={t("form.meter_passport.equipment_date_legalization")}
            value={props.formValue.equipment_date_legalization}
            onChange={props.onChangeDate}
            showClearIcon={false}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipment_date_legalization")
            }
            required
            minDate={props.formValue.equipment_date_production}
            view={"month"}
          />
        </Grid>
        <Tooltip
          title={
            <h2 style={{ whiteSpace: "break-spaces" }}>
              {t(`form.meter_passport.equipment_media_type_tooltip`)}
            </h2>
          }
          arrow
          placement={"left"}
        >
          <Grid item xs={12} md={2}>
            <AutocompleteField
              name={"equipment_media_type"}
              label={t("form.meter_passport.equipment_media_type")}
              value={props.formValue.equipment_media_type}
              onChange={props.onChangeAutocompleteFieldWithObjectOptions}
              options={props.filteringData?.equipment_media_type}
              readOnly={
                props.readOnly ||
                props.readOnlyFields.includes("equipment_media_type")
              }
              optionLabelKey={"equmedtyp_name"}
              multiple={false}
              isObjectOption={true}
              required
              disableClearable={true}
              error={props.unfillRequiredFields.includes(
                "equipment_media_type"
              )}
              helperText={
                props.unfillRequiredFields.includes("equipment_media_type") &&
                t("field_required")
              }
            />
          </Grid>
        </Tooltip>
        <Grid item xs={12} md={3}>
          <AutocompleteField
            name={"equipment_responsible_user"}
            label={t("form.meter_passport.equipment_responsible_user")}
            value={props.formValue.equipment_responsible_user}
            onChange={props.onChangeAutocompleteFieldWithObjectOptions}
            options={props.filteringData?.equipment_responsible_user}
            readOnly={
              props.readOnly ||
              props.readOnlyFields.includes("equipment_responsible_user")
            }
            optionLabelKey={"full_name"}
            multiple={false}
            isObjectOption={true}
            disableClearable={true}
            required
            error={props.unfillRequiredFields.includes(
              "equipment_responsible_user"
            )}
            helperText={
              props.unfillRequiredFields.includes(
                "equipment_responsible_user"
              ) && t("field_required")
            }
          />
        </Grid>
      </Grid>
    </BoxWithLabel>
  );
};

CounterBasicInfoForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  onChangeDate: PropTypes.func,
  readOnly: PropTypes.bool,
  filteringData: PropTypes.object,
  readOnlyFields: PropTypes.bool,
  unfillRequiredFields: PropTypes.array,
};

CounterBasicInfoForm.defaultProps = {
  readOnly: false,
  readOnlyFields: [],
};

export default CounterBasicInfoForm;
