import { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import useEquipmentService from "../../../services/equipmentService";

import BasicDialog from "../../base/BasicDialog";
import CounterTenantForm from "../../form/CounterTenantForm/CounterTenantForm";

import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useForm } from "../../../hooks/useForm";

import { getErrorMsg } from "../../../helpers/methods";

const EquipmentInstalationTenantSetDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const tenantSetRef = useRef();

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm();
  const {
    formValue: tenants,
    setFormValue: setTenants,
    onChangeInArrayForm: onChangeTenantInArrayForm,
    onChangeAutocompleteFieldWithObjectOptionsInArrayForm:
      onChangeAutocompleteTenantFieldWithObjectOptions,
  } = useForm();

  const {
    getEquipmentInstalationTenantSetDetailsData,
    updateEquipmentInstalationTenantSet,
    getEquipmentForMetersFilteringData,
  } = useEquipmentService();

  const equipmentInstalation = useAsync(() =>
    getEquipmentInstalationTenantSetDetailsData(
      props.equipmentInstalationTenantSetId
    )
  );

  const filteringData = useAsync(() =>
    getEquipmentForMetersFilteringData({
      only_free_ppe_or_for_eits_id: props.equipmentInstalationTenantSetId,
    })
  );

  const updateEquipmentInstalationFn = useAsyncFn(
    updateEquipmentInstalationTenantSet
  );

  useEffect(() => {
    if (equipmentInstalation.loading) return;

    const { tenant, ...rest } = equipmentInstalation.value;

    setTenants(tenant);

    setFormValue(rest);
  }, [equipmentInstalation.loading]);

  const getPreparedDataToSend = () => {
    const dataToSend = { ...formValue };

    dataToSend.tenant = tenants;

    return dataToSend;
  };

  const handleSubmit = () => {
    const unfillFieldsInstalationLocation =
      tenantSetRef.current.checkIfRequiredFieldsAreFill();

    if (unfillFieldsInstalationLocation.length > 0) {
      return;
    }

    updateEquipmentInstalationFn
      .execute(props.equipmentInstalationTenantSetId, getPreparedDataToSend())
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.equipment_instalation_tenant_set_updated")
        );
        if (props.onSubmitCallback) {
          props.onSubmitCallback(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isLoading =
    tenants === undefined || formValue === undefined || filteringData.loading;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <CounterTenantForm
            formValue={tenants}
            setFormValue={setTenants}
            startSettlement={formValue.eits_start_settlement}
            showEndSettlement={!formValue.eits_active}
            endSettlement={formValue.eits_end_settlement}
            power={formValue.eits_power}
            ppe={formValue.ppe}
            tariffGroup={formValue.electricity_tariff_group}
            onChangeDate={onChangeDate}
            onChange={onChange}
            onChangeAutocompleteFieldWithObjectOptions={
              onChangeAutocompleteFieldWithObjectOptions
            }
            onChangeInArrayForm={onChangeTenantInArrayForm}
            onChangeAutocompleteFieldWithObjectOptionsInArrayForm={
              onChangeAutocompleteTenantFieldWithObjectOptions
            }
            unfillRequiredFields={[]}
            filteringData={filteringData.value}
            ref={tenantSetRef}
            showTenantSetElectricityParams={true}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            sx={{ marginTop: 1 }}
            variant="contained"
            color="primary"
            fullWidth
            loading={updateEquipmentInstalationTenantSet.loading}
            onClick={handleSubmit}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      maxWidth={"md"}
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      titleAlign="center"
      title={t("dialog.equipment.edit_equipment_instalation_tenant_set")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

EquipmentInstalationTenantSetDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  equipmentInstalationTenantSetId: PropTypes.string,
};

EquipmentInstalationTenantSetDialog.defaultProps = {};

export default EquipmentInstalationTenantSetDialog;
