import { useMemo, useState, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { Grid, Button } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import useEquipmentService from "../../services/equipmentService";

import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

import { getObjectFromSearchParams, getErrorMsg } from "../../helpers/methods";
import { NAVIGATION_DRAWER_TYPE_METERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import EquipmentTable from "../../components/table/EquipmentTable/EquipmentTable";

import TableService from "../../services/tableService";
import { exportEquipmentBmsToFileUrl } from "../../helpers/apiUrls";

import useDialogWithId from "../../hooks/useDialogWithId";
import EquipmentBmsDetailsDialog from "../../components/dialog/EquipmentBmsDetailsDialog";

import {
  EQUIPMENT_BMS_TABLE_CONFIG,
  EQUIPMENT_BMS_TABLE_NAME,
} from "./TableConfig";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { centerVericalAlignIconStyle } from "../../helpers/styles";
import { overflowButtonStyle } from "../../helpers/styles";

import ChangeEquipmentBmsButton from "../../components/button/ChangeEquipmentBmsStatusButton";

import { useSnackbarAlert } from "../../context/snackbarAlert";

export default function EquipmentBmsReadingPage(props) {
  const { pageName } = props;

  const [
    openEquipmentBmsDetailsDialog,
    equipmentBmsId,
    onOpenEquipmentBmsDetailsDialog,
    onCloseEquipmentBmsDetailsDialog,
  ] = useDialogWithId();

  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const [dataLocaly, setDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const equipmentBmsSearchParams = useMemo(() => {
    let tempSearchParams = { ...getObjectFromSearchParams(searchParams) };
    return tempSearchParams;
  }, [searchParams]);

  const {
    getEquipmentBmsListData,
    getEquipmentBmsFilteringData,
    addEquipmentsToWaitingRoom,
  } = useEquipmentService();

  const equipmentBmsFilteringData = useAsync(getEquipmentBmsFilteringData);

  const equipmentBmsData = useAsync(() => {
    return getEquipmentBmsListData(equipmentBmsSearchParams);
  }, [equipmentBmsSearchParams]);

  useEffect(() => {
    if (equipmentBmsData.loading) {
      return;
    }
    setDataLocaly(equipmentBmsData.value.results);
    setCountRecords(equipmentBmsData.value.count);
  }, [equipmentBmsData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setDataLocaly(undefined);
    equipmentBmsData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const exportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      EQUIPMENT_BMS_TABLE_CONFIG,
      hiddenColumnsForTables?.[EQUIPMENT_BMS_TABLE_NAME],
      columnsOrdersForTables?.[EQUIPMENT_BMS_TABLE_NAME]
    );
  }, [
    EQUIPMENT_BMS_TABLE_CONFIG,
    hiddenColumnsForTables?.[EQUIPMENT_BMS_TABLE_NAME],
    columnsOrdersForTables?.[EQUIPMENT_BMS_TABLE_NAME],
  ]);

  const handleClickCheck = useCallback((id) => {
    setDataLocaly((prevData) => {
      let selectedRow = { ...prevData.find((rowData) => rowData.id === id) };
      selectedRow.selected = !selectedRow.selected;
      return prevData.map((rowData) => {
        if (rowData.id === id) return selectedRow;
        return rowData;
      });
    });
  }, []);

  const [checkedAll, setCheckedAll] = useState(false);
  const handleClickCheckAll = useCallback(
    (value) => {
      let currValue = !checkedAll;
      setCheckedAll(currValue);

      setDataLocaly((prev) => {
        return prev.map((rowData) => ({
          ...rowData,
          selected: currValue,
        }));
      });
    },
    [checkedAll]
  );

  const getSeletedEquipmentBmsIds = () =>
    dataLocaly
      .filter((equipmentBms) => equipmentBms.selected)
      .map((equipmentBms) => equipmentBms.id);

  const getSeletedEquipmentIds = () =>
    dataLocaly
      .filter((equipment) => equipment.selected)
      .map((equipment) => equipment.equipment_id);

  const addEquipemntsToWaitingRoomFn = useAsyncFn(addEquipmentsToWaitingRoom);

  const handleAddEquipmentToWaitingRoom = () => {
    let equipmentIds = getSeletedEquipmentIds();
    if (equipmentIds.length === 0) return;

    addEquipemntsToWaitingRoomFn
      .execute(equipmentIds)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.equipment_to_waiting_room_added")
        );
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t(
              "snackbar_alert.occurred_error_during_adding_equipment_to_waiting_room"
            )
          )
        );
      });
  };

  const getExtraButtonListForToolBar = () => {
    let extraButtonList = [
      <Button
        color="primary"
        onClick={handleAddEquipmentToWaitingRoom}
        variant="contained"
        size="small"
        startIcon={
          <ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />
        }
        sx={overflowButtonStyle}
        fullWidth
      >
        {t("add_to_waiting_room")}
      </Button>,
      <ChangeEquipmentBmsButton
        selectedEquipmentBmsIds={getSeletedEquipmentBmsIds()}
        onSubmit={refreshTable}
      />,
    ];

    return extraButtonList;
  };

  const isLoading =
    dataLocaly === undefined || equipmentBmsFilteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_METERS}
    >
      <DefaultPageWrapper titleKey={"equipment_reading"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <UniversalToolBarWithDialogs
              pageName={props.pageName}
              tableConfig={[
                {
                  name: EQUIPMENT_BMS_TABLE_NAME,
                  config: EQUIPMENT_BMS_TABLE_CONFIG,
                },
              ]}
              filteringData={equipmentBmsFilteringData}
              refreshTable={refreshTable}
              onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
              resetPageNumber={resetPageNumber}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showOpenCloseFilterButton={false}
              showCreateButton={false}
              extraButtonList={getExtraButtonListForToolBar()}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isLoading}>
              {hiddenColumnsForTables && columnsOrdersForTables && (
                <EquipmentTable
                  data={dataLocaly}
                  showCheckbox={true}
                  checkedAll={checkedAll}
                  onClickCheckAll={handleClickCheckAll}
                  onClickCheck={handleClickCheck}
                  showCleanFilterIcon={false}
                  countRecords={countRecords}
                  page={page}
                  onClickMultipleLocationAlert={onOpenEquipmentBmsDetailsDialog}
                  onPageChange={handleChangePageWithSearchParams}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={equipmentBmsFilteringData}
                  style={{ maxHeight: "75vh" }}
                  tableConfig={EQUIPMENT_BMS_TABLE_CONFIG}
                  hiddenColumns={
                    hiddenColumnsForTables[EQUIPMENT_BMS_TABLE_NAME]
                  }
                  columnsOrders={
                    columnsOrdersForTables[EQUIPMENT_BMS_TABLE_NAME]
                  }
                  showExportToFileButton={true}
                  exportToFileUrl={exportEquipmentBmsToFileUrl}
                  exportToFileSearchParams={equipmentBmsSearchParams}
                  exportToFileHeaders={exportHeaders}
                  exportToFileFileName={`${t(
                    "headers.equipment_reading"
                  )}.xlsx`}
                />
              )}
            </LoaderWrapper>
          </Grid>
          {openEquipmentBmsDetailsDialog && (
            <EquipmentBmsDetailsDialog
              open={openEquipmentBmsDetailsDialog}
              onClose={onCloseEquipmentBmsDetailsDialog}
              equipmentBmsId={equipmentBmsId}
              onSubmitCallback={refreshTable}
            />
          )}
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
