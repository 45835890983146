import { useState, useEffect, useRef } from "react";

import { useSearchParams } from "react-router-dom";

import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import BasicDialog from "../../base/BasicDialog";

import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useAsync } from "../../../hooks/useAsync";

import TicketTable from "../../table/TicketTable";

import useTicketService from "../../../services/ticketService";

import {
  isSearchParamsObjectEmpty,
  getObjectFromSearchParams,
} from "../../../helpers/methods";

import {
  TICKET_EQUIPMENT_TABLE_CONFIG,
  TICKET_EQUIPMENT_TABLE_PREFIX,
} from "./TableConfig";

import useFilterSearchParams from "../../../hooks/useFilterSearchParams";

const TicketsForEquipmentDialog = (props) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const { getTicketsDataForEquipments } = useTicketService();

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
  } = useFilterSearchParams(searchParams);

  const [ticketEquipmentSearchParams, setTicketEquipmentSearchParams] =
    useState(getSearchParamsByFilterPrefix(TICKET_EQUIPMENT_TABLE_PREFIX));

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      TICKET_EQUIPMENT_TABLE_PREFIX,
      ticketEquipmentSearchParams,
      setTicketEquipmentSearchParams
    );
  }, [searchParams, TICKET_EQUIPMENT_TABLE_PREFIX]);

  const ticketEquipmentData = useAsync(() => {
    let tempSearchParams = {
      ...ticketEquipmentSearchParams,
    };
    tempSearchParams.equipment = props.equipmentId;
    return getTicketsDataForEquipments(tempSearchParams);
  }, [ticketEquipmentSearchParams]);

  const [dataLocaly, setDataLocaly] = useState();

  useEffect(() => {
    if (ticketEquipmentData.loading) {
      return;
    }
    setDataLocaly(ticketEquipmentData.value);
  }, [ticketEquipmentData.loading]);

  const isLoading = dataLocaly === undefined;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid container spacing={1} justifyContent={"flex-end"}>
        <Grid item xs={12}>
          <TicketTable
            showDetailsIcon={true}
            showFilters={false}
            data={dataLocaly}
            tableConfig={TICKET_EQUIPMENT_TABLE_CONFIG}
            hiddenColumns={[]}
            filteringData={undefined}
            filterPrefix={TICKET_EQUIPMENT_TABLE_PREFIX}
            initialExpandedFilterHeader={
              !isSearchParamsObjectEmpty(ticketEquipmentSearchParams)
            }
            // style={{ maxHeight: "70vh" }}
            showCleanFilterIcon={false}
            showExportToFileButton={false}
            withPagination={false}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={() => props.onClose()}
      titleAlign="center"
      maxWidth={"md"}
      title={t("form.meter_passport.tickets")}
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

TicketsForEquipmentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  equipmentId: PropTypes.string,
};

TicketsForEquipmentDialog.defaultProps = {};

export default TicketsForEquipmentDialog;
