import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import useCorrespondenceService from "../../../../services/correspondenceService";
import UniversalToolBarWithDialogs from "../../../bar/UniversalToolBarWithDialogs";
import TableService from "../../../../services/tableService";
import {exportPostOutListToFileUrl } from "../../../../helpers/apiUrls";

import {
  isSearchParamsObjectEmpty,
} from "../../../../helpers/methods";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import {
  centerVericalAlignIconStyle,
  overflowButtonStyle,
} from "../../../../helpers/styles";

import {
  POST_TABLE_NAME,
  POST_TABLE_FILTER_PREFIX,
  POST_OUT_TABLE_CONFIG,
} from "./TableConfig";
import useDialog from "../../../../hooks/useDialog";
import { DIALOG_CREATE_MODE, DIALOG_EDIT_MODE } from "../../../../helpers/constants";
import useBasicDrawer from "../../../../hooks/useBasicDrawer";
import DetailsDrawer from "../../../drawer/UniversalDetailsDrawer/UniversalDetailsDrawer";
import CorrespondenceTable from "../../../table/CorrespondenceTable";
import CreatePostDialog from "../../../dialog/CreatePostDialog/CreatePostDialog";

const tableConfig = {
  name: POST_TABLE_NAME,
  config: POST_OUT_TABLE_CONFIG,
};

const CorrespondencePostOutTab = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(POST_TABLE_FILTER_PREFIX);

  const [
    openCreatePostDialog,
    onOpenCreatePostDialog,
    onCloseCreatePostDialog,
  ] = useDialog();

  const [isDialogWithParamsClosed, setIsDialogWithParamsClosed] = useState(true);
  const postDialogModeRef = useRef();

  const handleOpenPostDialogInCreateMode = () => {
    postDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenCreatePostDialog();
  };

  const handleOpenPostDialogInEditMode = (postId) => {
    postDialogModeRef.current = DIALOG_EDIT_MODE;
    handleOpenDetailsDrawer(postId);
  };

  const getPostDialogCallback = () => {
    if (
      postDialogModeRef.current === DIALOG_CREATE_MODE &&
      !isSearchParamsObjectEmpty(postOutSearchParams)
    ) {
      setSearchParams(
        clearSearchParamsByFilterPrefix(POST_TABLE_FILTER_PREFIX)
      );
    } else {
      postOutData.refetch();
    }
  };

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
  } = useFilterSearchParams(searchParams);

  const [postOutSearchParams, setPostOutSearchParams] = useState(
    getSearchParamsByFilterPrefix(POST_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      POST_TABLE_FILTER_PREFIX,
      postOutSearchParams,
      setPostOutSearchParams
    );
  }, [searchParams]);

  const { getPostsOutData, getPostData } = useCorrespondenceService();
  const postOutData = useAsync(() => getPostsOutData({ ...postOutSearchParams }), [
    postOutSearchParams,
  ]);
  const [postOutDataLocal, setPostOutDataLocal] = useState(undefined);
  const [countRecords, setCountRecords] = useState(0);

  useEffect(() => {
    if (postOutData.loading) {
      return;
    }
    setPostOutDataLocal(postOutData.value ? postOutData.value.results : []);
    setCountRecords(postOutData.value ? postOutData.value.count : 0);
  }, [postOutData.loading, postOutData.value]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState({});
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState({});

  const refreshTable = () => {
    setPostOutDataLocal([]);
    postOutData.refetch();
    if (openDetailsDrawer && openedPostIdRef.current) {
      executeGetPostDetailsDataFn(openedPostIdRef.current);
    }
  };

  const onCleanFiltersInFilterDialog = () => {
    setPostOutDataLocal([]);
    setCountRecords(0);
  };

  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);
  const openedPostIdRef = useRef();
  const opendedPostDetailsDataRef = useRef();
  const getPostDataFn = useAsyncFn(getPostData);

  const handleOpenDetailsDrawer = useCallback(
    (postId) => {
      if (
        openDetailsDrawer &&
        (!postId || postId === opendedPostDetailsDataRef.current?.id)
      ) {
        setOpenDetailsDrawer(false);
        openedPostIdRef.current = null;
        opendedPostDetailsDataRef.current = null;
      } else {
        setOpenDetailsDrawer(true);
        openedPostIdRef.current = postId;
        executeGetPostDetailsDataFn(postId);
      }
    },
    [openDetailsDrawer, openedPostIdRef.current]
  );

  const executeGetPostDetailsDataFn = (postId) => {
    getPostDataFn.execute(postId).then(
      (data) => (opendedPostDetailsDataRef.current = data)
    );
  };

  const postExportHeaders = useMemo(() => {
    if (!hiddenColumnsForTables || !columnsOrdersForTables) return [];
    return TableService.getTableExportHeaders(
      POST_OUT_TABLE_CONFIG,
      hiddenColumnsForTables?.[POST_TABLE_NAME],
      columnsOrdersForTables?.[POST_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[POST_TABLE_NAME],
    columnsOrdersForTables?.[POST_TABLE_NAME],
    POST_OUT_TABLE_CONFIG,
  ]);

  const isLoading = postOutDataLocal ===undefined || props.filteringData.loading;


  const refreshDrawerTable = () => {
    if (openDetailsDrawer && openedPostIdRef.current) {
      executeGetPostDetailsDataFn(openedPostIdRef.current);
    }
  };

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={handleOpenDetailsDrawer}
      filteringData={props.filteringData}
      backdropColor={'rgba(173, 216, 230, 0.5)'}
      itemData={opendedPostDetailsDataRef.current}
      itemType={"post"}
      readOnly={true}
      isLoading={getPostDataFn.loading || !opendedPostDetailsDataRef.current}
      onOpenDialogParamsAction={setIsDialogWithParamsClosed}
      onRefetchData={refreshTable}
      onRefetchTableData={refreshDrawerTable}
      availableActionButtons={props.hasEditPermission ? ["enclosures", "copy_post", "edit_post", "delete"]:["enclosures"]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <UniversalToolBarWithDialogs
            pageName={props.pageName}
            showOpenCloseFilterButton={false}
            tableConfig={tableConfig}
            filteringData={props.filteringData}
            refreshTable={refreshTable}
            onCleanFlitersInFilterDialog={onCleanFiltersInFilterDialog}
            resetPageNumber={resetPageNumber}
            hiddenColumnsForTables={hiddenColumnsForTables}
            setHiddenColumnsForTables={setHiddenColumnsForTables}
            columnsOrdersForTables={columnsOrdersForTables}
            setColumnsOrdersForTables={setColumnsOrdersForTables}
            filterPrefix={POST_TABLE_FILTER_PREFIX}
            extraButtonList={
              props.hasEditPermission
                ? [
                    <Button
                      key="create-post-button"
                      onClick={handleOpenPostDialogInCreateMode}
                      variant="contained"
                      size="small"
                      fullWidth
                      startIcon={
                        <ControlPointOutlinedIcon
                        sx={centerVericalAlignIconStyle}
                        />
                      }
                      sx={{
                        ...overflowButtonStyle,
                        backgroundColor: '#3f51b5',
                        '&:hover': {
                          backgroundColor: '#303f9f',
                        }
                      }}
        
                    >
                      {t("bar.tool_bar.create_post_out")}
                    </Button>,
                  ]
                : []
            }
          />
        </Grid>
        <Grid item xs={12}>
          {hiddenColumnsForTables && (
            <LoaderWrapper showLoader={isLoading} >
            <CorrespondenceTable
              showCleanFilterIcon={true}
              style={{ maxHeight: "60vh" }}
              showFilters={true}
              data={postOutDataLocal}
              filteringData={props.filteringData}
              filterPrefix={POST_TABLE_FILTER_PREFIX}
              countRecords={countRecords}
              page={page}
              onPageChange={handleChangePageWithSearchParams}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onClickRow={handleOpenPostDialogInEditMode}
              selectedPostId={openedPostIdRef.current}
              resetPageNumber={resetPageNumber}
              tableConfig={POST_OUT_TABLE_CONFIG}
              hiddenColumns={hiddenColumnsForTables[POST_TABLE_NAME]}
              columnsOrders={columnsOrdersForTables[POST_TABLE_NAME]}
              showExportToFileButton={true}
              exportToFileUrl={exportPostOutListToFileUrl}
              exportToFileSearchParams={{
                ...postOutSearchParams,
              }}
              exportToFileHeaders={postExportHeaders}
              exportToFileFileName={`${t("page.correspondence_page.post_out")}.xlsx`}
              isLoading = {isLoading || postOutData.loading}
            />
            </LoaderWrapper>
          )}
        </Grid>
        <Grid item xs={12}>
          {openCreatePostDialog && (
            <CreatePostDialog
              open={openCreatePostDialog}
              onClose={onCloseCreatePostDialog}
              onSubmitCallback={getPostDialogCallback}
              dialogMode={postDialogModeRef.current}
              onRefetchData={refreshTable}
              postIn={false}
              autocompleteData={props.filteringData.value}
              backdropColor={'rgba(173, 216, 230, 0.5)'}
            />
          )}
        </Grid>
      </Grid>
    </DetailsDrawer>
  );
};

CorrespondencePostOutTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CorrespondencePostOutTab.defaultProps = {};

export default CorrespondencePostOutTab;
