import React from "react";
import PropTypes from "prop-types";

import AutocompleteField from "../../../field/AutocompleteField";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";

import useEquipmentService from "../../../../services/equipmentService";
import { useAsync } from "../../../../hooks/useAsync";

const CreateTicketEquipmentReplacementForm = (props) => {
  const { t } = useTranslation();

  const { getEquipmentSelectList } = useEquipmentService();
  const equipmentSelectList = useAsync(() =>
    getEquipmentSelectList({
      only_for_instalation: true,
      media_type_group_for_equipment_id:
        props.formValue?.equipment_for_deinstalation,
    })
  );

  if (equipmentSelectList.loading) return <LoaderWrapper showLoader />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={1}
      columnSpacing={2}
      marginTop={1}
    >
      <Grid item xs={4}>
        <AutocompleteField
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          name={"equipment_for_deinstalation"}
          label={t("form.meter_passport.equipment_for_deinstalation")}
          value={props.formValue?.equipment_for_deinstalation}
          options={equipmentSelectList.value}
          readOnly={
            props.readOnly ||
            props.readOnlyFields.includes("equipment_for_deinstalation")
          }
          optionLabelKey={"equipment_nr"}
          multiple={false}
          isObjectOption={true}
        />
      </Grid>
      <Grid item xs={4}>
        <AutocompleteField
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          name={"equipment_for_instalation"}
          label={t("form.meter_passport.equipment_for_instalation")}
          value={props.formValue?.equipment_for_instalation}
          options={equipmentSelectList.value}
          readOnly={
            props.readOnly ||
            props.readOnlyFields.includes("equipment_for_instalation")
          }
          optionLabelKey={"equipment_nr"}
          multiple={false}
          isObjectOption={true}
        />
      </Grid>
    </Grid>
  );
};

CreateTicketEquipmentReplacementForm.propTypes = {
  formValue: PropTypes.object,
  onChange: PropTypes.func,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func,
  readOnly: PropTypes.bool,
  readOnlyFields: PropTypes.bool,
};

CreateTicketEquipmentReplacementForm.defaultProps = {
  readOnly: false,
  readOnlyFields: [],
};

export default CreateTicketEquipmentReplacementForm;
