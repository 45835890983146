import { useMemo, useRef } from "react";
import PropTypes, { any } from "prop-types";

import i18n from "../../../i18n";

import SplitButton from "../SplitButton";

import CreateTicketDialog from "../../dialog/CreateTicketDialog";
import useDialog from "../../../hooks/useDialog";

import useEquipmentService from "../../../services/equipmentService";
import useLocationService from "../../../services/locationService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";

import { useSnackbarAlert } from "../../../context/snackbarAlert";

import CreateTicketEquipmentDeintalationForm from "../../form/CreateTicketEquipmentForms/CreateTicketEquipmentDeinstalationForm";
import CreateTicketEquipmentIntalationForm from "../../form/CreateTicketEquipmentForms/CreateTicketEquipmentInstalationForm";
import CreateTicketEquipmentReplacementForm from "../../form/CreateTicketEquipmentForms/CreateTicketEquipmentReplacementForm";
import CreateTicketEquipmentReadingForm from "../../form/CreateTicketEquipmentForms/CreateTicketEquipmentReadingForm";

import { isEmptyArray } from "../../../helpers/methods";

export const STD_TICKET_KEY_FOR_INSTALATION = "instalation";
export const STD_TICKET_KEY_FOR_DEINSTALATION = "deinstalation";
export const STD_TICKET_KEY_FOR_REPLACEMENT = "replacement";
export const STD_TICKET_KEY_FOR_READING = "reading";

function CreateEquipmentTicketButton(props) {
  const snackbarAlert = useSnackbarAlert();

  const [
    openCreateTicketDialog,
    onOpenCreateTicketDialog,
    onCloseCreateTicketDialog,
  ] = useDialog();

  const {
    prepareLocationDataFromBackendForLocationRecordsComponent,
    getObjectDataForEquipment,
    getBuidlingListForEquipments,
  } = useLocationService();

  const ticketInitialDataRef = useRef();
  const ticketEquipmentForm = useRef();
  const ticketDialogReadOnlyFields = useRef();

  const { getStdTicketsConfigForEquipment } = useEquipmentService();

  const stdTicketsConfigForEquipment = useAsync(
    getStdTicketsConfigForEquipment
  );

  const getEquipmentObjectDataFn = useAsyncFn(getObjectDataForEquipment);
  const getBuidlingListForEquipmentsFn = useAsyncFn(
    getBuidlingListForEquipments
  );

  const handleCloseCreateTicketDialog = (existChanges) => {
    ticketInitialDataRef.current = null;
    ticketEquipmentForm.current = null;
    ticketDialogReadOnlyFields.current = null;
    onCloseCreateTicketDialog();
    if (existChanges && props.onSubmitCreateTicket) {
      props.onSubmitCreateTicket();
    }
  };

  const stdTicketsFrontendConfig = useMemo(
    () => ({
      instalation: {
        form: <CreateTicketEquipmentIntalationForm />,
        extraDataForTicketDialog: {
          equipment_for_instalation: props.selectedEquipmentIds[0],
        },
        readOnlyFields: [
          "std_ticket",
          "ticket_category",
          "equipment_for_instalation",
        ],
      },
      deinstalation: {
        form: <CreateTicketEquipmentDeintalationForm />,
        extraDataForTicketDialog: {
          equipment_for_deinstalation: props.selectedEquipmentIds[0],
        },
        readOnlyFields: [
          "std_ticket",
          "ticket_category",
          "locations",
          "equipment_for_deinstalation",
        ],
      },
      replacement: {
        form: <CreateTicketEquipmentReplacementForm />,
        extraDataForTicketDialog: {
          equipment_for_deinstalation: props.selectedEquipmentIds[0],
        },
        readOnlyFields: [
          "std_ticket",
          "ticket_category",
          "locations",
          "equipment_for_deinstalation",
        ],
      },
      reading: {
        form: <CreateTicketEquipmentReadingForm />,
        extraDataForTicketDialog: {
          equipment_for_reading: props.selectedEquipmentIds,
        },
        readOnlyFields: [
          "std_ticket",
          "ticket_category",
          "locations",
          "equipment_for_reading",
        ],
      },
    }),
    [props.selectedEquipmentIds]
  );

  const handleOpenCreateTicketDialog = (stdTicketKey) => {
    if (
      stdTicketsConfigForEquipment.loading ||
      !stdTicketsConfigForEquipment.value
    ) {
      return;
    }

    const stdTicketBackendConfig = stdTicketsConfigForEquipment.value.find(
      (config) => config.std_ticket_key === stdTicketKey
    );

    const getDataFn =
      stdTicketKey === STD_TICKET_KEY_FOR_READING
        ? getBuidlingListForEquipmentsFn
        : getEquipmentObjectDataFn;

    const dataProps =
      stdTicketKey === STD_TICKET_KEY_FOR_READING
        ? props.selectedEquipmentIds
        : props.selectedEquipmentIds[0];

    getDataFn
      .execute(dataProps)
      .then((resData) => {
        if (stdTicketBackendConfig.is_for_multiple_equipments) {
          if (
            stdTicketBackendConfig.is_for_mounted_equipments &&
            (resData.some((object) => object === null) ||
              resData.some((object) => isEmptyArray(object.location)))
          ) {
            snackbarAlert.openErrorSnackbarAlert(
              i18n.t("snackbar_alert.equipment_is_not_mounted")
            );
            return;
          }
          if (
            !stdTicketBackendConfig.is_for_mounted_equipments &&
            resData.some((object) => object !== null)
          ) {
            snackbarAlert.openErrorSnackbarAlert(
              i18n.t("snackbar_alert.equipment_is_not_mounted")
            );
            return;
          }
        } else {
          if (
            stdTicketBackendConfig.is_for_mounted_equipments &&
            isEmptyArray(resData?.location || [])
          ) {
            snackbarAlert.openErrorSnackbarAlert(
              i18n.t("snackbar_alert.equipment_is_not_mounted")
            );
            return;
          }

          if (
            !stdTicketBackendConfig.is_for_mounted_equipments &&
            !isEmptyArray(resData?.location || [])
          ) {
            snackbarAlert.openErrorSnackbarAlert(
              i18n.t("snackbar_alert.equipment_is_already_mounted")
            );
            return;
          }
        }

        let initialData = {
          std_ticket: stdTicketBackendConfig.std_ticket_id,
          ticket_name: stdTicketBackendConfig.std_ticket_name,
          ticket_category: stdTicketBackendConfig.ticket_category_id,
          extraData:
            stdTicketsFrontendConfig[stdTicketKey].extraDataForTicketDialog,
        };

        if (stdTicketBackendConfig.is_for_mounted_equipments) {
          initialData["locations"] =
            prepareLocationDataFromBackendForLocationRecordsComponent(
              stdTicketBackendConfig.is_for_multiple_equipments
                ? resData.map((object) => object.location)
                : resData.location
            );
        }

        ticketInitialDataRef.current = initialData;

        ticketEquipmentForm.current =
          stdTicketsFrontendConfig[stdTicketKey].form;

        ticketDialogReadOnlyFields.current =
          stdTicketsFrontendConfig[stdTicketKey].readOnlyFields;

        onOpenCreateTicketDialog();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenCreateTicketInIntalationModeDialog = () => {
    handleOpenCreateTicketDialog(STD_TICKET_KEY_FOR_INSTALATION);
  };

  const handleOpenCreateTicketInDeintalationModeDialog = () => {
    handleOpenCreateTicketDialog(STD_TICKET_KEY_FOR_DEINSTALATION);
  };

  const handleOpenCreateTicketInReplacementModeDialog = () => {
    handleOpenCreateTicketDialog(STD_TICKET_KEY_FOR_REPLACEMENT);
  };

  const handleOpenCreateTicketInReadingModeDialog = () => {
    handleOpenCreateTicketDialog(STD_TICKET_KEY_FOR_READING);
  };

  const massActions = useMemo(() => {
    const massActionsForSingleEquipment = [
      {
        label: i18n.t("bar.tool_bar.equipment_instalation"),
        callback: handleOpenCreateTicketInIntalationModeDialog,
      },
      {
        label: i18n.t("bar.tool_bar.equipment_deinstalation"),
        callback: handleOpenCreateTicketInDeintalationModeDialog,
      },
      {
        label: i18n.t("bar.tool_bar.equipment_replacement"),
        callback: handleOpenCreateTicketInReplacementModeDialog,
      },
    ];

    const masssActionsForMultipleEquipments = [
      {
        label: i18n.t("bar.tool_bar.equipment_reading"),
        callback: handleOpenCreateTicketInReadingModeDialog,
      },
    ];

    if (props.selectedEquipmentIds && props.selectedEquipmentIds.length > 1) {
      return masssActionsForMultipleEquipments;
    }

    return [
      ...massActionsForSingleEquipment,
      ...masssActionsForMultipleEquipments,
    ];
  }, [props.selectedEquipmentIds]);

  return (
    <>
      <SplitButton
        label={i18n.t("bar.tool_bar.create_ticket")}
        withChangeSelectedItem={false}
        color={props.color}
        options={massActions}
        disabled={
          props.selectedEquipmentIds && props.selectedEquipmentIds.length === 0
        }
      />
      {openCreateTicketDialog &&
        ticketInitialDataRef.current &&
        ticketEquipmentForm.current &&
        ticketDialogReadOnlyFields.current && (
          <CreateTicketDialog
            open={openCreateTicketDialog}
            onClose={handleCloseCreateTicketDialog}
            returnTrueIfTicketCanceled={false}
            initialData={ticketInitialDataRef.current}
            readOnlyFields={ticketDialogReadOnlyFields.current}
            allowOnlySingleLocation={true}
            extraForm={ticketEquipmentForm.current}
            stdTicketFilters={{ std_ticket_is_only_for_selected_tickets: true }}
          />
        )}
    </>
  );
}

CreateEquipmentTicketButton.propTypes = {
  color: PropTypes.string,
  selectedEquipmentIds: PropTypes.array,
  onSubmitCreateTicket: PropTypes.func,
};

CreateEquipmentTicketButton.defaultProps = {
  color: "success",
};

export default CreateEquipmentTicketButton;
