import { useMemo, useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import { useAsync } from "../../hooks/useAsync";
import useEquipmentService from "../../services/equipmentService";

import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

import { getObjectFromSearchParams } from "../../helpers/methods";
import { NAVIGATION_DRAWER_TYPE_METERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import EquipmentTable from "../../components/table/EquipmentTable/EquipmentTable";

import TableService from "../../services/tableService";
import { exportEquipmentBmsUnmatchedToFileUrl } from "../../helpers/apiUrls";

import {
  EQUIPMENT_BMS_TABLE_CONFIG,
  EQUIPMENT_BMS_TABLE_NAME,
} from "./TableConfig";

import CreateItemDialog from "../../components/dialog/CreateItemDialog";
import useDialog from "../../hooks/useDialog";

export default function EquipmentBmsUnmatchedPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();

  const [dataLocaly, setDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams();

  const [
    openCreateItemDialog,
    onOpenCreateItemDialog,
    onCloseCreateItemDialog,
  ] = useDialog();

  const equipmentBmsSearchParams = useMemo(() => {
    let tempSearchParams = { ...getObjectFromSearchParams(searchParams) };
    return tempSearchParams;
  }, [searchParams]);

  const { getEquipmentBmsUnmatchedListData } = useEquipmentService();

  const equipmentBmsData = useAsync(() => {
    return getEquipmentBmsUnmatchedListData(equipmentBmsSearchParams);
  }, [equipmentBmsSearchParams]);

  useEffect(() => {
    if (equipmentBmsData.loading) {
      return;
    }
    setDataLocaly(equipmentBmsData.value.results);
    setCountRecords(equipmentBmsData.value.count);
  }, [equipmentBmsData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setDataLocaly(undefined);
    equipmentBmsData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const initialFormValueRef = useRef();
  const handleOpenCreateItemDialog = (equipmentNumber) => {
    initialFormValueRef.current = { equipment_nr: equipmentNumber };
    onOpenCreateItemDialog();
  };

  const exportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      EQUIPMENT_BMS_TABLE_CONFIG,
      hiddenColumnsForTables?.[EQUIPMENT_BMS_TABLE_NAME],
      columnsOrdersForTables?.[EQUIPMENT_BMS_TABLE_NAME]
    );
  }, [
    EQUIPMENT_BMS_TABLE_CONFIG,
    hiddenColumnsForTables?.[EQUIPMENT_BMS_TABLE_NAME],
    columnsOrdersForTables?.[EQUIPMENT_BMS_TABLE_NAME],
  ]);

  const isLoading = dataLocaly === undefined;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_METERS}
    >
      <DefaultPageWrapper titleKey={"equipment_bms_unmatched"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <UniversalToolBarWithDialogs
              pageName={props.pageName}
              tableConfig={[
                {
                  name: EQUIPMENT_BMS_TABLE_NAME,
                  config: EQUIPMENT_BMS_TABLE_CONFIG,
                },
              ]}
              filteringData={{}}
              refreshTable={refreshTable}
              onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
              resetPageNumber={resetPageNumber}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showOpenCloseFilterButton={false}
              showCreateButton={false}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isLoading}>
              {hiddenColumnsForTables && columnsOrdersForTables && (
                <EquipmentTable
                  data={dataLocaly}
                  showCheckbox={false}
                  showCleanFilterIcon={false}
                  countRecords={countRecords}
                  page={page}
                  onClickMultipleLocationAlert={handleOpenCreateItemDialog}
                  onPageChange={handleChangePageWithSearchParams}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={undefined}
                  style={{ maxHeight: "75vh" }}
                  tableConfig={EQUIPMENT_BMS_TABLE_CONFIG}
                  hiddenColumns={
                    hiddenColumnsForTables[EQUIPMENT_BMS_TABLE_NAME]
                  }
                  columnsOrders={
                    columnsOrdersForTables[EQUIPMENT_BMS_TABLE_NAME]
                  }
                  showExportToFileButton={true}
                  exportToFileUrl={exportEquipmentBmsUnmatchedToFileUrl}
                  exportToFileSearchParams={equipmentBmsSearchParams}
                  exportToFileHeaders={exportHeaders}
                  exportToFileFileName={`${t(
                    "headers.equipment_bms_unmatched"
                  )}.xlsx`}
                />
              )}
            </LoaderWrapper>
          </Grid>
          {openCreateItemDialog && (
            <CreateItemDialog
              open={openCreateItemDialog}
              onClose={onCloseCreateItemDialog}
              onRefetchData={refreshTable}
              itemType={"equipment"}
              showSearchItemButton={false}
              restrictParamListForItem
              treeIndex={3.5}
              initialFormValue={initialFormValueRef.current}
            />
          )}
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
