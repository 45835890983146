import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";

import { useForm } from "../../../hooks/useForm";

import TextFieldFormControl from "../../field/TextFieldFormControl";
import TrueFalseSelectField from "../../field/TrueFalseSelectField/trueFalseSelectField";

import useTariffService from "../../../services/tariffsService";

import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";

import {
  DIALOG_PREVIEW_MODE,
  DIALOG_EDIT_MODE,
  DIALOG_CREATE_MODE,
} from "../../../helpers/constants";

const REQUIRED_FIELDS = ["etg_name", "etg_active"];

const FIELDS_TO_SAVE = [...REQUIRED_FIELDS, "etg_note"];

const ElectricityTarrifGroupDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const dialogTitle = useMemo(() => {
    return props.dialogMode === DIALOG_CREATE_MODE
      ? t("dialog.electricity_tariff.add_new_tariff_group")
      : props.dialogMode === DIALOG_EDIT_MODE
      ? t("dialog.electricity_tariff.edit_tariff_group")
      : t("dialog.electricity_tariff.tariff_group_details");
  }, [props.dialogMode]);

  const isReadOnly = useMemo(
    () => props.readOnly || props.dialogMode === DIALOG_PREVIEW_MODE,
    [props.readOnly, props.dialogMode]
  );

  const fieldsToSave = useMemo(
    () => props.fieldsToSave || FIELDS_TO_SAVE,
    [props.fieldsToSave]
  );

  const { formValue, setFormValue, onChange } = useForm();

  const {
    getElectricityTariffGroupDetailsData,
    createElectricityTariffGroup,
    updateElectricityTariffGroup,
  } = useTariffService();

  const initialData = useAsync(() => {
    if (props.dialogMode === DIALOG_CREATE_MODE) {
      return Promise.resolve({ etg_active: true });
    }

    return getElectricityTariffGroupDetailsData(props.tariffGroupId);
  }, [props.getElectricityTariffGroupDetailsData, props.dialogMode]);

  useEffect(() => {
    setFormValue(initialData.value);
  }, [initialData.loading]);

  const prepareDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (fieldsToSave.includes(key)) {
        dataToSend[key] = value;
      }
    }
    return dataToSend;
  };

  const updateTariffGroupFn = useAsyncFn(updateElectricityTariffGroup);
  const addTariffGroupFn = useAsyncFn(createElectricityTariffGroup);
  const submitFn = useMemo(
    () =>
      props.dialogMode === DIALOG_CREATE_MODE
        ? addTariffGroupFn
        : updateTariffGroupFn,
    [props.dialogMode]
  );

  const handleSubmit = (dataToSend) => {
    submitFn
      .execute(dataToSend, props.tariffGroupId)
      .then((res) => {
        if (props.dialogMode === DIALOG_CREATE_MODE) {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.tariff_group_created")
          );
        } else {
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.tariff_group_updated")
          );
        }
        if (props.onSubmitCallback) {
          props.onSubmitCallback();
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_saving_changes")
          )
        );
      });
  };

  const isFormValid =
    formValue &&
    REQUIRED_FIELDS.every((field) => !isEmptyValue(formValue[field]));

  const isLoading = formValue === undefined;

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <TextFieldFormControl
            name="etg_name"
            required
            value={formValue.etg_name}
            label={t("dialog.electricity_tariff.etg_name")}
            readOnly={isReadOnly}
            onChange={onChange}
          />
        </Grid>
        {props.dialogMode !== DIALOG_CREATE_MODE && (
          <Grid item xs={12}>
            <TrueFalseSelectField
              name={"etg_active"}
              label={t("dialog.electricity_tariff.etg_active")}
              value={formValue.etg_active}
              onChange={onChange}
              addEmptyOptions={false}
              valuesAsBool
              required
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextFieldFormControl
            name="etg_note"
            value={formValue.etg_note}
            label={t("dialog.electricity_tariff.etg_note")}
            readOnly={isReadOnly}
            onChange={onChange}
            multiline
            rows={3}
          />
        </Grid>
        {!isReadOnly && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={addTariffGroupFn.loading || updateTariffGroupFn.loading}
              disabled={!isFormValid}
              onClick={() => handleSubmit(prepareDataToSend())}
            >
              {t("save")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      showTopFullScreenButton={false}
      title={dialogTitle}
      maxWidth="xs"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

ElectricityTarrifGroupDialog.propTypes = {
  dialogMode: PropTypes.oneOf([
    DIALOG_CREATE_MODE,
    DIALOG_EDIT_MODE,
    DIALOG_PREVIEW_MODE,
  ]),
  readOnly: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  fieldsToSave: PropTypes.array,
};

ElectricityTarrifGroupDialog.defaultProps = {
  open: false,
};

export default ElectricityTarrifGroupDialog;
