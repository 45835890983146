import i18n from "../../../i18n";


export const TRANSFERS_TABLE_FIELDS_CONFIG = [
  {
    name: "transfer_date_paid",
    getValueCallback: (rowData) => rowData.transfer_date_paid,
    label: i18n.t("table.transfer_table.transfer_date_paid"),
  },  
  {
    name: "transfer_title",
    getValueCallback: (rowData) => rowData.transfer_title,
    label: i18n.t("table.transfer_table.transfer_title")
  },
  {
    name: "transfer_payer",
    getValueCallback: (rowData) => rowData.transfer_payer,
    label: i18n.t("table.transfer_table.transfer_payer"),
  },
  {
    name: "transfer_receiver",
    getValueCallback: (rowData) => rowData.transfer_receiver,
    label: i18n.t("table.transfer_table.transfer_receiver"),
  },
  {
    name: "transfer_payer_iban",
    getValueCallback: (rowData) => rowData.transfer_payer_iban,
    label: i18n.t("table.transfer_table.transfer_payer_iban"),
  },
  {
    name: "transfer_receiver_iban",
    getValueCallback: (rowData) => rowData.transfer_receiver_iban,
    label: i18n.t("table.transfer_table.transfer_receiver_iban"),
  },
  {
    name: "currency",
    getValueCallback: (rowData) => rowData.currency,
    label: i18n.t("table.transfer_table.currency"),
  },
  {
    name: "icopaytrat_amount",
    getValueCallback: (rowData) => rowData.icopaytrat_amount,
    label: i18n.t("table.transfer_table.icopaytrat_amount"),
  },
  {
    name: "icopayment_kind",
    getValueCallback: (rowData) => i18n.t(`table.icos_table.${rowData.icopayment_kind}_rate`),
    label: i18n.t("table.transfer_table.icopayment_kind"),
  }
];
