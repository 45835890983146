import { useMemo, useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";

import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";

import useFilterSearchParams from "../../hooks/useFilterSearchParams";
import usePaginationWithSearchParams from "../../hooks/usePaginationWithSearchParams";

import { useAsync } from "../../hooks/useAsync";
import useEquipmentService from "../../services/equipmentService";

import UniversalToolBarWithDialogs from "../../components/bar/UniversalToolBarWithDialogs";

import useDialogWithId from "../../hooks/useDialogWithId";

import { isSearchParamsForFilteringEmpty } from "../../helpers/methods";
import { NAVIGATION_DRAWER_TYPE_METERS } from "../../components/drawer/NavigationDrawer/NavigationDrawer";

import TableService from "../../services/tableService";
import { exportPpeUrl } from "../../helpers/apiUrls";

import PpeDialog from "../../components/dialog/PpeDialog";

import {
  PPE_TABLE_NAME,
  PPE_FILTER_PREFIX,
  PPE_TABLE_FIELDS_CONFIG,
} from "./TableConfig";

import { DIALOG_CREATE_MODE, DIALOG_EDIT_MODE } from "../../helpers/constants";
import PpeTable from "../../components/table/PpeTable";

const CONFIG_FOR_USER_PREFERENCEDIALOG = {
  name: PPE_TABLE_NAME,
  config: PPE_TABLE_FIELDS_CONFIG,
};

export default function PpeManagementPage(props) {
  const { pageName } = props;

  const { t } = useTranslation();

  const [dataLocaly, setDataLocaly] = useState();
  const [countRecords, setCountRecords] = useState();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(PPE_FILTER_PREFIX);

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefixes,
  } = useFilterSearchParams(searchParams);

  const [filterSearchParams, setFilterSearchParams] = useState(
    getSearchParamsByFilterPrefix(PPE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      PPE_FILTER_PREFIX,
      filterSearchParams,
      setFilterSearchParams
    );
  }, [searchParams]);

  const { getPpeListData, getPpeFilteringData } = useEquipmentService();

  const filteringData = useAsync(getPpeFilteringData);

  const backendData = useAsync(
    () => getPpeListData(filterSearchParams),
    [filterSearchParams]
  );

  useEffect(() => {
    if (backendData.loading) {
      return;
    }
    setDataLocaly(backendData.value.results);
    setCountRecords(backendData.value.count);
  }, [backendData.loading]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState();
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState();

  const refreshTable = () => {
    setDataLocaly(undefined);
    backendData.refetch();
  };

  const onCleanFlitersInFilterDialog = () => {
    setDataLocaly(undefined);
    setCountRecords(undefined);
  };

  const [openPpeDialog, ppeId, onOpenPpeDialog, onClosePpeDialog] =
    useDialogWithId();

  const ppeDialogModeRef = useRef();
  const handleOpenPpeDialogInCreateMode = () => {
    ppeDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenPpeDialog(undefined);
  };

  const handleOpenTarrifDialogInEditMode = (id) => {
    ppeDialogModeRef.current = DIALOG_EDIT_MODE;
    onOpenPpeDialog(id);
  };

  const getPpeDialogSubmitCallback = () => {
    if (!isSearchParamsForFilteringEmpty(searchParams)) {
      setSearchParams({});
    } else {
      backendData.refetch();
    }

    onClosePpeDialog();
  };

  const exportHeaders = useMemo(() => {
    return TableService.getTableExportHeaders(
      PPE_TABLE_FIELDS_CONFIG,
      hiddenColumnsForTables?.[PPE_TABLE_NAME],
      columnsOrdersForTables?.[PPE_TABLE_NAME]
    );
  }, [
    PPE_TABLE_FIELDS_CONFIG,
    hiddenColumnsForTables?.[PPE_TABLE_NAME],
    columnsOrdersForTables?.[PPE_TABLE_NAME],
  ]);

  const isLoading = dataLocaly === undefined || filteringData.loading;

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <NavigationDrawer
      pageName={pageName}
      drawerType={NAVIGATION_DRAWER_TYPE_METERS}
    >
      <DefaultPageWrapper titleKey={"ppe_management"}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <UniversalToolBarWithDialogs
              pageName={props.pageName}
              tableConfig={CONFIG_FOR_USER_PREFERENCEDIALOG}
              filteringData={filteringData}
              refreshTable={refreshTable}
              onCleanFlitersInFilterDialog={onCleanFlitersInFilterDialog}
              resetPageNumber={resetPageNumber}
              hiddenColumnsForTables={hiddenColumnsForTables}
              setHiddenColumnsForTables={setHiddenColumnsForTables}
              columnsOrdersForTables={columnsOrdersForTables}
              setColumnsOrdersForTables={setColumnsOrdersForTables}
              showOpenCloseFilterButton={false}
              showCreateButton={true}
              onClickCreateItem={handleOpenPpeDialogInCreateMode}
              createButtonLabel={t("page.ppe_management.add_ppe")}
            />
          </Grid>
          <Grid item xs={12}>
            <LoaderWrapper showLoader={isLoading}>
              {hiddenColumnsForTables && columnsOrdersForTables && (
                <PpeTable
                  showCheckbox={false}
                  data={dataLocaly}
                  showCleanFilterIcon={true}
                  countRecords={countRecords}
                  page={page}
                  onPageChange={handleChangePageWithSearchParams}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  resetPageNumber={resetPageNumber}
                  filteringData={filteringData}
                  filterPrefix={PPE_FILTER_PREFIX}
                  style={{ maxHeight: "75vh" }}
                  tableConfig={PPE_TABLE_FIELDS_CONFIG}
                  hiddenColumns={hiddenColumnsForTables[PPE_TABLE_NAME]}
                  columnsOrders={columnsOrdersForTables[PPE_TABLE_NAME]}
                  onClickEdit={handleOpenTarrifDialogInEditMode}
                  showExportToFileButton={true}
                  exportToFileUrl={exportPpeUrl}
                  exportToFileSearchParams={filterSearchParams}
                  exportToFileHeaders={exportHeaders}
                  exportToFileFileName={`${t("headers.ppe_management")}.xlsx`}
                />
              )}
            </LoaderWrapper>
          </Grid>
          {openPpeDialog && (
            <PpeDialog
              open={openPpeDialog}
              onClose={onClosePpeDialog}
              dialogMode={ppeDialogModeRef.current}
              onSubmitCallback={getPpeDialogSubmitCallback}
              ppeId={ppeId}
            />
          )}
        </Grid>
      </DefaultPageWrapper>
    </NavigationDrawer>
  );
}
