import PropTypes from "prop-types";

import i18n from "../../../i18n";

import SplitButton from "../SplitButton";

import useEquipmentService from "../../../services/equipmentService";

import { useAsyncFn } from "../../../hooks/useAsync";

import { useSnackbarAlert } from "../../../context/snackbarAlert";

export const EQUIPBMS_STATUS_TO_VERIFICATION = "to_verification";
export const EQUIPBMS_STATUS_SYSTEM_ACCEPTED = "system_accepted";
export const EQUIPBMS_STATUS_MANUAL_ACCEPTED = "manual_accepted";
export const EQUIPBMS_STATUS_REFUSED = "refused";

export const EQUIPBMS_STATUSES_TO_CHANGED = [
  EQUIPBMS_STATUS_TO_VERIFICATION,
  EQUIPBMS_STATUS_MANUAL_ACCEPTED,
  EQUIPBMS_STATUS_REFUSED,
];

function ChangeEquipmentBmsButton(props) {
  const snackbarAlert = useSnackbarAlert();

  const { changeEquipmentBmsStatus } = useEquipmentService();
  const changeEquipmentBmsStatusFn = useAsyncFn(changeEquipmentBmsStatus);

  const handleChangeEquipemntBmsStatus = (statusKind) => {
    if (props.selectedEquipmentBmsIds.length === 0) return;

    changeEquipmentBmsStatusFn
      .execute(props.selectedEquipmentBmsIds, statusKind)
      .then(() => {
        if (props.onSubmit) props.onSubmit();
        snackbarAlert.openSuccessSnackbarAlert(
          i18n.t("snackbar_alert.bms_status_changed")
        );
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          i18n.t("snackbar_alert.occurred_error_during_bms_status_changed")
        );
      });
  };

  const massActions = [
    {
      label: i18n.t(`backend_choices_list.${EQUIPBMS_STATUS_TO_VERIFICATION}`),
      callback: () =>
        handleChangeEquipemntBmsStatus(EQUIPBMS_STATUS_TO_VERIFICATION),
    },
    // Don't allow user to change bms status to system accepted
    //   {
    //     label: i18n.t(
    //       `backend_choices_list.${EQUIPBMS_STATUS_SYSTEM_ACCEPTED}`
    //     ),
    //     callback: () =>
    //       handleChangeEquipemntBmsStatus(EQUIPBMS_STATUS_SYSTEM_ACCEPTED),
    //   },
    {
      label: i18n.t(`backend_choices_list.${EQUIPBMS_STATUS_MANUAL_ACCEPTED}`),
      callback: () =>
        handleChangeEquipemntBmsStatus(EQUIPBMS_STATUS_MANUAL_ACCEPTED),
    },
    {
      label: i18n.t(`backend_choices_list.${EQUIPBMS_STATUS_REFUSED}`),
      callback: () => handleChangeEquipemntBmsStatus(EQUIPBMS_STATUS_REFUSED),
    },
  ];

  return (
    <>
      <SplitButton
        label={i18n.t("bar.tool_bar.change_status")}
        withChangeSelectedItem={false}
        color={props.color}
        options={massActions}
      />
    </>
  );
}

ChangeEquipmentBmsButton.propTypes = {
  color: PropTypes.string,
  selectedEquipmentBmsIds: PropTypes.array,
  onSubmit: PropTypes.func,
};

ChangeEquipmentBmsButton.defaultProps = {
  color: "success",
  selectedEquipmentBmsIds: [],
};

export default ChangeEquipmentBmsButton;
