import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";
import { getTranslatedList } from "../../helpers/methods";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

export const REALIZED_TRANSFERS_TABLE_NAME = "realized_transfers_table";
export const NEXT_TRANSFERS_TABLE_NAME = "next_transfers_table";
export const FUTURE_TRANSFERS_TABLE_NAME = "future_transfers_table";

export const REALIZED_TRANSFERS_TABLE_FIELDS_CONFIG = [
  {
    name: "transfer_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.transfer_date),
    label: i18n.t("table.icos_table.transfer_date"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "transfer_date_paid",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.transfer_date_paid),
    label: i18n.t("table.icos_table.transfer_date_paid"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  
  // {
  //   name: "edit",
  //   getValueCallback: (rowData, onClickEdit, hasPermission) => {
  //     const content = (
  //         <ModeEditOutlineOutlined
  //           onClick={(e) => onClickEdit(e, rowData.id)}
  //         />
  //     );
  //     return  hasPermission ? content : "";
  //   },
  //   label: undefined,
  //   filterType: null,
  // },
  {
    name: "transfer_payer",
    getValueCallback: (rowData) => rowData.transfer_payer,
    label: i18n.t("table.icos_table.payer"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owners,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "transfer_receiver",
    getValueCallback: (rowData) => rowData.transfer_receiver,
    label: i18n.t("table.icos_table.receiver"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owners,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "transfer_payer_iban",
    getValueCallback: (rowData) => rowData.transfer_payer_iban,
    label: i18n.t("table.icos_table.transfer_payer_iban"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "transfer_receiver_iban",
    getValueCallback: (rowData) => rowData.transfer_receiver_iban,
    label: i18n.t("table.icos_table.transfer_receiver_iban"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },   
  {
    name: "currency",
    getValueCallback: (rowData) => rowData.currency,
    label: i18n.t("table.icos_table.currency"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.currencies,
      isObjectOption: true,
      optionLabelKey: "currency_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "transfer_amount",
    getValueCallback: (rowData) => rowData.transfer_amount,
    label: i18n.t("table.icos_table.transfer_amount"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
      showNumberSeparator : true
    }),
  },
  {
    name: "transfer_title",
    getValueCallback: (rowData) => rowData.transfer_title,
    label: i18n.t("table.icos_table.transfer_title"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
];



export const NEXT_TRANSFERS_TABLE_FIELDS_CONFIG = [
  {
    name: "ico_nr",
    getValueCallback: (rowData) => rowData.ico_nr,
    label: i18n.t("table.icos_table.agreement_number"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ico_name",
    getValueCallback: (rowData) => rowData.ico_name,
    label: i18n.t("table.icos_table.agreement_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "icopayment_payer",
    getValueCallback: (rowData) => rowData.icopayment_payer,
    label: i18n.t("table.icos_table.payer"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owners,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "icopayment_receiver",
    getValueCallback: (rowData) => rowData.icopayment_receiver,
    label: i18n.t("table.icos_table.receiver"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owners,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "icopayment_kind",
    getValueCallback: (rowData) => i18n.t(`table.icos_table.${rowData.icopayment_kind}_rate`),
    label: i18n.t("table.icos_table.payment_kind"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: getTranslatedList(filteringData?.value?.payment_kinds, "other.payment_kinds."),
      isObjectOption: true,
      optionLabelKey: "name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  }, 
  {
    name: "icopayment_due_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.icopayment_due_date),
    label: i18n.t("table.icos_table.due_date"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "currency",
    getValueCallback: (rowData) => rowData.currency,
    label: i18n.t("table.icos_table.currency"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.currencies,
      isObjectOption: true,
      optionLabelKey: "currency_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "icopayment_current_amount_original",
    getValueCallback: (rowData) => rowData.icopayment_current_amount_original_formatted,
    label: i18n.t("table.icos_table.payment_to_pay"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
      showNumberSeparator : true
    }),
  },
];

export const FUTURE_TRANSFERS_TABLE_FIELDS_CONFIG = [
  {
    name: "ico_nr",
    getValueCallback: (rowData) => rowData.ico_nr,
    label: i18n.t("table.icos_table.agreement_number"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "ico_name",
    getValueCallback: (rowData) => rowData.ico_name,
    label: i18n.t("table.icos_table.agreement_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "schedule_payer",
    getValueCallback: (rowData) => rowData.schedule_payer,
    label: i18n.t("table.icos_table.payer"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owners,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "schedule_receiver",
    getValueCallback: (rowData) => rowData.schedule_receiver,
    label: i18n.t("table.icos_table.receiver"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owners,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "icoschedule_kind",
    getValueCallback: (rowData) => i18n.t(`table.icos_table.${rowData.icoschedule_kind}_rate`),
    label: i18n.t("table.icos_table.payment_kind"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: getTranslatedList(filteringData?.value?.payment_kinds, "other.payment_kinds."),
      isObjectOption: true,
      optionLabelKey: "name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  }, 
  {
    name: "icoschedule_due_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.icoschedule_due_date),
    label: i18n.t("table.icos_table.due_date"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "currency",
    getValueCallback: (rowData) => rowData.currency,
    label: i18n.t("table.icos_table.currency"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.currencies,
      isObjectOption: true,
      optionLabelKey: "currency_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "icoschedule_amount_to_pay",
    getValueCallback: (rowData) => rowData.icoschedule_amount_to_pay,
    label: i18n.t("table.icos_table.amount_to_pay"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
      showNumberSeparator : true
    }),
  },
];