import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
} from "../../helpers/constants";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../helpers/methods";
import SyncIcon from "@mui/icons-material/Sync";
import DateService from "../../services/dateService";

export const ED_TABLE_NAME = "electricity_distribution_table";
export const ED_FILTER_PREFIX = "ed";

export const ED_TABLE_FIELDS_CONFIG = [
  {
    name: "ed_month",
    getValueCallback: (rowData) => rowData.ed_month,
    label: i18n.t("table.electricity_distribution_table.ed_month"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      decimalPlaces: 0,
      style: { minWidth: "100px" },
    }),

    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_year",
    getValueCallback: (rowData) => rowData.ed_year,
    label: i18n.t("table.electricity_distribution_table.ed_year"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      decimalPlaces: 0,
      style: { minWidth: "100px" },
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "object_level_2",
    getValueCallback: (rowData) => rowData.object_level_2,
    label: i18n.t("table.electricity_distribution_table.object_level_2"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.object_level_2,
      isObjectOption: true,
      optionLabelKey: "object_name",
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "object_level_4",
    getValueCallback: (rowData) => rowData.object_level_4,
    label: i18n.t("table.electricity_distribution_table.object_level_4"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.object_level_4,
      isObjectOption: true,
      optionLabelKey: "object_name",
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipment_note",
    getValueCallback: (rowData) => rowData.equipment_note,
    label: i18n.t("table.electricity_distribution_table.equipment_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.electricity_distribution_table.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "equipment",
    getValueCallback: (rowData) => rowData.equipment_nr,
    label: i18n.t("table.electricity_distribution_table.equipment_nr"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment,
      isObjectOption: true,
      optionLabelKey: "equipment_nr",
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "electricity_tariff_group",
    getValueCallback: (rowData) => rowData.electricity_tariff_group,
    label: i18n.t(
      "table.electricity_distribution_table.electricity_tariff_group"
    ),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.electricity_tariff_group,
      isObjectOption: true,
      optionLabelKey: "etg_name",
      addNewValue: false,
      multiple: true,
      style: { minWidth: "150px" },
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ppe",
    getValueCallback: (rowData) => rowData.ppe,
    label: i18n.t("table.electricity_distribution_table.ppe"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.ppe,
      isObjectOption: true,
      optionLabelKey: "ppe_number",
      addNewValue: false,
      multiple: true,
    }),
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_power",
    getValueCallback: (rowData) => rowData.ed_power,
    label: i18n.t("table.electricity_distribution_table.ed_power"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_start_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
        rowData.ed_start_date
      ),
    label: i18n.t("table.electricity_distribution_table.ed_start_date"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "ed_start_value",
    getValueCallback: (rowData) => rowData.ed_start_value,
    label: i18n.t("table.electricity_distribution_table.ed_start_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_start_status",
    getValueCallback: (rowData) =>
      rowData.ed_start_status
        ? i18n.t(`backend_choices_list.${rowData.ed_start_status}`)
        : null,
    label: i18n.t("table.electricity_distribution_table.ed_start_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: convertFlatListFromBackendToTranslatedOptionObject(
        filteringData.value?.ed_start_status
      ),
      isObjectOption: true,
      multiple: true,
    }),
  },
  {
    name: "ed_end_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
        rowData.ed_end_date
      ),
    label: i18n.t("table.electricity_distribution_table.ed_end_date"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
  },
  {
    name: "ed_end_value",
    getValueCallback: (rowData) => rowData.ed_end_value,
    label: i18n.t("table.electricity_distribution_table.ed_end_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_end_status",
    getValueCallback: (rowData) =>
      rowData.ed_end_status
        ? i18n.t(`backend_choices_list.${rowData.ed_end_status}`)
        : null,
    label: i18n.t("table.electricity_distribution_table.ed_end_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: convertFlatListFromBackendToTranslatedOptionObject(
        filteringData.value?.ed_end_status
      ),
      isObjectOption: true,
      multiple: true,
    }),
  },
  {
    name: "ed_total_consumption_value",
    getValueCallback: (rowData) => rowData.ed_total_consumption_value,
    label: i18n.t(
      "table.electricity_distribution_table.ed_total_consumption_value"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_rush_consumption_value",
    getValueCallback: (rowData) => rowData.ed_rush_consumption_value,
    label: i18n.t(
      "table.electricity_distribution_table.ed_rush_consumption_value"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_rush_consumption_hours",
    getValueCallback: (rowData) => rowData.ed_rush_consumption_hours,
    label: i18n.t(
      "table.electricity_distribution_table.ed_rush_consumption_hours"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_norush_consumption_value",
    getValueCallback: (rowData) => rowData.ed_norush_consumption_value,
    label: i18n.t(
      "table.electricity_distribution_table.ed_norush_consumption_value"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_norush_consumption_hours",
    getValueCallback: (rowData) => rowData.ed_norush_consumption_hours,
    label: i18n.t(
      "table.electricity_distribution_table.ed_norush_consumption_hours"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_delta_s",
    getValueCallback: (rowData) => rowData.ed_delta_s,
    label: i18n.t("table.electricity_distribution_table.ed_delta_s"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_subscription_fee_fixed",
    getValueCallback: (rowData) => rowData.ed_subscription_fee_fixed,
    label: i18n.t(
      "table.electricity_distribution_table.ed_subscription_fee_fixed"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_network_fee_fixed",
    getValueCallback: (rowData) => rowData.ed_network_fee_fixed,
    label: i18n.t("table.electricity_distribution_table.ed_network_fee_fixed"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_transition_fee_fixed",
    getValueCallback: (rowData) => rowData.ed_transition_fee_fixed,
    label: i18n.t(
      "table.electricity_distribution_table.ed_transition_fee_fixed"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "ed_network_fee_changeable",
    getValueCallback: (rowData) => rowData.ed_network_fee_changeable,
    label: i18n.t(
      "table.electricity_distribution_table.ed_network_fee_changeable"
    ),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    headerSx: { whiteSpace: "break-spaces" },
  },
  {
    name: "recalculate",
    getValueCallback: (rowData, onClickRefresh) => (
      <SyncIcon
        onClick={(e) =>
          onClickRefresh(
            rowData.equipment_id,
            rowData.ed_year,
            rowData.ed_month
          )
        }
      />
    ),
    label: i18n.t("recalculate"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];
