import i18n from "../../i18n";

import {
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  DATETIME_TO_DATE_RANGE_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";

import SelectFieldService from "../../services/selectFieldService";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";

import WindowService from "../../services/windowService";
import DateService from "../../services/dateService";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../helpers/methods";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

export const EQUIPMENT_BMS_TABLE_NAME = "equipment_bms";

export const EQUIPMENT_BMS_TABLE_CONFIG = [
  {
    name: "passport",
    getValueCallback: (rowData) => (
      <FeedOutlinedIcon
        onClick={(e) =>
          WindowService.openInNewTab(
            `/counter_passport/${rowData.equipment_id}`
          )
        }
      />
    ),
    label: i18n.t("passport"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
  {
    name: "equipment",
    getValueCallback: (rowData) => rowData.equipment_nr,
    label: i18n.t("table.counter_repository.equipment"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.equipment,
      isObjectOption: true,
      optionLabelKey: "equipment_nr",
      addNewValue: false,
      multiple: true,
    }),
    other_export_field_name: "equipment_nr",
    sortable: true,
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.user_email_notifications.tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_category",
    getValueCallback: (rowData) => rowData.equipment_category,
    label: i18n.t("table.counter_repository.equipment_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_category,
      isObjectOption: true,
      optionLabelKey: "equcat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_media_type",
    getValueCallback: (rowData) => rowData.equipment_media_type,
    label: i18n.t("table.counter_repository.equipment_media_type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_media_type,
      isObjectOption: true,
      optionLabelKey: "equmedtyp_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipment_status",
    getValueCallback: (rowData) => rowData.equipment_status,
    label: i18n.t("table.counter_repository.equipment_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.equipment_status,
      isObjectOption: true,
      optionLabelKey: "equstat_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_1_name",
    getValueCallback: (rowData) => rowData.location?.level_1?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_1_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_1_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_2_name",
    getValueCallback: (rowData) => rowData.location?.level_2?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_2_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_2_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_3_name",
    getValueCallback: (rowData) => rowData.location?.level_3?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_3_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_3_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_4_name",
    getValueCallback: (rowData) => rowData.location?.level_4?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_4_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_4_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "objects_level_5_name",
    getValueCallback: (rowData) => rowData.location?.level_5?.object_name,
    label: i18n.t("table.counter_repository.instalation_place_level_5_name"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.objects_level_5_name,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "equipbms_date",
    getValueCallback: (rowData) =>
      DateService.convertDatetimeFromBackendToFormatYYYYMMDD_hhmmss(
        rowData.equipbms_date
      ),
    label: i18n.t("table.counter_repository.equipbms_date"),
    filterType: DATETIME_TO_DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "equipbms_value",
    getValueCallback: (rowData) => rowData.equipbms_value,
    label: i18n.t("table.counter_repository.equipbms_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  {
    name: "equipbms_unit",
    getValueCallback: (rowData) => rowData.equipbms_unit,
    label: i18n.t("table.counter_repository.equipbms_unit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "equipbms_manual",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.equipbms_manual),
    label: i18n.t("table.counter_repository.equipbms_manual"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "equipbms_status",
    getValueCallback: (rowData) =>
      i18n.t(`backend_choices_list.${rowData.equipbms_status}`),
    label: i18n.t("table.counter_repository.equipbms_status"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: convertFlatListFromBackendToTranslatedOptionObject(
        filteringData.value.equipbms_status
      ),
      isObjectOption: true,
      multiple: true,
    }),
  },
  {
    name: "equipbms_note",
    getValueCallback: (rowData) => rowData.equipbms_note,
    label: i18n.t("table.counter_repository.equipment_order_note"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    label: i18n.t("edit"),
    getValueCallback: (rowData, onClickEdit) => {
      const content = (
        <ModeEditOutlineOutlined onClick={() => onClickEdit(rowData.id)} />
      );
      return rowData.equipbms_manual ? content : "";
    },
    filterType: null,
    excludedFromExport: true,
  },
];
