import i18n from "../../i18n";
import {
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  DATE_RANGE_FIELD_TYPE,
  INTEGER_FIELD_TYPE,
  NUMERIC_FIELD_TYPE,
  TEXT_FIELD_TYPE,
} from "../../helpers/constants";
import DateService from "../../services/dateService";
import SelectFieldService from "../../services/selectFieldService";
import { isEmptyValue } from "../../helpers/methods";

export const AGREEMENT_TABLE_NAME = "agreements_table";

export const AGREEMENT_TABLE_FIELDS_CONFIG = [
  {
    name: "agreement_nr",
    getValueCallback: (rowData) => rowData.agreement_nr,
    label: i18n.t("table.agreements_table.agreement_nr"),
    filterType: INTEGER_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
    defaultHidden : true
  },
  {
    name: "owner",
    getValueCallback: (rowData) => rowData.owner,
    label: i18n.t("table.agreements_table.agreement_owner"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.owner,
      isObjectOption: true,
      optionLabelKey: "owner_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "tenant",
    getValueCallback: (rowData) => rowData.tenant,
    label: i18n.t("table.agreements_table.agreement_tenant"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    defaultHidden : true,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.tenant,
      isObjectOption: true,
      optionLabelKey: "tenant_short_name",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },

  {
    name: "agreement_individual_person",
    getValueCallback: (rowData) => rowData.agreement_individual_person,
    label: i18n.t("table.agreements_table.agreement_individual_person"),
    getFilterFieldConfigCallback: (filteringData) => undefined,
    filterType: TEXT_FIELD_TYPE,
    sortable: true
  },
  {
    name: "agreement_title",
    getValueCallback: (rowData) => rowData.agreement_title,
    label: i18n.t("table.agreements_table.agreement_title"),
    getFilterFieldConfigCallback: (filteringData) => undefined,
    filterType: TEXT_FIELD_TYPE,
    sortable: true,
    defaultHidden : true
  },
  {
    name: "agreement_kind",
    getValueCallback: (rowData) => rowData.agreement_kind,
    label: i18n.t("table.agreements_table.agreement_kind"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.agreement_kind,
      isObjectOption: true,
      optionLabelKey: "agki_category",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "agreement_subkind",
    getValueCallback: (rowData) => rowData.agreement_subkind,
    label: i18n.t("table.agreements_table.agreement_subkind"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.agreement_subkind,
      isObjectOption: true,
      optionLabelKey: "agsu_subcategory",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "agreement_scope",
    getValueCallback: (rowData) => rowData.agreement_scope,
    label: i18n.t("table.agreements_table.agreement_scope"),
    getFilterFieldConfigCallback: (filteringData) => undefined,
    filterType: TEXT_FIELD_TYPE,
    defaultHidden : true
  },
  {
    name: "agreement_location",
    getValueCallback: (rowData) => rowData.agreement_location,
    label: i18n.t("table.agreements_table.agreement_location"),
    getFilterFieldConfigCallback: (filteringData) => undefined,
    filterType: TEXT_FIELD_TYPE,
    sortable: true,
  },
  {
    name: "agreement_sign_form",
    getValueCallback: (rowData) => rowData.agreement_sign_form,
    label: i18n.t("table.agreements_table.agreement_sign_form"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.agreement_sign_form,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },
  {
    name: "agreement_date_sign",
    getValueCallback: (rowData) => 
      DateService.convertDatetimeFromBackendToFormatDDMMYYYY(rowData.agreement_date_sign),
    label: i18n.t("table.agreements_table.agreement_date_sign"),
    filterType: DATE_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      style: { minWidth: "100px" },
    }),
    sortable: true,
  },
  // {
  //   name: "agreement_date_start",
  //   getValueCallback: (rowData) =>
  //     DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.agreement_date_start),
  //   label: i18n.t("table.agreements_table.agreement_date_start"),
  //   filterType: DATE_RANGE_FIELD_TYPE,
  //   getFilterFieldConfigCallback: (filteringData) => ({
  //     style: { minWidth: "100px" },
  //   }),
  //   sortable: true,
  //   defaultHidden : true
  // },
  // {
  //   name: "agreement_date_stop",
  //   getValueCallback: (rowData) =>
  //     DateService.convertDatetimeFromBackendToFormatYYYYMMDD(rowData.agreement_date_stop),
  //   label: i18n.t("table.agreements_table.agreement_date_stop"),
  //   filterType: DATE_RANGE_FIELD_TYPE,
  //   getFilterFieldConfigCallback: (filteringData) => ({
  //     style: { minWidth: "100px" },
  //   }),
  //   sortable: true,
  //   defaultHidden : true
  // },
  // {
  //   name: "agreement_validity",
  //   getValueCallback: (rowData) => rowData.agreement_validity,
  //   label: i18n.t("table.agreements_table.agreement_xxx"),
  //   filterType: TEXT_FIELD_TYPE,
  //   getFilterFieldConfigCallback: (filteringData) => undefined,
  // },
  // {
  //   name: "keeper",
  //   getValueCallback: (rowData) => rowData.keeper,
  //   label: i18n.t("table.agreements_table.agreement_keeper"),
  //   filterType: AUTOCOMPLETE_FIELD_TYPE,
  //   defaultHidden : true,
  //   getFilterFieldConfigCallback: (filteringData) => ({
  //     options: filteringData?.value?.keeper,
  //     isObjectOption: true,
  //     optionLabelKey: "full_name",
  //     addNewValue: false,
  //     multiple: true,
  //     disableClearable: true,
  //     style: { minWidth: "150px" },
  //   }),
  // },
  // {
  //   name: "agreement_note_legal",
  //   getValueCallback: (rowData) => rowData.agreement_note_legal,
  //   label: i18n.t("table.agreements_table.agreement_note_legal"),
  //   filterType: TEXT_FIELD_TYPE,
  //   getFilterFieldConfigCallback: (filteringData) => undefined,
  // },
  {
    name: "parent",
    getValueCallback: (rowData) => rowData.parent,
    label: i18n.t("table.agreements_table.agreement_parent"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData?.value?.parent,
      isObjectOption: true,
      optionLabelKey: "agreement_nr",
      addNewValue: false,
      multiple: true,
      disableClearable: true,
      style: { minWidth: "150px" },
    }),
  },

  {
    name: "agreement_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.agreement_active),
    label: i18n.t("table.agreements_table.agreement_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,

  },

];
